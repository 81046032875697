import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import Popover from '../../../core/Popover';
import IconButton from '../../../core/IconButton';
import Tooltip from '../../../core/Tooltip';
import { CommentExclamation, Fullscreen } from '../../../icons';
import CardKpi from '../CardKpi';

import { StyledPublicGridKpisCard, Comment } from './styled/PublicGridKpisCard.styled';

const PublicGridKpisCard = ({
  item, openKpiViewer, kpiQtd, globalTheme,
}) => {
  const kpiRef = useRef();
  const [showCommentContainer, setShowCommentContainer] = useState(false);

  const openCommentContainer = () => {
    setShowCommentContainer(!showCommentContainer);
  };

  const closeCommentContainer = () => {
    setShowCommentContainer(false);
  };

  const handleOpenKpiViewer = () => {
    openKpiViewer(item.id);
  };

  return (
    <StyledPublicGridKpisCard>
      <CardKpi
        item={item}
        id={item.id}
        editPainel={false}
        ref={kpiRef}
        dashSize={kpiQtd}
        globalTheme={
          globalTheme && item.style?.UseGlobalColor !== false
            ? globalTheme : { useGlobalTheme: false }
        }
      />
      {item.comment && (
        <div className="menuCard">
          <Popover
            open={showCommentContainer}
            closePopover={closeCommentContainer}
          >
            <Popover.Action>
              <Tooltip text="Ver comentário" direction="left">
                <IconButton
                  color="secondary"
                  shape="rounded"
                  variant="pattern"
                  size="medium"
                  onClick={openCommentContainer}
                >
                  <CommentExclamation />
                </IconButton>
              </Tooltip>
            </Popover.Action>
            <Popover.Content stayOpened style={{ width: '250px', padding: '1rem 1rem', whiteSpace: 'normal' }}>
              <span style={{ color: '#696969', fontSize: '0.85rem', fontWeight: 'bold' }}>Comentário</span>
              <Comment>
                {item.comment}
              </Comment>
            </Popover.Content>
          </Popover>
        </div>
      )}
      <div className="menuCard bottom">
        <Tooltip text="Expandir" direction="left">
          <IconButton
            color="primary"
            shape="rounded"
            variant="pattern"
            size="medium"
            onClick={handleOpenKpiViewer}
          >
            <Fullscreen />
          </IconButton>
        </Tooltip>
      </div>
    </StyledPublicGridKpisCard>
  );
};

PublicGridKpisCard.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    comment: PropTypes.string,
    name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    type: PropTypes.string,
    meta: PropTypes.shape({}),
  }),
  openKpiViewer: PropTypes.func,
  kpiQtd: PropTypes.number,
  globalTheme: PropTypes.shape({}),
};

PublicGridKpisCard.defaultProps = {
  item: {},
  openKpiViewer: () => {},
  kpiQtd: 1,
  globalTheme: undefined,
};

export default PublicGridKpisCard;
