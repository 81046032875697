export default {
  // TITLE
  TitleControl: '',
  ShowTitleControl: { checked: true },
  StyleTitleControl: {
    fontSize: 20,
    color: '#000000',
    fontFamily: 'arial',
    bold: false,
    italic: false,
    underline: false,
  },
  // GENERAL
  SortValuesControl: 'Não ordenado',
  ColumnToSortControl: 'index',
  CustomSortControl: {
    checked: false, kpiCategories: [],
  },
  LineShowTotalControl: true,
  ColumnShowTotalControl: true,
  // LABEL
  LabelsFontFamilyControl: { value: 'arial' },
  LabelsFontSizeControl: { value: 14 },
  LineColorControl: 'single',
  RowColor: 'white',
  StripedRowsColor: 'white',
  HeaderColor: 'white',
  PrefixControl: '',
  SufixControl: '',
  Decimals: 'auto',
  separadorNumericoCasas: '0',
  LabelCurrencyControl: 'Nenhum',
  LabelGroupingControl: 'Nenhum',
  UseGlobalColor: true,
  // AXIS
  EnableGridX: true,
  EnableGridY: true,
  GridColor: '#EEE',
};
