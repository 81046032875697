/* eslint-disable no-param-reassign */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { ResponsiveScatterPlotCanvas } from '@nivo/scatterplot';

import customTooltip from '../../utils/chartTools/handleCustomTooltip';
import colorSelector from '../../utils/formatCharts/handleColor';
import customAxis from '../../utils/formatCharts/formatAxis';
import { dataSort, genDataScatterPlot } from '../../utils/formatCharts/handleData';
import { getBiggestTick, getSmallestTick } from '../../utils/formatCharts/getAxisTicks';
import { checkErrors } from '../../utils/chartTools/kpiMsg';

const ScatterPlot = ({
  data, styleConfig, isEdit, globalTheme,
}) => {
  const [chartData, setChartData] = useState({ ready: false });

  useEffect(() => {
    setChartData(genDataScatterPlot(data, styleConfig));
  }, [data]);

  useEffect(() => {
    if (chartData.ready) {
      setChartData((cData) => ({
        ...cData,
        data: dataSort(chartData.data, styleConfig),
      }));
    }
  }, [
    ...styleConfig.CustomSortControl.kpiCategories,
    styleConfig.SortValuesControl,
  ]);

  const biggestTickY = useMemo(() => (
    getBiggestTick(chartData.yMax ?? 0, styleConfig)
  ), [chartData.yMax]);

  const smallestTickY = useMemo(() => (
    getSmallestTick(chartData.yMin ?? 0, styleConfig)
  ), [chartData.yMin]);

  const biggestTickX = useMemo(() => (
    getBiggestTick(chartData.xMax ?? 0, styleConfig)
  ), [chartData.xMax]);

  const smallestTickX = useMemo(() => (
    getSmallestTick(chartData.xMin ?? 0, styleConfig)
  ), [chartData.xMin]);

  const createTooltip = (val) => customTooltip(val, 'scatterplot', styleConfig);

  return chartData.ready && chartData?.isScatterplot ? (
    <ResponsiveScatterPlotCanvas
      data={chartData.data}
      /* GENERAL */
      margin={
        styleConfig?.Margin || {
          top: 50,
          right: 130,
          bottom: 50,
          left: 60,
        }
      }
      xScale={{
        type: styleConfig?.ValueScaleControlX ?? 'linear',
        min: styleConfig?.MinValueControlX
          ? smallestTickX
          : styleConfig?.MinValueX ?? 0,
        max: styleConfig?.MaxValueControlX
          ? biggestTickX
          : styleConfig?.MaxValueX ?? 0,
      }}
      yScale={{
        type: styleConfig?.ValueScaleControlY ?? 'linear',
        min: styleConfig?.MinValueControlY
          ? smallestTickY
          : styleConfig?.MinValueY ?? 0,
        max: styleConfig?.MaxValueControlY
          ? biggestTickY
          : styleConfig?.MaxValueY ?? 0,
      }}
      /* STYLE */
      nodeSize={+(styleConfig?.NodeSizeControl) ?? 9}
      colors={colorSelector({
        dataLength: chartData.indexes.length,
        config: styleConfig,
        colorKey: 'serieId',
        globalTheme,
      })}
      /* AXIS */
      enableGridX={styleConfig?.EnableGridX}
      enableGridY={styleConfig?.EnableGridY}
      axisTop={customAxis('top', styleConfig)}
      axisLeft={customAxis('left', styleConfig)}
      axisBottom={customAxis('bottom', styleConfig)}
      axisRight={customAxis('right', styleConfig)}
      /* INTERACTIVE */
      isInteractive={styleConfig.InteractiveControl}
      tooltip={createTooltip}
      /* LEGENDS */
      legends={styleConfig?.LegendControl?.checked ? [{
        ...styleConfig?.LegendPlacementControl?.value,
        itemWidth: 100,
        itemHeight: 18,
        itemTextColor: '#999',
        symbolSize: 18,
        symbolShape: styleConfig?.LegendSymbolControl || 'circle',
        itemsSpacing: parseInt(styleConfig?.LegendsSpacingControl?.value, 10),
        effects: [{ on: 'hover', style: { itemTextColor: '#000' } }],
        toggleSerie: !isEdit,
      }] : []}
      /* THEME */
      theme={{
        legends: {
          text: {
            fontSize: `${styleConfig?.LegendsFontSizeControl?.value}`,
            fontFamily: `'${styleConfig?.LegendsFontFamilyControl?.value}', Arial`,
          },
          hidden: {
            text: {
              textDecoration: 'line-through',
            },
          },
        },
        axis: {
          legend: {
            text: {
              fontSize: styleConfig?.AxisGlobal?.legendFontSize,
              fontFamily: `'${styleConfig?.AxisGlobal?.fontFamily}', Arial`,
              fill: styleConfig?.AxisGlobal?.color,
            },
          },
          ticks: {
            text: {
              fontFamily: `'${styleConfig?.AxisGlobal?.fontFamily}', Arial`,
              fontSize: styleConfig?.AxisGlobal?.fontSize,
              fill: styleConfig?.AxisGlobal?.color,
            },
          },
        },
      }}
      //
      pixelRatio={2}
    />
  ) : checkErrors({ notScatterplot: !(chartData?.isScatterplot) });
};

ScatterPlot.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  styleConfig: PropTypes.objectOf(PropTypes.any).isRequired,
  isEdit: PropTypes.bool.isRequired,
  globalTheme: PropTypes.objectOf(PropTypes.any),
};

ScatterPlot.defaultProps = {
  globalTheme: {},
};

export default ScatterPlot;
