/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import GridKpisCard from '../../juristec-ui/kpis/grid/CardContainer/GridKpisCard';
import getCardStyle from '../../utils/functions/getCardStyle';
import {
  StyledOuterGridLayout,
  StyledResponsiveReactGridLayout,
} from './styled/KpiRender.styled';

// ReactGridLayout variables
const rglCols = {
  lg: 48,
  md: 48,
  sm: 48,
  xs: 48,
  xxs: 48,
};
const breakpoints = {
  lg: 1200,
  md: 1024,
  sm: 768,
  xs: 480,
  xxs: 0,
};

function KpiRender({
  dashboardKey,
  metaGen,
  chartType,
  pivotTable,
  styleConfig,
  setMetaGridInfo,
  dashboardLayout,
  handleStyleConfig,
  dashboardDoc,
}) {
  const editPainel = true;
  const componentRef = useRef();

  const doc = {
    i: 'new',
    w: dashboardLayout?.w || 48,
    h: dashboardLayout?.h || 20,
    x: 0,
    y: 1000,
  };

  const [breakpoint, setBreakpoint] = useState('lg');
  const [stateDashboardLayout, setStateDashboardLayout] = useState({
    lg: [doc],
    md: [doc],
    sm: [doc],
    xs: [doc],
    xxs: [doc],
  });

  const [kpiObject, setKpiObject] = useState({
    id: 'new',
    name: 'kpiName',
    status: 'active',
    updatedAt: '',
    type: chartType,
    data: pivotTable,
    style: styleConfig,
    meta: metaGen,
  });

  useEffect(() => {
    setMetaGridInfo(doc);
  }, []);

  function onLayoutChange(layout, _newlayouts) {
    setStateDashboardLayout(_newlayouts);
    setMetaGridInfo(_newlayouts[breakpoint][0]);
  }

  function onBreakpointChange(newBreakpoint, newCols) {
    setBreakpoint(newBreakpoint);
  }

  useEffect(() => {
    setKpiObject({
      id: 'new',
      // name: 'kpiName',
      name: styleConfig?.ShowTitleControl?.checked === true ? styleConfig?.TitleControl : '',
      status: 'active',
      updatedAt: '',
      type: chartType,
      data: pivotTable,
      style: styleConfig,
      meta: metaGen,
    });
  }, [pivotTable, chartType, styleConfig]);

  return (
    <StyledOuterGridLayout editPainel={editPainel}>
      <StyledResponsiveReactGridLayout
        ref={componentRef}
        editPainel={editPainel}
        isResizable={editPainel}
        isDraggable={false}
        margin={[3, 3]}
        breakpoints={breakpoints}
        cols={rglCols}
        rowHeight={20}
        layouts={stateDashboardLayout}
        onLayoutChange={(layout, _layouts) => onLayoutChange(layout, _layouts)}
        onBreakpointChange={(newBreakpoint, newCols) => onBreakpointChange(newBreakpoint, newCols)}
        compactType="vertical" // TODO: criar input para 3 opcoes: vertical, horizontal ou null
        preventCollision={false}
        useCSSTransforms={false}
        measureBeforeMount={false}
        className="kpi_preview"
        resizeHandles={['s', 'e', 'se']}
      >
        <div key={kpiObject.id} style={getCardStyle(dashboardDoc?.cardStyles || {})}>
          <GridKpisCard
            handleStyleConfig={handleStyleConfig}
            // refresh={() => { }}
            // isShared={false}
            item={kpiObject}
            // onRemoveItem={() => { }}
            editPainel={editPainel}
            // dashboardKey={dashboardKey}
            // stateDashboardLayout={stateDashboardLayout}
            globalTheme={dashboardDoc?.cardStyles}
          />
        </div>
      </StyledResponsiveReactGridLayout>
    </StyledOuterGridLayout>
  );
}

KpiRender.propTypes = {
  chartType: PropTypes.string,
  dashboardKey: PropTypes.any,
  pivotTable: PropTypes.object,
  setMetaGridInfo: PropTypes.func,
  styleConfig: PropTypes.object,
  dashboardLayout: PropTypes.object,
  handleStyleConfig: PropTypes.func,
  metaGen: PropTypes.object,
  dashboardDoc: PropTypes.shape({
    cardStyles: PropTypes.shape({}),
  }),
};

KpiRender.defaultProps = {
  dashboardLayout: {
    w: 48,
    h: 20,
  },
  dashboardDoc: {},
};

export default KpiRender;
