import Gradient from 'gradient2';
import { getColorSchemes } from '../controllersUtils/themeColors';

function genGradient(arrayColors, dataLength) {
  if (arrayColors.length < 2) return arrayColors;
  if (dataLength === 2) return [arrayColors[0], arrayColors[arrayColors.length - 1]];
  if (dataLength <= arrayColors.length) return arrayColors;
  const gradient = new Gradient({
    colors: arrayColors,
    steps: dataLength,
  });
  return gradient.toArray();
}

/**
 * Handles chart color format.
 * @param {object} params function params
 * @param {number} params.dataLength Quantity of categories.
 * @param {object} params.config Style config.
 * @param {string} params.colorKey Custom color key.
 * @param {boolean} params.isMap Identifies if the chart is a map type (different theme).
 * @param {string[]} params.globalTheme Array with global colors
 * @returns {string[]|object|function} Required color handle.
 */
const colorSelector = ({
  dataLength, config, colorKey, isMap, globalTheme,
}) => {
  if (globalTheme?.useGlobalTheme) {
    return genGradient(globalTheme.charts, dataLength);
  }
  switch (config.PalletsControl) {
    case 'gradient': {
      const gradColors = [
        config.GradientPickerControl?.color1 || '#4711B2',
        config.GradientPickerControl?.color2 || '#FF9626',
      ];
      if (config.GradientPickerControl?.color3?.length > 0) {
        gradColors.push(config.GradientPickerControl.color3);
      }
      return genGradient(gradColors, dataLength);
    }
    case 'default': {
      const theme = config.ColorTheme || { scheme: 'nivo' };
      if (!getColorSchemes(isMap).includes(theme.scheme)) {
        return isMap ? 'nivo' : { scheme: 'nivo' };
      }
      return isMap ? theme.scheme : theme;
    }
    default:
      return (a) => (config?.Colors ? config?.Colors[a[colorKey]] : '#f2ead3');
  }
};

export default colorSelector;
