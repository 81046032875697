import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

//
import IconButton from '../../../juristec-ui/core/IconButton';
import Tooltip from '../../../juristec-ui/core/Tooltip';
import InputLine from '../../../juristec-ui/core/InputTextLine';

import {
  Add,
  ArrowLeft,
  Chat,
  Finder,
} from '../../../juristec-ui/icons';

import {
  Container, Tabs, NavOpt, FirstSpace, SecondSpace, SearchBarContainer, IconWrapper,
} from './styled/AdminToolbar.styled';

function AdminToolbar({
  tab, setTab, handleNewInstance, setFilterText, handleMessage,
}) {
  const history = useHistory();

  const handleMessageUsers = () => {
    handleMessage();
  };

  const goBack = () => {
    history.push('/home');
  };
  return (
    <Container>
      <FirstSpace>
        <Tooltip text="Voltar" direction="right">
          <IconButton
            variant="contained"
            color="secondary"
            size="medium"
            shape="rounded"
            onClick={goBack}
          >
            <ArrowLeft />
          </IconButton>
        </Tooltip>
        <Tabs>
          <NavOpt active={tab === 'customers'} onClick={() => setTab('customers')}>
            Clientes
          </NavOpt>
          <NavOpt active={tab === 'reports'} onClick={() => setTab('reports')}>
            Relatórios
          </NavOpt>
          <NavOpt active={tab === 'users'} onClick={() => setTab('users')}>
            Usuários
          </NavOpt>
        </Tabs>
      </FirstSpace>

      {tab !== 'reports' && (
        <SecondSpace>
          <div className="gap-fix">
            <SearchBarContainer>
              <IconWrapper>
                <Finder />
              </IconWrapper>
              <InputLine
                placeholder={tab === 'customers' ? 'Buscar instância' : 'Buscar usuário'}
                autoComplete="off"
                onChange={setFilterText}
              />
            </SearchBarContainer>
          </div>

          {tab === 'customers' && (
            <>
              <Tooltip text="Criar instância" direction="left">
                <IconButton
                  variant="contained"
                  color="secondary"
                  size="medium"
                  shape="rounded"
                  onClick={handleNewInstance}
                  disabled={false}
                  className="add_instance"
                >
                  <Add />
                </IconButton>
              </Tooltip>
              <Tooltip text="Enviar mensagem para usuários" direction="right">
                <IconButton
                  variant="contained"
                  color="secondary"
                  size="medium"
                  shape="rounded"
                  onClick={handleMessageUsers}
                  disabled={false}
                  className="send-users-message"
                >
                  <Chat />
                </IconButton>
              </Tooltip>
            </>
          )}
        </SecondSpace>
      )}
    </Container>
  );
}

AdminToolbar.propTypes = {
  tab: PropTypes.string,
  setTab: PropTypes.func,
  handleNewInstance: PropTypes.func,
  setFilterText: PropTypes.func,
  handleMessage: PropTypes.func.isRequired,
};

AdminToolbar.defaultProps = {
  tab: 'customers',
  setTab: () => {},
  handleNewInstance: () => {},
  setFilterText: () => {},
};

export default AdminToolbar;
