import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';

import DataTable from '../../../juristec-ui/core/DataTable';
import IconButton from '../../../juristec-ui/core/IconButton';
import Tooltip from '../../../juristec-ui/core/Tooltip';
import TableCellPop from '../../../juristec-ui/core/TableCellPop';
import FileOptions from '../FileOptions';

import {
  Upload,
  UpdateError,
  UpdateWarning,
  UpdateSuccess,
  VirtualAssistant,
} from '../../../juristec-ui/icons';
import { printSize, formatDateTime } from '../../../juristec-ui/utils/functions/lab';
import originIcon from '../../../juristec-ui/utils/originMap';

import {
  FilesTable,
  IconWrapper,
  IconBtnContainer,
  FilesTableOverFlow,
  OwnerLabel,
} from './styled/FilesList.styled';

/**
* A component that displays files info and options
*/
const FilesList = ({
  files,
  viewFile,
  updateFile,
  downloadFile,
  deleteFile,
  showFileDashboards,
  user,
  usersList,
  convertFileToMail,
  convertFileApiGed,
  getReportStatus,
  deviceType,
  userCompany,
  shareFile,
  changeDatabasePassword,
  transferFile,
  createFileProvision,
  listFileProvisions,
  selfRemove,
  columnAlias,
  virtualAssistant,
}) => {
  const theme = useTheme();

  const iconGroup = (item) => {
    /** Checks if the file is automatically updated */
    const isAutoUpdated = [
      'LegalOne',
      'FileApi',
      'LegalOneExtractor',
      'PgSql',
      'MySql',
      'L1ReportsApi',
      'Drive',
      'GoogleDrive',
      'OneDrive',
      'oneDrive',
      'Antecipei',
      'Mail',
      'mail',
      'Benner',
      'Oracle',
    ].includes(item.origin);

    /**
     * Return visual cues depending on file update characteristics
     * @returns {object} Object with corresponding update icon and color
     */
    const visualInfo = () => {
      if (!isAutoUpdated) return { icon: <Upload />, color: 'primary' };
      if (item.lastAttemptStatus === 'ERROR') return { icon: <UpdateError />, color: 'error' };
      if (item.lastAttemptStatus === 'WARNING') return { icon: <UpdateWarning />, color: 'warning' };
      return { icon: <UpdateSuccess />, color: 'success' };
    };

    /** Selects with type of function to be called on click */
    const updateOrStatus = () => (isAutoUpdated ? getReportStatus(item) : updateFile(item));

    const updateMessage = () => {
      if (isAutoUpdated) {
        return 'Atualizado automaticamente';
      }
      if (item.origin === 'Snapshot') {
        return 'Histórico de arquivo não pode ser substituído';
      }
      if (user.id === item.owner || item.shared_write.includes(user.id)) {
        return 'Atualizar arquivo';
      }
      return 'Você não pode atualizar um arquivo de outra pessoa';
    };

    return (
      <IconBtnContainer>
        {userCompany.plan !== 'push' && (
          <Tooltip
            text={updateMessage()}
          >
            <IconButton
              size="medium"
              shape="rounded"
              color={visualInfo().color}
              style={{ padding: '1px' }}
              onClick={updateOrStatus}
              disabled={(!isAutoUpdated && !(user.id === item.owner
                || item.shared_write.includes(user.id))) || item.origin === 'Snapshot'}
            >
              {visualInfo().icon}
            </IconButton>
          </Tooltip>
        )}
        {Boolean(virtualAssistant) && (
          <Tooltip text="Assistente Virtual (BETA)" direction="left">
            <IconButton
              variant="pattern"
              color="primary"
              size="medium"
              shape="rounded"
              onClick={() => virtualAssistant(item)}
            >
              <VirtualAssistant />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip text="Mais opções">
          <FileOptions
            size="medium"
            shape="rounded"
            item={item}
            viewFile={viewFile}
            downloadFile={downloadFile}
            deleteFile={deleteFile}
            showFileDashboards={showFileDashboards}
            user={user}
            convertFileToMail={convertFileToMail}
            convertFileApiGed={convertFileApiGed}
            userCompany={userCompany}
            shareFile={shareFile}
            changeDatabasePassword={changeDatabasePassword}
            transferFile={transferFile}
            createFileProvision={createFileProvision}
            listFileProvisions={listFileProvisions}
            selfRemove={selfRemove}
            columnAlias={columnAlias}
          />
        </Tooltip>
      </IconBtnContainer>
    );
  };

  const getOwnerName = (ownerId, filename) => {
    if (ownerId !== user.id) {
      const owner = usersList.find((u) => u.id === ownerId);
      return (
        <div>
          <TableCellPop text={filename} />
          <OwnerLabel>
            {owner ? `Proprietário: ${owner?.name}` : 'Proprietário excluído'}
          </OwnerLabel>
        </div>
      );
    }
    return (
      <div>
        <TableCellPop text={filename} />
      </div>
    );
  };

  const columns = [
    {
      field: 'origin',
      label: '',
      valueGetter: (param) => (
        <IconWrapper>
          {originIcon(param.origin)}
        </IconWrapper>
      ),
    },
    {
      field: 'filename',
      label: `Arquivos (${files.length})`,
      sortable: true,
      valueGetter: (param) => getOwnerName(param.owner, param.filename),
    },
    {
      field: 'size',
      label: 'Tamanho',
      valueGetter: (param) => printSize(param.size).replace('.', ','),
      sortable: true,
    },
    deviceType === 'desktop' ? {
      field: 'created_at',
      label: 'Criado em',
      sortable: true,
      valueGetter: (param) => formatDateTime(param.created_at, { time: 'half' }),
    } : {},
    deviceType === 'desktop' ? {
      field: 'updated_at',
      label: 'Atualizado em',
      sortable: true,
      valueGetter: (param) => (param.updated_at ? (
        formatDateTime(param.updated_at, { time: 'half' })
      ) : (
        formatDateTime(param.created_at, { time: 'half' })
      )),
    } : {},
    {
      field: 'options',
      label: 'Opções',
      valueGetter: (param) => iconGroup(param),
    },
  ];

  return (
    <FilesTable className="files_list">
      <FilesTableOverFlow>
        <DataTable
          columns={columns}
          rowData={files}
          rowColor={theme.tableBackground}
          strippedRowsColor={theme.containerOdd}
          defaultSortField="filename"
          headerColor="transparent"
          theadStyle={{
            position: 'sticky',
            top: 0,
            zIndex: 1,
            backgroundColor: theme.background,
          }}
        />
      </FilesTableOverFlow>
    </FilesTable>
  );
};

FilesList.propTypes = {
  /**
    * Array with the data of the files
    */
  files: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  /**
    * Function for viewing a file
    */
  viewFile: PropTypes.func.isRequired,
  /**
    * Function for updating a file
    */
  updateFile: PropTypes.func.isRequired,
  /**
    * Function for downloading a file
    */
  downloadFile: PropTypes.func.isRequired,
  /**
    * Function for deleting a file
    */
  deleteFile: PropTypes.func.isRequired,
  /**
    * Function to show the dashboards associated with the file
    */
  showFileDashboards: PropTypes.func.isRequired,
  /**
   * Function to convert File to Legalone email
   */
  convertFileToMail: PropTypes.func.isRequired,
  /**
   * Function to convert File to Legalone Api GED report
   */
  convertFileApiGed: PropTypes.func.isRequired,
  /**
   * Function to share file from one user to others
   */
  shareFile: PropTypes.func.isRequired,
  /**
   * Function to change database password (used for Benner and SQL files)
   */
  changeDatabasePassword: PropTypes.func.isRequired,
  /**
   * Function to transfer the ownership from one user to another
   */
  transferFile: PropTypes.func.isRequired,
  /**
   * Function to create (or concatenate) a new file version of the file
   */
  createFileProvision: PropTypes.func,
  /**
   * Function to list all the provisions created
   */
  listFileProvisions: PropTypes.func,
  /**
   * Function to self remove a user from a shared file
   */
  selfRemove: PropTypes.func.isRequired,
  /**
   * Function to rename columns from a file
   */
  columnAlias: PropTypes.func.isRequired,
  /**
   * User object
   */
  user: PropTypes.shape({
    id: PropTypes.string,
    role: PropTypes.string,
  }).isRequired,
  /**
   * The current device type by viewport width
   */
  deviceType: PropTypes.string.isRequired,
  /**
   * Function to get the status of a report (used to Legal One and Connect reports)
   */
  getReportStatus: PropTypes.func.isRequired,
  /**
  * Object with the user's company information
  */
  userCompany: PropTypes.shape({
    plan: PropTypes.string,
  }).isRequired,
  /**
  * Object with the user's company information
  */
  usersList: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    }),
  ).isRequired,
  /**
   * Function for viewing a file with assistant open
   */
  virtualAssistant: PropTypes.func,
};

FilesList.defaultProps = {
  virtualAssistant: null,
  createFileProvision: () => {},
  listFileProvisions: () => {},
};

export default FilesList;
