import React from 'react';
import PropTypes from 'prop-types';

// icons
import {
  Chart,
  Info,
  Filter,
  Groupings,
} from '../../../juristec-ui/icons';

// styles
import {
  RowInfoContainer,
  BadgersContainer,
  SeparatorLine,
  ListContainer,
  BlockInfo,
  HeaderInfo,
  ContentInfo,
  SideBySideInfo,
} from './styled/InfoKpi.styled';
import Badge from '../../../juristec-ui/core/Badge';

import { dateOptions } from '../../../options';

const typeMap = {
  values: {
    notInverse: 'Valores selecionados',
    isInverse: 'Valores não selecionados',
  },
  first: 'Primeiros N',
  most: {
    notInverse: 'N mais frequentes (sem empate)',
    isInverse: 'Não está entre os N mais frequentes (sem empate)',
  },
  least: {
    notInverse: 'N menos frequentes (sem empate)',
    isInverse: 'Não está entre os N menos frequentes (sem empate)',
  },
  most_inc: {
    notInverse: 'N mais frequentes (com empate)',
    isInverse: 'Não está entre os N mais frequentes (com empate)',
  },
  least_inc: {
    notInverse: 'N menos frequentes (com empate)',
    isIverse: 'Não está entre os N menos frequentes (com empate)',
  },
  contains: {
    notInverse: 'Contém o texto',
    isInverse: 'Não contém o texto',
  },
  greater: {
    notInverse: 'Maiores que N',
    isInverse: 'Menores ou iguais a N',
  },
  lesser: {
    notInverse: 'Menores que N',
    isInverse: 'Maiores ou iguais a N',
  },
  greater_inc: {
    notInverse: 'Maiores ou iguais a N',
    isInverse: 'Menores que N',
  },
  lesser_inc: {
    notInverse: 'Menores ou iguais a N',
    isInverse: 'Maiores que N',
  },
  after: {
    notInverse: 'A partir da data N (exclusive)',
    isInverse: 'Antes da data N (inclusive)',
  },
  before: {
    notInverse: 'Antes da data N (exclusive)',
    isInverse: 'A partir da data N (inclusive)',
  },
  after_inc: {
    notInverse: 'A partir da data N (inclusive)',
    isInverse: 'Antes da data N (exclusive)',
  },
  before_inc: {
    notInverse: 'Antes da data N (inclusive)',
    isInverse: 'A partir da data N (exclusive)',
  },
  between: {
    notInverse: 'Entre as datas A e B (exclusive)',
    isInverse: 'Não está entre A e B (inclusive)',
  },
  betweenD: {
    notInverse: 'Entre A e B (exclusive)',
    isInverse: 'Não está entre as datas A e B (inclusive)',
  },
  between_inc: {
    notInverse: 'Entre as datas A e B (inclusive)',
    isInverse: 'Não está entre A e B (exclusive)',
  },
  between_incD: {
    notInverse: 'Entre A e B (inclusive)',
    isInverse: 'Não está entre as datas A e B (exclusive)',
  },
  last: {
    notInverse: 'Últimos N',
    isInverse: 'Inverso dos últimos N',
  },
  lastyear: {
    notInverse: 'Últimos N anos',
    isInverse: 'Inverso dos últimos N anos',
  },
  lastM: {
    notInverse: 'Últimos N meses',
    isInverse: 'Inverso dos últimos N meses',
  },
  lastD: {
    notInverse: 'Últimos N dias',
    isInverse: 'Inverso dos últimos N dias',
  },
  lastQ: {
    notInverse: 'Últimos N trimestres',
    isInverse: 'Inverso dos últimos N trimestres',
  },
  lastS: {
    notInverse: 'Últimos N semestres',
    isInverse: 'Inverso dos últimos N semestres',
  },
  lastW: {
    notInverse: 'Últimas N semanas',
    isInverse: 'Inverso das últimas N semanas',
  },
  nextyear: {
    notInverse: 'Próximos N anos',
    isInverse: 'Inverso dos próximos N anos',
  },
  nextM: {
    notInverse: 'Próximos N meses',
    isInverse: 'Inverso dos próximos N meses',
  },
  nextD: {
    notInverse: 'Próximos N dias',
    isInverse: 'Inverso dos próximos N dias',
  },
  nextQ: {
    notInverse: 'Próximos N trimestres',
    isInverse: 'Inverso dos próximos N trimestres',
  },
  nextS: {
    notInverse: 'Próximos N semestres',
    isInverse: 'Inverso dos próximos N semestres',
  },
  nextW: {
    notInverse: 'Próximas N semanas',
    isInverse: 'Inverso das próximas N semanas',
  },
  valMod: {
    greater: {
      notInverse: 'Maiores que N',
      isInverse: 'Menores ou iguais a N',
    },
    lesser: {
      notInverse: 'Menores que N',
      isInverse: 'Maiores ou iguais a N',
    },
    between: {
      notInverse: 'Entre A e B (exclusive)',
      isInverse: 'Não está entre A e B (inclusive)',
    },
    greater_inc: {
      notInverse: 'Maiores ou iguais a N',
      isInverse: 'Menores que N',
    },
    lesser_inc: {
      notInverse: 'Menores ou iguais a N',
      isInverse: 'Maiores que N',
    },
    between_inc: {
      notInverse: 'Entre A e B (inclusive)',
      isInverese: 'Não está entre A e B (exclusive)',
    },
    most: {
      notInverse: 'N Maiores (sem empate)',
      isInverse: 'Não está entre os N maiores (com empate)',
    },
    least: {
      notInverse: 'N Menores (sem empate)',
      isInverse: 'Não está entre os N menores (com empate)',
    },
    most_inc: {
      notInverse: 'N Maiores (com empate)',
      isInverse: 'Não está entre os N maiores (sem empate)',
    },
    least_inc: {
      notInverse: 'N Menores (com empate)',
      isInverse: 'Não está entre os N menores (sem empate)',
    },
  },
  get(key = 'values', date, selector, isValueMod, inverse) {
    if (isValueMod) {
      return inverse ? this.valMod[key].isInverse : this.valMod[key].notInverse;
    }
    if (date) {
      if (selector) {
        if (key === 'values') {
          return inverse ? this[key].isInverse : this[key].notInverse;
        }
        return inverse ? this[key + selector].isInverse : this[key + selector].notInverse;
      }
      return inverse ? this[`${key} D`].isInverse : this[`${key} D`].notInverse;
    }
    return inverse ? this[key].isInverse || '' : this[key].notInverse || key;
  },
};

const funcoes = {
  count: 'Contagem',
  nunique: 'Contagem Únicos',
  sum: 'Soma',
  mean: 'Média',
  ...dateOptions.reduce((acc, cur) => {
    acc[cur.value] = cur.label;
    return acc;
  }, {}),
  get(k) {
    return this[k] || k;
  },
};

const charts = {
  Table: 'Tabela',
  Value: 'Valor',
  Bar: 'Barras',
  Line: 'Linhas',
  Pie: 'Pizza',
  Radar: 'Radar',
  Map: 'Mapa',
  Funnel: 'Funil',
  RadialBar: 'Barras radiais',
  Calendar: 'Calendário',
  Waffle: 'Waffle',
  get(k) {
    return this[k] || k;
  },
};

/**
 * A component for displaying information from a kpi
 */
const InfoKpi = ({
  creationDate,
  lastUpdateDate,
  kpiName,
  fileName,
  dataValues,
  dataLine,
  dataColumn,
  dataControl,
  chartType,
  filters,
  groupings,
  device,
  owner,
}) => (
  <>
    {/* Main Info */}
    <BlockInfo>
      <HeaderInfo>
        <Info />
        <span>Informações Principais</span>
      </HeaderInfo>
      <ContentInfo device={device}>
        <div className="child-item">
          <span style={{ fontWeight: 'bold' }}>Título: </span>
          <span>{kpiName}</span>
        </div>
      </ContentInfo>
      <ContentInfo>
        <div className="child-item">
          <span style={{ fontWeight: 'bold' }}>Origem: </span>
          <span>{fileName}</span>
        </div>
      </ContentInfo>
      <ContentInfo device={device}>
        <div className="child-item">
          <span style={{ fontWeight: 'bold' }}>Criado em: </span>
          <span>{creationDate}</span>
        </div>
        <div className="child-item">
          <span style={{ fontWeight: 'bold' }}>Atualizado em: </span>
          <span>{lastUpdateDate}</span>
        </div>
      </ContentInfo>
      {owner && owner !== '' && (
        <ContentInfo>
          <div className="child-item">
            <span style={{ fontWeight: 'bold' }}>Proprietário: </span>
            <span>{owner}</span>
          </div>
        </ContentInfo>
      )}
    </BlockInfo>
    <SeparatorLine />

    {/* Chart Info */}
    <BlockInfo>
      <HeaderInfo>
        <Chart />
        <span>Informações Gráficas</span>
      </HeaderInfo>
      <ContentInfo device={device}>
        <div className="child-item">
          <span style={{ fontWeight: 'bold' }}>
            {dataValues?.length > 1 ? 'Valores: ' : 'Valor: '}
          </span>
          <span>{dataValues?.map((dv) => dv.column ?? '')?.join(', ') ?? ''}</span>
        </div>
        {!['BoxPlot', 'ScatterPlot', 'SubsetTable'].includes(chartType) && (
          <div className="child-item">
            <span style={{ fontWeight: 'bold' }}>Função: </span>
            <span>{funcoes.get(dataValues?.[0]?.map ?? '')}</span>
          </div>
        )}
      </ContentInfo>
      {dataLine?.column && (
        <ContentInfo device={device}>
          <div className="child-item">
            <span style={{ fontWeight: 'bold' }}>Linha: </span>
            <span>{dataLine.column}</span>
          </div>
          {dataLine.map !== 'category' && (
            <div className="child-item">
              <span style={{ fontWeight: 'bold' }}>Formato: </span>
              <span>{funcoes.get(dataLine.map)}</span>
            </div>
          )}
        </ContentInfo>
      )}
      {dataColumn?.column && (
        <ContentInfo device={device}>
          <div className="child-item">
            <span style={{ fontWeight: 'bold' }}>Coluna: </span>
            <span>{dataColumn.column}</span>
          </div>
          {dataColumn.map !== 'category' && (
            <div className="child-item">
              <span style={{ fontWeight: 'bold' }}>Formato: </span>
              <span>{funcoes.get(dataColumn.map)}</span>
            </div>
          )}
        </ContentInfo>
      )}
      {dataControl?.column && (
        <ContentInfo device={device}>
          <div className="child-item">
            <span style={{ fontWeight: 'bold' }}>Controle: </span>
            <span>{dataControl.column}</span>
          </div>
          {dataControl.map !== 'category' && (
            <div className="child-item">
              <span style={{ fontWeight: 'bold' }}>Formato: </span>
              <span>{funcoes.get(dataControl.map)}</span>
            </div>
          )}
        </ContentInfo>
      )}
      <ContentInfo device={device}>
        <div className="child-item">
          <span style={{ fontWeight: 'bold' }}>Tipo de indicador: </span>
          <span>{charts.get(chartType)}</span>
        </div>
      </ContentInfo>
    </BlockInfo>

    {/* Filters and Groupings */}
    <SideBySideInfo device={device}>
      <div style={{ width: device !== 'phone' ? '50%' : '100%' }}>
        <HeaderInfo>
          <Filter />
          <span>Filtros</span>
        </HeaderInfo>
        <ListContainer>
          {filters?.length > 0 ? (
            filters.map((f, i) => (
              <BadgersContainer key={f.column}>
                <span className="principal">{`${i + 1}°. ${f.column}`}</span>
                <span className="secundario">{typeMap.get(f.ftype, !!f.selector, f.selector, f.isValueFilter, f.inverse)}</span>
                {f.values.map((value) => (
                  <Badge
                    key={f.column + value}
                    color="secondary"
                    textTransform="uppercase"
                    size="small"
                  >
                    <div>{value}</div>
                  </Badge>
                ))}
              </BadgersContainer>
            ))
          ) : (
            <RowInfoContainer className="list-row">
              <span className="info">Nenhum filtro</span>
            </RowInfoContainer>
          )}
        </ListContainer>
      </div>
      <div style={{ width: device !== 'phone' ? '50%' : '100%' }}>
        <HeaderInfo>
          <Groupings />
          <span>Agrupamentos</span>
        </HeaderInfo>
        <ListContainer>
          {groupings && (groupings.column?.length > 0 || groupings.line?.length > 0) ? (
            <>
              {groupings.column?.length > 0 && (
                <>
                  {groupings.column.map((g, i) => (
                    <BadgersContainer key={g.column + g.ftype}>
                      <span className="principal">{`${i + 1}°. ${g.column}`}</span>
                      <span className="secundario">
                        {typeMap.get(g.ftype, !!g.selector, g.selector, g.isValueGroup, g.inverse)}
                      </span>
                      <div className="row-div">
                        <span className="info">
                          {/* nome do grupo */}
                          {g.rule}
                          :
                        </span>
                        {g.values.map((value) => (
                          <Badge
                            key={g.column + value + g.ftype}
                            color="secondary"
                            textTransform="uppercase"
                            size="small"
                          >
                            <div>{value}</div>
                          </Badge>
                        ))}
                      </div>
                    </BadgersContainer>
                  ))}
                </>
              )}
              {groupings.line?.length > 0 && (
                <>
                  {groupings.line.map((g, i) => (
                    <BadgersContainer key={g.column + g.ftype}>
                      <span className="principal">{`${groupings.column.length + i + 1}°. ${g.column}`}</span>
                      <span className="secundario">
                        {typeMap.get(g.ftype, !!g.selector, g.selector, g.isValueGroup, g.inverse)}
                      </span>
                      <div className="row-div">
                        <span className="info">
                          {/* nome do grupo */}
                          {g.rule}
                          :
                        </span>
                        {g.values.map((value) => (
                          <Badge
                            key={g.column + value + g.ftype}
                            color="secondary"
                            textTransform="uppercase"
                            size="small"
                          >
                            <div>{value}</div>
                          </Badge>
                        ))}
                      </div>
                    </BadgersContainer>
                  ))}
                </>
              )}
            </>
          ) : (
            <RowInfoContainer className="list-row">
              <span className="info">Nenhum agrupamento</span>
            </RowInfoContainer>
          )}
        </ListContainer>
      </div>
    </SideBySideInfo>
  </>
);

InfoKpi.propTypes = {
  /**
   * The date the kpi was created
   */
  creationDate: PropTypes.string.isRequired,
  /**
   * The last date the kpi was updated
   */
  lastUpdateDate: PropTypes.string.isRequired,
  /**
   * Name assigned to the kpi
   */
  kpiName: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  /**
   * Name of the file to which the kpi originated
   * */
  fileName: PropTypes.string.isRequired,
  /**
   * Array of objects with the variables names and function used in the kpi value
   */
  dataValues: PropTypes.arrayOf(PropTypes.shape({
    column: PropTypes.string.isRequired,
    map: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  })).isRequired,
  /**
   * Object with the variable name and function used in the kpi line
   */
  dataLine: PropTypes.shape({
    column: PropTypes.string.isRequired,
    map: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  /**
   * Object with the variable name and function used in the kpi column
   */
  dataColumn: PropTypes.shape({
    column: PropTypes.string,
    map: PropTypes.string,
    type: PropTypes.string,
  }),
  /**
   * Object with the variable name and function used in the kpi control
   */
  dataControl: PropTypes.shape({
    column: PropTypes.string,
    map: PropTypes.string,
    type: PropTypes.string,
  }),
  /**
   * Type of the chart
   */
  chartType: PropTypes.string.isRequired,
  /**
   * Object with the filters used in the kpi
   */
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
    }),
  ).isRequired,
  /**
   * Object with the groupings used in the kpi
   */
  groupings: PropTypes.shape({
    column: PropTypes.arrayOf(
      PropTypes.shape({}),
    ),
    line: PropTypes.arrayOf(
      PropTypes.shape({}),
    ),
  }).isRequired,
  /**
   * size of the device
   */
  device: PropTypes.string.isRequired,
  owner: PropTypes.string,
};

InfoKpi.defaultProps = {
  dataColumn: {},
  dataControl: {},
  owner: '',
};

export default InfoKpi;
