/* eslint-disable no-param-reassign */

/**
 * Realiza o agrupamento de acordo com o que foi escolhido.
 * @param {object} group agrupamento a ser aplicado.
 * @param {object[]} valueList array com os valores a serem agrupados.
 * @returns {object[]} array com os resultados do agrupamento.
 */
function doGroup(group, valueList) {
  // Para retornar um indice definido quando o metodo 'findIndex' retornar -1 (nao encontrado)
  const getSafeIndex = (idx, defaultIdx) => (idx > -1 ? idx : defaultIdx);

  const value1 = group.values[0];
  const value2 = group.values[1];
  let start = 0; // Indice de inicio
  let end = Infinity; // Indice de parada

  switch (group.ftype) {
    // N Maiores (sem empate)
    case 'most':
      start = -1 * value1;
      break;
    // N Maiores (com empate)
    case 'most_inc':
      start = getSafeIndex(
        valueList.findIndex((v) => (+v.value) >= (valueList[valueList.length - Math.min(value1, valueList.length)].value)), 0,
      );
      break;
    // N Menores (sem empate)
    case 'least':
      end = value1;
      break;
    // N Menores (com empate)
    case 'least_inc':
      end = getSafeIndex(
        valueList.findIndex((v) => (+v.value) > (valueList[Math.min(value1 - 1, valueList.length - 1)].value)), Infinity,
      );
      break;
    // Maiores que N
    case 'greater':
      start = getSafeIndex(valueList.findIndex((v) => (+v.value) > (+value1)), 0);
      break;
    // Maiores ou iguais a N
    case 'greater_inc':
      start = getSafeIndex(valueList.findIndex((v) => (+v.value) >= (+value1)), 0);
      break;
    // Menores que N
    case 'lesser':
      end = getSafeIndex(valueList.findIndex((v) => (+v.value) >= (+value1)), Infinity);
      break;
    // Menores ou iguais a N
    case 'lesser_inc':
      end = getSafeIndex(valueList.findIndex((v) => (+v.value) > (+value1)), Infinity);
      break;
    // Entre A e B (exclusive)
    case 'between':
      start = getSafeIndex(valueList.findIndex((v) => (+v.value) > (+value1)), 0);
      end = getSafeIndex(valueList.findIndex((v) => (+v.value) >= (+value2)), Infinity);
      break;
    // Entre A e B (inclusive)
    case 'between_inc':
      start = getSafeIndex(valueList.findIndex((v) => (+v.value) >= (+value1)), 0);
      end = getSafeIndex(valueList.findIndex((v) => (+v.value) > (+value2)), Infinity);
      break;
    default:
      return valueList;
  }
  return valueList.splice(start, end - start);
}

/**
 * Recalcula os totais com base nos valores agrupados.
 * @param {object} groupedData os dados agrupados sem os totais.
 * @param {string} groupVar marcador para indicar qual agrupamento foi realizado anteriormente.
 * @param {string[]?} columnTotal nome utilizado na colona totais, perdidos durante o agrupamento.
 * @returns {object} um objeto com os valores de totais recalculados.
 */
function recalculateTotals(groupedData, groupVar, columnTotal) {
  if (groupVar === 'line') {
    const tValues = [];
    groupedData.index.push('Totais');
    groupedData.data.forEach((dList) => {
      dList.forEach((dVal, i) => {
        tValues[i] = ((+tValues[i] || 0) + (+dVal)).toString();
      });
    });
    groupedData.data.push(tValues);
  } else {
    groupedData.columns.push(columnTotal);
    groupedData.data.forEach((dList) => {
      let rowSum = 0;
      dList.forEach((dVal) => {
        rowSum += (+dVal);
      });
      dList.push(rowSum.toString());
    });
  }
  return groupedData;
}

/**
 * Realiza o agrupamento dos valores relacionados as linhas.
 * @param {array} tempIndex array de linhas dos dados.
 * @param {array} tempData array de valores dos dados.
 * @param {object} group agrupamento a ser aplicado.
 * @returns {object} um objeto com partes dos dados agrupados e com os totais recalculados.
 */
function groupLine(tempIndex, tempData, group) {
  const dTotals = tempData.slice(0, -1).map((val, i) => ({
    index: i,
    value: val[val.length - 1],
  }));

  dTotals.sort((a, b) => a.value - b.value);
  const groupTotals = doGroup(group, dTotals);

  const newGroupData = [];
  groupTotals.forEach((tObj) => {
    tempData[tObj.index].forEach((d, i) => {
      newGroupData[i] = ((+d) + (+newGroupData[i] || 0)).toString();
    });
  });

  const groupedData = dTotals.reduce((aux, tObj) => {
    aux.data.push(tempData[tObj.index]);
    aux.index.push(tempIndex[tObj.index]);
    return aux;
  }, { data: [newGroupData], index: [group.rule] });

  return recalculateTotals(groupedData, 'line');
}

/**
 * Realiza o agrupamento dos valores relacionados as colunas.
 * @param {array} tempColumns array de colunas dos dados.
 * @param {array} tempData array de valores dos dados.
 * @param {object} group agrupamento a ser aplicado.
 * @returns {object} um objeto com partes dos dados agrupados e com os totais recalculados.
 */
function groupColumn(tempColumns, tempData, group) {
  const dTotals = tempData[tempData.length - 1].slice(0, -1).map((val, i) => ({
    index: i,
    value: val,
  }));

  dTotals.sort((a, b) => a.value - b.value);
  const groupTotals = doGroup(group, dTotals);

  const newGroupData = [];
  tempData.forEach((dList) => {
    newGroupData.push([
      groupTotals.reduce((sum, tObj) => {
        sum += (+dList[tObj.index]);
        return sum;
      }, 0).toString(),
    ]);
  });

  const groupedData = dTotals.reduce((aux, tObj) => {
    tempData.forEach((dList, i) => {
      aux.data[i] = aux.data[i] || [];
      aux.data[i].push(dList[tObj.index]);
    });
    aux.columns.push(tempColumns[tObj.index]);
    return aux;
  }, { columns: [[tempColumns[0][0], group.rule]], data: newGroupData });

  return recalculateTotals(groupedData, 'column', tempColumns[tempColumns.length - 1]);
}

/**
 * Inicia o processo de filtragem por valores.
 * @param {object[]} gList lista de agrupamentos de valores.
 * @param {object} rawData os dados antes de serem agrupados.
 * @returns {object} um objeto com os dados agrupados.
 */
function groupByValues(gList, rawData) {
  const { columns, index, data } = rawData;
  let tempData = {
    columns,
    index,
    data,
  };

  gList.forEach((group) => {
    if (group.variable === 'column') {
      tempData = { ...tempData, ...groupColumn(tempData.columns, tempData.data, group) };
    } else {
      tempData = { ...tempData, ...groupLine(tempData.index, tempData.data, group) };
    }
  });
  return tempData;
}

export default groupByValues;
