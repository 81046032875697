/* eslint-disable no-nested-ternary */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import DataTable from '../../../core/DataTable';

import { genDataColumns, genDataRows } from '../../utils/formatCharts/handleData';

const customRowSort = (rows, config) => {
  if (config && rows) {
    const copyData = [...rows];
    const { kpiCategories } = config.CustomSortControl;
    return copyData.sort((a, b) => kpiCategories.indexOf(a.index) - kpiCategories.indexOf(b.index));
  }
  return rows;
};

const DataFrameTable = ({
  data, styleConfig, isEdit, valueFunction, globalTheme,
}) => {
  const [tableCols, setTableCols] = useState({ ready: false });
  const [tableRows, setTableRows] = useState({ ready: false });

  useEffect(() => {
    const isAvg = styleConfig?.TotalFunctionControl ? styleConfig.TotalFunctionControl === 'avg' : valueFunction === 'mean';
    setTableCols(genDataColumns(data, styleConfig, !isEdit, isAvg));
  }, [data, isEdit,
    styleConfig.ColumnShowTotalControl,
    styleConfig.LabelCurrencyControl,
    styleConfig.Decimals,
    styleConfig.separadorNumericoCasas,
    styleConfig.LabelGroupingControl,
    styleConfig.PrefixControl,
    styleConfig.SufixControl,
    valueFunction,
    styleConfig?.TotalFunctionControl,
  ]);

  useEffect(() => {
    setTableRows(genDataRows(data));
  }, [data]);

  useEffect(() => {
    if (tableRows.ready
      && styleConfig.CustomSortControl?.checked && styleConfig.CustomSortControl.kpiCategories) {
      setTableRows((cRows) => ({
        ...cRows,
        rows: customRowSort(tableRows.rows, styleConfig),
      }));
    }
  }, [
    ...styleConfig.CustomSortControl.kpiCategories,
    styleConfig.SortValuesControl,
    tableRows.ready,
  ]);

  const getSortField = () => {
    if (styleConfig.SortValuesControl === 'Customizado') return '';
    if (styleConfig.SortValuesControl === 'Não ordenado') return 'index';
    return styleConfig.ColumnToSortControl || 'index';
  };

  return tableCols.ready && tableRows.ready ? (
    <div style={{ padding: '0 5px', position: 'relative' }} className="tableWrapper">
      <DataTable
        columns={tableCols.cols || []}
        rowData={tableRows.rows || []}
        rowTotals={styleConfig.LineShowTotalControl ? tableRows.rowTotals : undefined}
        tableStyle={styleConfig}
        defaultSortField={getSortField()}
        defaultSortOrder={styleConfig.SortValuesControl === 'Decrescente' ? 'descending' : 'ascending'}
        compact
        headerColor={globalTheme?.useGlobalTheme ? globalTheme.tableHeader : styleConfig.HeaderColor ?? 'white'}
        rowColor={globalTheme?.useGlobalTheme ? globalTheme.tableRows[0] : styleConfig.RowColor ?? 'white'}
        strippedRowsColor={globalTheme?.useGlobalTheme ? globalTheme.tableRows[1] : (
          styleConfig.LineColorControl === 'striped' ? (
            styleConfig.StripedRowsColor || 'white'
          ) : (
            styleConfig.RowColor || 'white'
          )
        )}
        useNumSorting
      />
    </div>
  ) : <></>;
};

DataFrameTable.propTypes = {
  data: PropTypes.objectOf(PropTypes.any),
  styleConfig: PropTypes.objectOf(PropTypes.any),
  isEdit: PropTypes.bool,
  valueFunction: PropTypes.string,
  globalTheme: PropTypes.objectOf(PropTypes.any),
};

DataFrameTable.defaultProps = {
  data: {},
  styleConfig: {},
  isEdit: true,
  valueFunction: 'sum',
  globalTheme: {},
};

export default DataFrameTable;
