import { runTransaction } from 'firebase/firestore';
import firebase from '../firebase';
import Batch from './Batch';
import Doc from './Doc';
import Collection from './Collection';
import Transaction from './Transaction';

export const getTransaction = (handler) => runTransaction(
  firebase.db, async (tr) => handler(new Transaction(tr)),
);

export const getDb = () => firebase.db;

export const getBatch = () => new Batch();

export const getListener = () => firebase.snapShotListener;

/* ############################################################################################## */
/* USERS */
export const getRefUser = (userId) => new Doc(
  `users/${userId}`,
);

export const getRefOnRegister = (email) => new Doc(
  `onregister/${email}`,
);

export const getRefCompany = (cId) => new Doc(
  `company/${cId}`,
);

export const getRefCompanySecs = (cId) => new Collection(
  `company/${cId}/secs`,
);

export const getRefUsersList = () => new Collection('users');

export const getRefUserHistory = (userId) => new Collection(
  `users/${userId}/loginHistory`,
);

/* ############################################################################################## */
/* GRUPOS */
export const getGroupRef = (userId) => new Collection(
  `users/${userId}/groups`,
);

/* ############################################################################################## */
/* DASHBOARDS */
export const getRefDashboardItem = (userId, docId) => new Doc(
  `users/${userId}/dashboards/${docId}`,
);

export const getRefDashboardItemList = (userId) => new Collection(
  `users/${userId}/dashboards`,
);

/* ############################################################################################## */
/* SHARED */
export const getRefSharedItem = (userId, docId) => new Doc(
  `shared/${userId}/dashboards/${docId}`,
);

export const getRefSharedItemList = (userId) => new Collection(
  `shared/${userId}/dashboards`,
);

/* ############################################################################################## */
/* KPIS */
export const getRefKpiItemList = (userId, dashboardKey, snap = 'kpis') => new Collection(
  `users/${userId}/dashboards/${dashboardKey}/${snap}`,
);

export const getRefKpiItem = (userId, dashboardKey, kpiKey, snap = 'kpis') => new Doc(
  `users/${userId}/dashboards/${dashboardKey}/${snap}/${kpiKey}`,
);

export const getRefPublicKpiList = (userId, dashId) => new Collection(`public/${userId}/dashboards/${dashId}/kpis`);

export const getRefPublicDashboard = (userId, dashId) => new Doc(`public/${userId}/dashboards/${dashId}`);

export const getRefPublicLayout = (userId, dashId) => new Doc(`public/${userId}/layout/${dashId}`);

/* ############################################################################################## */
/* Mural - comments */
export const getRefCommentsList = (userId, dashboardKey) => new Collection(
  `users/${userId}/dashboards/${dashboardKey}/comments`,
);

export const getRefComment = (userId, dashboardKey, commentId) => new Doc(
  `users/${userId}/dashboards/${dashboardKey}/comments/${commentId}`,
);

/* ############################################################################################## */
/* NOTIFICATIONS */

export const getRefNotificationsCount = (companyId) => new Collection(
  `notifications/${companyId}/count`,
);

export const getRefNotificationCount = (companyId, userId) => new Doc(
  `notifications/${companyId}/count/${userId}`,
);

export const getRefNotifications = (companyId) => new Collection(
  `notifications/${companyId}/pushs`,
);

export const getRefNotification = (companyId, notificationId) => new Doc(
  `notifications/${companyId}/pushs/${notificationId}`,
);

export const getRefSystemMessages = (companyId) => new Collection(
  `notifications/${companyId}/system`,
);

export const getRefSystemMessage = (companyId, messageId) => new Doc(
  `notifications/${companyId}/system/${messageId}`,
);

/* ############################################################################################## */
/* POLICIES */

export const getRefDefaultPolicy = () => new Doc(
  'policy/default',
);

export const getRefPolicy = (policyId) => new Doc(
  `policy/${policyId}`,
);

/* ############################################################################################## */
/* SNAPSHOTS */
export const getRefSnapshot = (userId, dashboardKey, snap) => new Collection(
  `users/${userId}/dashboards/${dashboardKey}/${snap}`,
);

/* ############################################################################################## */
/* LAYOUTS */
export function layout2Firestore(layout) {
  return layout ? layout.map((v) => ({
    i: v.i,
    h: v.h,
    w: v.w,
    x: v.x,
    y: v.y,
  })) : [];
}

export function layoutAll2Firestore(stateDashboardLayout) {
  return {
    lg: layout2Firestore(stateDashboardLayout.lg || []),
    md: layout2Firestore(stateDashboardLayout.md || []),
    sm: layout2Firestore(stateDashboardLayout.sm || []),
    xs: layout2Firestore(stateDashboardLayout.xs || []),
    xxs: layout2Firestore(stateDashboardLayout.xxs || []),
  };
}

export function layoutInsert2Firestore(stateDashboardLayout, ndoc) {
  const lg = [...layout2Firestore(stateDashboardLayout?.lg || []), ndoc];
  const md = [...layout2Firestore(stateDashboardLayout?.md || []), ndoc];
  const sm = [...layout2Firestore(stateDashboardLayout?.sm || []), ndoc];
  const xs = [...layout2Firestore(stateDashboardLayout?.xs || []), ndoc];
  const xxs = [...layout2Firestore(stateDashboardLayout?.xxs || []), ndoc];
  return {
    lg, md, sm, xs, xxs,
  };
}

export function layoutUpdate2Firestore(stateDashboardLayout, ndoc) {
  const lg = [...layout2Firestore(stateDashboardLayout.lg)].map((ds) => {
    if (ds.i !== ndoc.i) return ds;
    return { ...ds, h: ndoc.h, w: ndoc.w };
  });
  const md = [...layout2Firestore(stateDashboardLayout.md)].map((ds) => {
    if (ds.i !== ndoc.i) return ds;
    return { ...ds, h: ndoc.h, w: ndoc.w };
  });
  const sm = [...layout2Firestore(stateDashboardLayout.sm)].map((ds) => {
    if (ds.i !== ndoc.i) return ds;
    return { ...ds, h: ndoc.h, w: ndoc.w };
  });
  const xs = [...layout2Firestore(stateDashboardLayout.xs)].map((ds) => {
    if (ds.i !== ndoc.i) return ds;
    return { ...ds, h: ndoc.h, w: ndoc.w };
  });
  const xxs = [...layout2Firestore(stateDashboardLayout.xxs)].map((ds) => {
    if (ds.i !== ndoc.i) return ds;
    return { ...ds, h: ndoc.h, w: ndoc.w };
  });
  return {
    lg, md, sm, xs, xxs,
  };
}

export const getRefKpiLayout = (userId, dashboardKey, snap = 'kpis') => {
  if (snap === 'kpis') {
    return new Doc(
      `users/${userId}/layouts/${dashboardKey}`,
    );
  }

  return new Doc(
    `users/${userId}/layouts/${dashboardKey}/snapshots/${snap}`,
  );
};

export const getRefDashboardLayout = (userId) => new Doc(
  `users/${userId}/layouts/dashboards_page`,
);

/* ############################################################################################## */
/* TEMPLATES */
export const getRefTemplateLayout = (dashboardKey) => new Doc(
  `system/templates/layouts/${dashboardKey}`,
);

export const getRefTemplateDoc = (dashboardKey) => new Doc(
  `system/templates/dashboards/${dashboardKey}`,
);

export const getRefTemplateList = () => new Collection(
  'system/templates/dashboards',
);

export const getRefTemplateKpis = (dashboardKey) => new Collection(
  `system/templates/dashboards/${dashboardKey}/kpis`,
);

/* ############################################################################################## */
/* COMPANY */
export const getCompanies = () => new Collection('company');

export const getCompany = (companyId) => new Doc(`company/${companyId}`);

export const getUserCompanies = () => new Collection('users');

/* ############################################################################################## */

export const getRefReports = (key) => new Doc(
  `system/bot/reports/${key}`,
);

/* ############################################################################################## */
/* Session */
export const getRefSession = (userId) => new Doc(
  `sessions/${userId}`,
);

/* ############################################################################################## */
/* Support */
export const getRefSummaries = () => new Doc(
  'system/support',
);

/* ############################################################################################## */
/* GPT */
export const getRefAssistantLogList = (userId, fileId) => new Collection(
  `users/${userId}/assistant_logs/${fileId}/logs`,
);

/* ############################################################################################## */
/* BIGDATA */
export const getRefBigDataHistory = (userId) => new Collection(
  `users/${userId}/bigDataHistory`,
);

/* ############################################################################################## */
/* L1ExtractorReports */
export const getRefL1ExtractorModules = () => new Doc(
  'system/l1ExtractorReports',
);

export const getRefL1ExtractorModuleFields = (module, plan) => new Doc(
  `system/l1ExtractorReports/moduleFields${plan}/${module}`,
);

export const getRefL1ExtractorModuleTemplates = (module, plan) => new Collection(
  `system/l1ExtractorReports/moduleFields${plan}/${module}/templates`,
);

export const getRefL1ExtractorModuleTemplate = (module, plan, template) => new Doc(
  `system/l1ExtractorReports/moduleFields${plan}/${module}/templates/${template}`,
);
export const getRefL1ExtractorTemplate = (template) => new Doc(
  `system/l1ExtractorReports/templates/${template}`,
);
