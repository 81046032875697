import React from 'react';

function Reverse() {
  return (
    <svg width="54" height="51" viewBox="0 0 54 51" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M25.092 28.8929L31.8181 48.0437L46.9437 34.748L42.1956 33.4758C42.1956 33.4758 43.2029 13.5901 28.0424 5.39545C34.3195 15.8766 30.1148 30.2387 30.1148 30.2387L25.092 28.8929Z" />
      <path d="M28.908 22.976L22.1819 3.8252L7.05632 17.1209L11.8044 18.3931C11.8044 18.3931 10.7971 38.2787 25.9576 46.4734C19.6806 35.9923 23.8852 21.6301 23.8852 21.6301L28.908 22.976Z" />
    </svg>
  );
}

export default Reverse;
