import styled, { css } from 'styled-components';
import { devices } from '../../../juristec-ui/core/breakpoints';
import { gap } from '../../../juristec-ui/styles/theme';

export const StyledContainer = styled.div`
  ${({ disabled, image }) => (disabled ? css`
    background-color: transparent;
    opacity: 1;
    background-image: url(${image});
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 100%;
  ` : css`
    opacity: 1;
  `)};
`;

export const StyledGridContainerCreateKpi = styled.div`
  margin: 0 0.5vw;
  display: grid;
  grid-template-columns: 345px auto;
  grid-template-rows: 70px 1fr;
  grid-gap: 10px;
  grid-template-areas: 
    "fileInfo kpiType"
    "sideBar main";
  height: 100%;

  @media ${devices.phoneS}, ${devices.phoneL} {
    display: flex;
    flex-direction: column;

    .fileInfo {
      margin: 5px 0px;
    }
    .kpiType {
      margin-bottom: 5px;
    }
    .sideBar {
      margin-bottom: 5px;
    }
  }

  .middleDiv{
    display: flex;
    margin: auto;
    justify-content: flex-start;
    width: 100%;
    & > div:first-child {
      max-width: calc(100% - 42px);
    }
    & > div:last-child {
      max-width: none;
    }
  }
  .fileInfo {
    grid-area: fileInfo;
    display: flex;
    border-radius: 4px;
  }
  .sideBar {
    grid-area: sideBar;
    display: flex;
    flex-direction: column;
  }
  .kpiType {
    grid-area: kpiType;
    padding: 0px 1%;
    display: flex;
    align-items: center;
    box-shadow: 0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12);
    border-radius: 4px;
  }
  .main {
    grid-area: main;
    display: flex;
    justify-content: center;
    min-width: 0px;
    border-radius: 4px;
    max-height: calc(100vh - 185px);
    background-color: transparent;

    & > .chart {
      overflow-y: hidden;
    }

    & > div {
      overflow-y: ${(props) => (props.chartType !== 'Table' ? 'auto' : 'hidden')};
    }
  }
`;

export const StyledContainerDoubleSelect = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 15px;

  & > div:first-child {
    margin-bottom: 10px;
  }

  border-left: 5px solid ${({ theme }) => theme.containerLine};
  background-color: ${({ theme }) => theme.container};

  &:nth-child(odd) {
    background-color: ${({ theme }) => theme.containerOdd};
    border-left-color: ${({ theme }) => theme.containerLineOdd};
  }
`;

export const StyledContentTab = styled.div`
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 335px);
  @media ${devices.tablet} {
    max-height: calc(100vh - 360px);
  }
  padding: 5px 3px;
  overflow-y: auto;
  overflow-x: hidden;

  & .gap-fix-first {
    display: flex;
    padding: 10px 0px;
    flex-direction: column;
    ${gap('15px', 'column')}
  }

  & .gap-fix-second {
    display: flex;
    flex-direction: row;
    ${gap('5px', 'row')}
  }
`;

export const ModifiersContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  ${gap('10px', 'column')}
`;

export const StyledContainerButtonActions = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 5px;
  margin-bottom: 10px;

  & > button {
    min-width: 0px;
    align-items: center;

    & svg {
      margin-left: auto;
      fill: white;
    }
  }
`;

export const LabelSmall = styled.span`
  font-weight: bold;
  color: ${({ theme }) => theme.primary};
  margin: 0;
  font-size: 14px;
`;

export const OuterKpiCreate = styled.main`
  @page {
    size: auto;
    margin: auto;
  }

  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: auto;
  padding: 5px 0;
  margin-top: 0rem;
  margin-left: 5%;
  margin-right: 5%;

  box-sizing: border-box;
  height: calc(100vh - 124px) !important;
  @media ${devices.tablet} {
    margin: 0px 0px;
    height: calc(100vh - 112px) !important;
  } ;
`;

export const LegendsContainer = styled.div`
  display: flex;
  ${gap('10px', 'row')}
  justify-content: center;
  margin: 10px 0;
  & .legend-type {
    display: flex;
    ${gap('5px', 'row')}
    align-items: center;
  }

  & .square {
    height: 20px;
    width: 20px;
    border-radius: 5px;
  }

  & .legend-texto {
    background: ${({ theme }) => theme.typeText};
  }

  & .legend-numero {
    background: ${({ theme }) => theme.typeNumber};
  }

  & .legend-date {
    background: ${({ theme }) => theme.typeDate};
  }
`;

export const GenChartAlert = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: ${({ theme }) => theme.fadedBackground};
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding-top: 10%;
  box-sizing: border-box;
  color: ${({ theme }) => theme.white};
`;

export const IconCircle = styled.div`
  display: flex;
  width: 100px;
  height: 100px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 2.5px;
  background-clip: content-box;
  border: 2.5px solid #ff8800;
  background-color: #ff8800;
  & svg {
    color: ${({ theme }) => theme.white};
    width: 55px;
    height: 55px;
  }
`;
