import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

// import Avatar from '../../core/Avatar';
import Tooltip from '../../../juristec-ui/core/Tooltip';
import IconButton from '../../../juristec-ui/core/IconButton';
import Button from '../../../juristec-ui/core/Button';
import SearchFilter from '../../../juristec-ui/core/SearchFilter';
// import AddDashModal from '../Modals/NewDashboard';
import {
  Add, ArrowLeft, Edit, Import, Trash,
} from '../../../juristec-ui/icons';
import {
  DashboardAvatarInformation,
  GridDashboardToolbarStyled,
  DashboardWelcomeInformation,
  DashboardActions,
} from './styled/GridDashboardToolbar.styled';
// import NewDashboard from '../Modals/NewDashboard';

const GridDashboardToolbar = ({
  user,
  dashboards,
  saveLayout,
  editPainel,
  setEditLayout,
  isLoading,
  // filters
  // filterText,
  setFilterText,
  selectedGroups,
  setSelectedGroups,
  selectedGeneral,
  setSelectedGeneral,
  openTrashCan,
  setOpenTrashCan,
  trashCount,
  clearTrashCan,
  //
  groups,
  handleCreateModal,
  importDashboard,
  tourContext = { tourOpen: false },
}) => {
  const cronRef = useRef();
  const [searchText, setSearchText] = useState('');
  // const [openAddModal, setOpenAddModal] = useState(false);

  const handleSearch = (txt) => {
    setSearchText(txt);
    clearTimeout(cronRef.current);
    cronRef.current = setTimeout(() => {
      setFilterText(txt);
    }, 200);
  };

  return (
    <GridDashboardToolbarStyled openTrashCan={openTrashCan} editPainel={editPainel}>
      {!openTrashCan ? (
        <DashboardWelcomeInformation>
          <h1>
            Olá,
            {` ${user?.name}!`}
          </h1>
          <>
            <h3>
              Dashboards:
              <span>{` ${dashboards?.filter((d) => !d.isDeleted).length || 0}`}</span>
            </h3>
          </>
        </DashboardWelcomeInformation>
      ) : (
        <div className="gap-fix">
          <DashboardAvatarInformation>
            <Tooltip text="Voltar" direction="right">
              <IconButton variant="contained" color="secondary" size="medium" shape="rounded" onClick={() => setOpenTrashCan(false)}>
                <ArrowLeft />
              </IconButton>
            </Tooltip>
          </DashboardAvatarInformation>
          <DashboardWelcomeInformation>
            <h1>
              Lixeira
            </h1>
            <h3>
              Dashboards:
              <span>{` ${trashCount}`}</span>
            </h3>
          </DashboardWelcomeInformation>
        </div>
      )}
      <DashboardActions editPainel={editPainel} openTrashCan={openTrashCan}>
        {/* eslint-disable-next-line no-nested-ternary */}
        {editPainel ? (
          <Button variant="contained" color="secondary" onClick={() => saveLayout()}>
            Salvar
          </Button>
        ) : openTrashCan ? (
          <Button
            variant="contained"
            color="secondary"
            shape="rounded"
            size="medium"
            className="action-trash"
            onClick={() => clearTrashCan(dashboards?.filter((d) => d.isDeleted))}
          >
            Esvaziar Lixeira
          </Button>
        ) : (
          <>
            <SearchFilter
              radioLabel="Geral"
              radioOptions={[
                { label: 'Todos os Dashboards', value: 'all' },
                { label: 'Meus Dashboards', value: 'my' },
                { label: 'Compartilhados Comigo', value: 'shared' },
                { label: 'Dashboards Favoritos', value: 'bookmarked' },
              ]}
              radioSelected={selectedGeneral}
              setRadioSelected={setSelectedGeneral}
              checkboxLabel="Grupos"
              checkboxOptions={Object.keys(groups).reduce((aux, g) => {
                aux[g] = groups[g]?.value || g;
                return aux;
              }, { 'Sem Grupo': '' })}
              checkboxSelected={selectedGroups}
              setCheckboxSelected={setSelectedGroups}
              textSearch={searchText}
              setTextSearch={handleSearch}
            />
            {trashCount > 0 && (
              <Tooltip text="Lixeira">
                <IconButton
                  variant="outlined"
                  color="primary"
                  shape="rounded"
                  size="medium"
                  className="action-trash"
                  onClick={() => setOpenTrashCan(true)}
                >
                  <Trash />
                  <span style={{ fontWeight: 'bold' }}>{` (${trashCount})`}</span>
                </IconButton>
              </Tooltip>
            )}
            <Tooltip text="Importar Dashboard">
              <IconButton
                variant="pattern"
                color="primary"
                shape="rounded"
                onClick={importDashboard}
              >
                <Import />
              </IconButton>
            </Tooltip>
            <Tooltip text="Reposicionar Dashboards">
              <IconButton
                variant="pattern"
                color="primary"
                shape="rounded"
                onClick={() => setEditLayout(true)}
              >
                <Edit />
              </IconButton>
            </Tooltip>

            {user?.role !== 'guest' && (
              <Tooltip text="Adicionar dashboard" className="action-button">
                <IconButton
                  id="createDashboard_first"
                  variant="contained"
                  color="secondary"
                  shape="rounded"
                  onClick={handleCreateModal}
                  isLoading={isLoading}
                >
                  <Add />
                </IconButton>
              </Tooltip>
            )}
          </>
        )}
      </DashboardActions>
      {/* {openAddModal && (
        <NewDashboard
          showModal={openAddModal}
          setShowModal={setOpenAddModal}
          createNewDashboard={handleCreateModal}
          groupNameList={Object.keys(groups).map((g) => ({ label: g, value: g }))}
          currentUser={user}
        />
      )} */}
    </GridDashboardToolbarStyled>
  );
};

GridDashboardToolbar.propTypes = {
  importDashboard: PropTypes.func,
};

GridDashboardToolbar.defaultProps = {
  importDashboard: () => {},
};

export default GridDashboardToolbar;
