import React, {
  useReducer, useMemo, useCallback, createContext, useContext, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { ip } from '../../utils/functions/urls';
import getRequestMeta from '../../utils/functions/generateMeta';
import { error as errorLabels } from '../../label';
import { AuthContext } from '../AuthProvider';

import firebase from '../../utils/firebase';
import firestoreErrors from '../../utils/firestoreErrors';
import { getRefReports, getRefAssistantLogList } from '../../utils/firestore';
import unsecureFileNames from '../../utils/unsecureFileNames';

import { waitMessagesUpload, filesViewerWaitMessagesReUpload, waitMessagesDonwload } from '../../options';

const getToken = async (u) => u.getIdToken();

export const FilesContext = createContext(null);

const logsLimitPerLoad = 10;

const initialState = {
  files: [],
  instanceFiles: [],
  isLoading: false,
  started: false,
  size: 0,
  dashboards: 0,
  waitingMsg: '',
};

const reduce = (state, action) => {
  // console.log('@filesProvider reducer: ', { state, action });
  switch (action.type) {
    case 'init': {
      return {
        ...state,
        dashboards: action.dashboards,
        size: action.size,
        files: action.files,
        instanceFiles: action.instanceFiles,
        isLoading: false,
        started: true,
        waitingMsg: action.waitingMsg || '',
      };
    }
    case 'setCompanyStorage': {
      return {
        ...state,
        dashboards: action.dashboards,
        size: action.size,
        isLoading: false,
      };
    }
    case 'setFiles':
      return {
        ...state,
        isLoading: false,
        files: action.files,
        waitingMsg: action.waitingMsg || '',
      };
    case 'deleteFile': {
      const { filename, id } = action.deleted;
      return {
        ...state,
        size: action.size,
        files: state.files.filter((f) => f.id !== id || f.filename !== filename),
        isLoading: false,
        waitingMsg: action.waitingMsg || '',
      };
    }

    case 'setLoading':
      return { ...state, isLoading: action.isLoading, waitingMsg: action.msg || '' };

    case 'clear':
      return initialState;

    default:
      return state;
  }
};

const FilesProvider = ({ children: providerChild }) => {
  const [state, dispatch] = useReducer(reduce, initialState);
  const setLoading = (l = true, msg = '') => dispatch({ type: 'setLoading', isLoading: l, msg });

  const { currentUser, user, claimsUser } = useContext(AuthContext);

  useEffect(() => {
    if (!currentUser) {
      dispatch({ type: 'clear' });
    }
  }, [currentUser]);

  const getFilesList = useCallback(async () => {
    try {
      const token = await getToken(currentUser);

      const opt = {
        ...await getRequestMeta(token, 'GET'),
      };

      const resFetch = await fetch(`${ip}/db/list?withInfo=true`, opt);

      const json = await resFetch.json();

      if (resFetch.status !== 200) {
        return { error: true, msg: errorLabels.filesProvider.getFilesList, raw: json.error };
      }

      let files = json?.info || [];
      if (claimsUser?.is_admin) {
        files = files.filter((f) => (
          f.owner === currentUser.uid || f.shared_read.includes(user.uid)
        ));
      }

      // const parsed = parse(json);
      return { error: false, msg: null, files };
    } catch (er) {
      console.log(er);
      return { error: true, msg: errorLabels.filesProvider.getFilesList, raw: `Erro do sistema: ${er.toString()}` };
    }
  }, [currentUser, claimsUser]);

  const getInstanceFilesList = useCallback(async () => {
    try {
      const token = await getToken(currentUser);

      const opt = {
        ...await getRequestMeta(token, 'GET'),
      };

      const resFetch = await fetch(`${ip}/db/list?withInfo=false`, opt);

      const json = await resFetch.json();

      if (resFetch.status !== 200) {
        return { error: true, msg: errorLabels.filesProvider.getFilesList, raw: json.error };
      }

      // const parsed = parse(json);
      return { error: false, msg: null, files: json.info };
    } catch (er) {
      console.log(er);
      return { error: true, msg: errorLabels.filesProvider.getFilesList, raw: `Erro do sistema: ${er.toString()}` };
    }
  }, [currentUser]);

  const getFilesMetadataList = useCallback(async (filesName, filesOwner) => {
    try {
      const token = await getToken(currentUser);

      const opt = {
        ...await getRequestMeta(token, 'POST', 'JSON'),
        body: JSON.stringify({
          files: filesName.map((fn) => (
            {
              filename: fn,
              ownerId: filesOwner,
            }
          )),
        }),
      };

      const resFetch = await fetch(`${ip}/db/list`, opt);

      const json = await resFetch.json();

      if (resFetch.status !== 200) {
        return { error: true, msg: errorLabels.filesProvider.getFilesList, raw: json.error };
      }

      return { error: false, msg: null, files: json.info };
    } catch (er) {
      console.log(er);
      return { error: true, msg: errorLabels.filesProvider.getFilesList, raw: `Erro do sistema: ${er.toString()}` };
    }
  }, [currentUser]);

  /**
     *  getCompanyStorage
     *  return { size: number, dashboards: number }
     * // qtd de dash da empresa e size q a empresa ocupa.
     */
  const getCurrentCompanyStorage = useCallback(async () => {
    try {
      const token = await getToken(currentUser);

      const opt = {
        ...await getRequestMeta(token),
        // body: JSON.stringify({companyid:user.company}),
      };

      const resFetch = await fetch(`${ip}/manage/company`, opt);
      const json = await resFetch.json();

      if (resFetch.status >= 500) {
        return {
          error: true,
          msg: errorLabels.fetchGeneric,
          raw: 'Verifique se existe algum problema com a sua conexão com a internet e tente novamente mais tarde!',
        };
      }

      if (resFetch.status !== 200) {
        return {
          error: true,
          msg: errorLabels.filesProvider.getCurrentCompanyStorage,
          raw: json.error,
        };
      }

      return { error: false, msg: null, raw: json.info };
    } catch (er) {
      console.log(er);
      return { error: true, msg: errorLabels.filesProvider.getCurrentCompanyStorage, raw: `Erro do sistema: ${er.toString()}` };
    }
  }, [currentUser, user]);

  const optimize = useCallback(async (dataToSend) => {
    try {
      const token = await getToken(currentUser);
      const opt = {
        ...await getRequestMeta(token, 'POST', 'JSON'),
        body: JSON.stringify(dataToSend),
      };
      const resFetch = await fetch(`${ip}/db/create/upload`, opt);
      if (resFetch.status === 500) {
        return {
          error: true,
          msg: errorLabels.fetchGeneric,
          raw: 'Verifique se existe algum problema com a sua conexão com a internet e tente novamente mais tarde!',
        };
      }
      const json = await resFetch.json();
      if (resFetch.status !== 200) {
        return { error: true, msg: errorLabels.filesProvider.optimize, raw: json.error };
      }
      return { error: false, msg: null, res: json.info };
    } catch (er) {
      console.log(er);
      return { error: true, msg: errorLabels.filesProvider.optimize, raw: `Erro do sistema: ${er.toString()}` };
    }
  }, [currentUser]);

  const convertFileToMail = useCallback(async (fileName, fileId) => {
    try {
      const token = await getToken(currentUser);
      const opt = {
        ...await getRequestMeta(token, 'POST', 'JSON'),
        body: JSON.stringify({
          filename: unsecureFileNames(fileName.replace('.metrics', '')),
          file_id: fileId,
          origin: 'Mail',
          search_field: 'subject',
          search_info: 'hash',
        }),
      };
      const resFetch = await fetch(`${ip}/db/create/mail`, opt);
      const json = await resFetch.json();
      if (resFetch.status === 500) {
        return {
          error: true,
          msg: errorLabels.fetchGeneric,
          raw: 'Verifique se existe algum problema com a sua conexão com a internet e tente novamente mais tarde!',
        };
      }
      if (resFetch.status !== 200) {
        return { error: true, msg: json?.msg || errorLabels.filesProvider.convertFileToMail, raw: json };
      }
      return { error: false, msg: null, res: json };
    } catch (er) {
      console.log(er);
      return { error: true, msg: errorLabels.filesProvider.convertFileToMail, raw: `Erro do sistema: ${er.toString()}` };
    }
  }, [currentUser]);

  const convertFileApiGed = useCallback(async (fileName, fileId) => {
    try {
      const token = await getToken(currentUser);
      const opt = {
        ...await getRequestMeta(token, 'POST', 'JSON'),
        body: JSON.stringify({
          filename: unsecureFileNames(fileName.replace('.metrics', '')),
          file_id: fileId,
          origin: 'L1ReportsApi',
          search_info: 'filename',
        }),
      };
      const resFetch = await fetch(`${ip}/db/create/l1reportsapi`, opt);
      const json = await resFetch.json();
      if (resFetch.status === 500) {
        return {
          error: true,
          msg: errorLabels.fetchGeneric,
          raw: 'Verifique se existe algum problema com a sua conexão com a internet e tente novamente mais tarde!',
        };
      }
      if (resFetch.status !== 200) {
        return { error: true, msg: json?.msg || errorLabels.filesProvider.convertFileApiGed, raw: json };
      }
      return { error: false, msg: null, res: json };
    } catch (er) {
      console.log(er);
      return { error: true, msg: errorLabels.filesProvider.convertFileApiGed, raw: `Erro do sistema: ${er.toString()}` };
    }
  }, [currentUser]);

  const downloadFile = useCallback(async (file) => {
    try {
      const token = await getToken(currentUser);
      const opt = {
        ...await getRequestMeta(token, 'GET'),
        responseType: 'blob',
      };
      const resFetch = await fetch(`${ip}/db/file/${file.file_id}/export`, opt);
      if (resFetch.status === 500) {
        return {
          error: true,
          msg: errorLabels.fetchGeneric,
          raw: 'Verifique se existe algum problema com a sua conexão com a internet e tente novamente mais tarde!',
        };
      }
      if (resFetch.status !== 200) {
        const json = await resFetch.json();
        return { error: true, msg: errorLabels.filesProvider.downloadFile, raw: json.error };
      }

      const blob = await resFetch.blob();
      const dFileName = file.filename.replace(/\.metrics|\.xlsx|\.xls|\.csv/g, '');

      const url = URL.createObjectURL(blob);
      const aNode = document.createElement('a');
      aNode.href = url;
      aNode.download = `${dFileName}.xlsx` || 'dados.xlsx';
      aNode.click();

      return { error: false, msg: null, res: blob };
    } catch (er) {
      console.log(er);
      return { error: true, msg: errorLabels.filesProvider.downloadFile, raw: `Erro do sistema: ${er.toString()}` };
    }
  }, [currentUser]);

  const getFileSample = useCallback(async (database, schema, rows = 20, page = 1) => {
    try {
      const token = await getToken(currentUser);
      const opt = {
        ...await getRequestMeta(token, 'POST', 'JSON'),
        responseType: 'blob',
        body: JSON.stringify({
          file_id: database,
          schema,
          rows,
          page,
        }),
      };
      const resFetch = await fetch(`${ip}/kpis/sample`, opt);

      if (resFetch.status >= 500) {
        return {
          error: true,
          msg: errorLabels.fetchGeneric,
          raw: 'Verifique se existe algum problema com a sua conexão com a internet e tente novamente mais tarde!',
        };
      }

      if (resFetch.status === 404) {
        return {
          error: true,
          msg: errorLabels.filesProvider.getSample,
          raw: 'O arquivo responsável pela geração deste KPI foi excluído. Desta forma, não é possível editar, clonar, atualizar ou obter o relatório relativo a este KPI.',
        };
      }

      const json = await resFetch.json();
      if (resFetch.status !== 200) {
        return { error: true, msg: errorLabels.filesProvider.getSample, raw: json.error };
      }

      return { error: false, msg: null, res: json.info };
    } catch (er) {
      console.log(er);
      return { error: true, msg: errorLabels.filesProvider.getSample, raw: `Erro do sistema: ${er.toString()}` };
    }
  }, [currentUser]);

  const getFile = useCallback(async (fileId) => {
    try {
      const token = await getToken(currentUser);
      // const companyid_body = (origin === 'LegalOne') ? user.company : null;
      const opt = {
        ...await getRequestMeta(token),
      };

      const resFetch = await fetch(`${ip}/db/file/${fileId}`, opt);

      if (resFetch.status >= 500) {
        return {
          error: true,
          msg: errorLabels.fetchGeneric,
          raw: 'Verifique se existe algum problema com a sua conexão com a internet e tente novamente mais tarde!',
        };
      }

      // const resFetch = await fetch(`${ip}/storage/files?filename=${fileName}${origin === 'LegalOne'
      //   ? `&companyid=${user.company}` : ''}`,
      // await getRequestMeta(token));
      const json = await resFetch.json();

      if (resFetch.status !== 200) {
        return { error: true, msg: json?.msg || errorLabels.filesProvider.getFile, raw: json.error };
      }

      // const parsed = parse(json);
      return { error: false, msg: null, res: json.info };
    } catch (er) {
      console.log(er);
      return { error: true, msg: errorLabels.filesProvider.getFile, raw: `Erro do sistema: ${er.toString()}` };
    }
  }, [currentUser, user]);

  const uploadFile = useCallback(async (file, overwrite) => {
    try {
      const token = await getToken(currentUser);

      const data = new FormData();
      data.append('file', file, file.name);
      data.append('overwrite', overwrite);

      const opt = { ...await getRequestMeta(token, 'POST'), body: data };

      const resFetch = await fetch(`${ip}/files/upload`, opt);
      const json = await resFetch.json();

      if (resFetch.status >= 500) {
        return {
          error: true,
          msg: errorLabels.fetchGeneric,
          raw: 'Verifique se existe algum problema com a sua conexão com a internet e tente novamente mais tarde!',
        };
      }

      if (resFetch.status !== 200) {
        return { error: true, msg: errorLabels.filesProvider.uploadFile, raw: json.error };
      }
      return { error: false, msg: null, res: json.info };
    } catch (er) {
      console.log(er);
      return { error: true, msg: errorLabels.filesProvider.uploadFile, raw: `Erro do sistema: ${er.toString()}` };
    }
  }, [currentUser]);

  const uploadFileByLink = useCallback(async (url, filename, headerSkip, connector) => {
    try {
      const token = await getToken(currentUser);
      const opt = {
        ...await getRequestMeta(token, 'POST', 'JSON'),
        body: JSON.stringify(
          // { url, overwrite }
          {
            filename,
            url,
            header_skip: headerSkip,
            predefined_connector: connector,
          },
        ),
      };
      const resFetch = await fetch(`${ip}/db/create/web`, opt);
      const json = await resFetch.json();

      if (resFetch.status >= 500) {
        return {
          error: true,
          msg: errorLabels.fetchGeneric,
          raw: 'Verifique se existe algum problema com a sua conexão com a internet e tente novamente mais tarde!',
        };
      }

      if (resFetch.status !== 200) {
        return { error: true, msg: json.msg || errorLabels.filesProvider.uploadFileByLink, raw: json.error };
      }

      return { error: false, msg: null, res: json.info };
    } catch (er) {
      console.log(er);
      return { error: true, msg: errorLabels.filesProvider.uploadFileByLink, raw: `Erro do sistema: ${er.toString()}` };
    }
  }, [currentUser]);

  // TODO: alternar o tipo de dbs (quando a rota existir) e atualizar para a rota nova
  const uploadBySqlDB = useCallback(async (params) => {
    try {
      const token = await getToken(currentUser);
      const opt = {
        ...await getRequestMeta(token, 'POST', 'JSON'),
        body: JSON.stringify(params),
      };
      const resFetch = await fetch(`${ip}/db/create/sql`, opt);
      const json = await resFetch.json();

      if (resFetch.status >= 500) {
        return {
          error: true,
          msg: errorLabels.fetchGeneric,
          raw: 'Verifique se existe algum problema com a sua conexão com a internet e tente novamente mais tarde!',
        };
      }

      if (resFetch.status !== 200) {
        return {
          error: true, msg: json.msg || errorLabels.filesProvider.uploadBySql, raw: json.error,
        };
      }

      return { error: false, msg: null, res: json.info };
    } catch (er) {
      console.log(er);
      return { error: true, msg: errorLabels.filesProvider.uploadBySql, raw: `Erro do sistema: ${er.toString()}` };
    }
  }, [currentUser]);

  const testSqlConnection = useCallback(
    async (params) => {
      try {
        const token = await getToken(currentUser);
        const opt = {
          ...(await getRequestMeta(token, 'POST', 'JSON')),
          body: JSON.stringify(params),
        };
        const resFetch = await fetch(`${ip}/db/utils/sql/check_conn`, opt);
        const json = await resFetch.json();

        if (resFetch.status >= 500) {
          return {
            error: true,
            msg: errorLabels.filesProvider.uploadBySql,
            raw: 'Houve um erro ao se conectar com o banco, verifique as credenciais inseridas!',
          };
        }

        if (resFetch.status !== 200) {
          return {
            error: true,
            msg: json.msg || errorLabels.filesProvider.uploadBySql,
            raw: json.error,
          };
        }

        return { error: false, msg: null, res: json.info };
      } catch (er) {
        console.log(er);
        return {
          error: true,
          msg: errorLabels.filesProvider.uploadBySql,
          raw: `Erro do sistema: ${er.toString()}`,
        };
      }
    },
    [currentUser],
  );


  const uploadBennerReport = useCallback(async (filename, url, username, password, headerSkip) => {
    try {
      const token = await getToken(currentUser);
      const opt = {
        ...await getRequestMeta(token, 'POST', 'JSON'),
        body: JSON.stringify({
          filename,
          predefined_connector: 'Benner',
          url,
          auth: {
            method: 'Basic Auth',
            username,
            password,
          },
          header_skip: headerSkip,
        }),
      };
      const resFetch = await fetch(`${ip}/db/create/web`, opt);
      const json = await resFetch.json();

      if (resFetch.status >= 500) {
        return {
          error: true,
          msg: errorLabels.fetchGeneric,
          raw: 'Verifique se existe algum problema com a sua conexão com a internet e tente novamente mais tarde!',
        };
      }

      if (resFetch.status !== 200) {
        return {
          error: true, msg: json.msg || errorLabels.filesProvider.uploadBennerReport, raw: json.error,
        };
      }

      return { error: false, msg: null, res: json.info };
    } catch (er) {
      console.log(er);
      return { error: true, msg: errorLabels.filesProvider.uploadBennerReport, raw: `Erro do sistema: ${er.toString()}` };
    }
  }, [currentUser]);

  const deleteFile = useCallback(async (item) => {
    try {
      const token = await getToken(currentUser);
      const opt = {
        ...await getRequestMeta(token, 'DELETE', 'JSON'),
      };
      const resFetch = await fetch(`${ip}/db/file/${item.file_id}`, opt);

      if (resFetch.status >= 500) {
        return {
          error: true,
          msg: errorLabels.fetchGeneric,
          raw: 'Verifique se existe algum problema com a sua conexão com a internet e tente novamente mais tarde!',
        };
      }

      const json = await resFetch.json();

      if (resFetch.status !== 200) {
        return {
          error: true,
          msg: errorLabels.filesProvider.deleteFile,
          raw: json.error,
        };
      }
      return {
        error: false,
        msg: null,
        deleted: item,
      };
    } catch (er) {
      console.log(er);
      return { error: true, msg: errorLabels.filesProvider.deleteFile, raw: `Erro do sistema: ${er.toString()}` };
    }
  }, [currentUser]);

  const deleteGlobalFile = useCallback(async (item) => {
    try {
      const filename = item.filefullname;
      const fileRef = firebase.bucket.child(
        `users/${user.company}/databases/${filename}`,
      );

      await fileRef.delete();

      const reportId = item.filefullname.split('-')[0];
      const report = getRefReports(reportId);
      await report.delete();

      return { error: false, msg: null, deleted: item };
    } catch (er) {
      console.log(er);
      return { error: true, msg: firestoreErrors(er.code) || errorLabels.filesProvider.deleteGlobalFile, raw: `Erro do sistema: ${er.toString()}` };
    }
  }, [user]);

  const reopen = useCallback(async (filename, decimal, header) => {
    try {
      const token = await getToken(currentUser);
      const opt = {
        ...await getRequestMeta(token, 'POST', 'JSON'),
        body: JSON.stringify({
          filename, decimal, header, ownerId: currentUser.uid,
        }),
      };
      const resFetch = await fetch(`${ip}/files/reopen`, opt);

      if (resFetch.status >= 500) {
        return {
          error: true,
          msg: errorLabels.fetchGeneric,
          raw: 'Verifique se existe algum problema com a sua conexão com a internet e tente novamente mais tarde!',
        };
      }

      const json = await resFetch.json();

      if (resFetch.status !== 200) {
        return { error: true, msg: errorLabels.filesProvider.reopen, raw: json.error };
      }

      return { error: false, msg: null, res: json.info };
    } catch (er) {
      console.log(er);
      return { error: true, msg: errorLabels.filesProvider.reopen, raw: `Erro do sistema: ${er.toString()}` };
    }
  }, [currentUser]);

  const getUnique = useCallback(async (database, column, selector = '') => {
    try {
      const token = await getToken(currentUser);
      // const userId = ownerId || currentUser.id || currentUser.uid;
      const opt = {
        ...await getRequestMeta(token, 'POST', 'JSON'),
        body: JSON.stringify({
          file_id: database,
          column,
          selector,
        }),
      };

      const resFetch = await fetch(`${ip}/kpis/unique`, opt);

      if (resFetch.status >= 500) {
        return {
          error: true,
          msg: errorLabels.fetchGeneric,
          raw: 'Verifique se existe algum problema com a sua conexão com a internet e tente novamente mais tarde!',
        };
      }

      const json = await resFetch.json();

      if (resFetch.status !== 200) {
        return { error: true, msg: json?.msg || errorLabels.filesProvider.getUnique, raw: json.error };
      }

      return { error: false, msg: null, res: json.info };
    } catch (er) {
      console.log(er);
      return { error: true, msg: errorLabels.filesProvider.getUnique, raw: `Erro do sistema: ${er.toString()}` };
    }
  }, [currentUser]);

  const getColumns = useCallback(async (database) => {
    try {
      const token = await getToken(currentUser);
      // const userId = ownerId || currentUser.id || currentUser.uid;
      const opt = {
        ...await getRequestMeta(token),
      };

      const resFetch = await fetch(`${ip}/db/file/${database}/columns`, opt);

      if (resFetch.status >= 500) {
        return {
          error: true,
          msg: errorLabels.fetchGeneric,
          raw: 'Verifique se existe algum problema com a sua conexão com a internet e tente novamente mais tarde!',
        };
      }

      const json = await resFetch.json();

      if (resFetch.status !== 200) {
        return {
          error: true, msg: json?.msg || errorLabels.filesProvider.getColumns, raw: json.error,
        };
      }

      return {
        error: false, msg: null, columns: json.columns, alias: json.alias,
      };
    } catch (er) {
      console.log(er);
      return { error: true, msg: errorLabels.filesProvider.getColumns, raw: `Erro do sistema: ${er.toString()}` };
    }
  }, [currentUser]);

  const editColumns = useCallback(async (database, columnTypes) => {
    try {
      const token = await getToken(currentUser);
      // const userId = ownerId || currentUser.id || currentUser.uid;
      const opt = {
        ...await getRequestMeta(token, 'PUT', 'JSON'),
        body: JSON.stringify({
          columns: Object.keys(columnTypes).map((column) => (
            {
              src: column,
              dst: column,
              type: columnTypes[column],
            }
          )),
        }),
      };

      const resFetch = await fetch(`${ip}/db/file/${database}/columns`, opt);

      if (resFetch.status >= 500) {
        return {
          error: true,
          msg: errorLabels.fetchGeneric,
          raw: 'Verifique se existe algum problema com a sua conexão com a internet e tente novamente mais tarde!',
        };
      }

      const json = await resFetch.json();

      if (resFetch.status !== 200) {
        return {
          error: true, msg: json?.msg || errorLabels.filesProvider.editColumns, raw: json.error,
        };
      }

      return { error: false, msg: null, res: json.columns };
    } catch (er) {
      console.log(er);
      return { error: true, msg: errorLabels.filesProvider.editColumns, raw: `Erro do sistema: ${er.toString()}` };
    }
  }, [currentUser]);

  const renameColumns = useCallback(async (database, aliasMap) => {
    try {
      const token = await getToken(currentUser);

      const opt = {
        ...await getRequestMeta(token, 'POST', 'JSON'),
        body: JSON.stringify({
          columns: aliasMap.map((column) => (
            {
              src: column.src,
              dst: column.dst,
            }
          )),
        }),
      };

      const resFetch = await fetch(`${ip}/db/file/${database}/columns/alias`, opt);

      if (resFetch.status >= 500) {
        return {
          error: true,
          msg: errorLabels.fetchGeneric,
          raw: 'Verifique se existe algum problema com a sua conexão com a internet e tente novamente mais tarde!',
        };
      }

      const json = await resFetch.json();

      if (resFetch.status !== 200) {
        return {
          error: true, msg: json?.msg || errorLabels.filesProvider.renameColumns, raw: json.error,
        };
      }

      return { error: false, msg: null, res: json.columns };
    } catch (er) {
      console.log(er);
      return { error: true, msg: errorLabels.filesProvider.renameColumns, raw: `Erro do sistema: ${er.toString()}` };
    }
  }, [currentUser]);

  const previewCustomColumns = useCallback(async (database, newColumnsList) => {
    try {
      const token = await getToken(currentUser);
      const opt = {
        ...await getRequestMeta(token, 'POST', 'JSON'),
        body: JSON.stringify({
          newColumns: newColumnsList,
        }),
      };

      const resFetch = await fetch(`${ip}/db/file/customcolumns/preview/${database}`, opt);

      if (resFetch.status >= 500) {
        return {
          error: true,
          msg: errorLabels.fetchGeneric,
          raw: 'Verifique se existe algum problema com a sua conexão com a internet e tente novamente mais tarde!',
        };
      }

      const json = await resFetch.json();

      if (resFetch.status !== 200) {
        return {
          error: true,
          msg: json?.msg || errorLabels.filesProvider.previewCustomColumns,
          raw: json.error,
        };
      }

      return { error: false, msg: null, res: json.info };
    } catch (er) {
      console.log(er);
      return {
        error: true,
        msg: errorLabels.filesProvider.previewCustomColumns,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [currentUser]);

  const manageCustomColumns = useCallback(async (database, newColumnsList) => {
    try {
      const token = await getToken(currentUser);
      const opt = {
        ...await getRequestMeta(token, 'POST', 'JSON'),
        body: JSON.stringify({
          newColumns: newColumnsList,
        }),
      };

      const resFetch = await fetch(`${ip}/db/file/customcolumns/${database}`, opt);

      if (resFetch.status >= 500) {
        return {
          error: true,
          msg: errorLabels.fetchGeneric,
          raw: 'Verifique se existe algum problema com a sua conexão com a internet e tente novamente mais tarde!',
        };
      }

      const json = await resFetch.json();

      if (resFetch.status !== 200) {
        return {
          error: true,
          msg: json?.msg || errorLabels.filesProvider.manageCustomColumns,
          raw: json.error,
        };
      }

      return { error: false, msg: null, res: json.info };
    } catch (er) {
      console.log(er);
      return {
        error: true,
        msg: errorLabels.filesProvider.manageCustomColumns,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [currentUser]);

  const baseUpdate = useCallback(async (filename, database, header) => {
    try {
      const token = await getToken(currentUser);
      const opt = {
        ...await getRequestMeta(token, 'PUT', 'JSON'),
        // body: JSON.stringify({
        //   // filename: filename.replace('.metrics', ''),
        //   // database: database.replace('.metrics', ''),
        //   // header: header || 0,
        //   uploaded_file: filename,
        //   header_skip: header || 0,
        // }),
      };
      const resFetch = await fetch(`${ip}/db/file/${database}?uploaded_file=${filename}&header_skip=${header || 0}`, opt);

      if (resFetch.status === 500) {
        return {
          error: true,
          msg: errorLabels.fetchGeneric,
          raw: 'Verifique se existe algum problema com a sua conexão com a internet e tente novamente mais tarde!',
        };
      }

      const json = await resFetch.json();

      if (resFetch.status !== 200) {
        return { error: true, msg: errorLabels.filesProvider.baseUpdate, raw: json.error || json.errors };
      }
      return { error: false, msg: null, res: json.info };
    } catch (er) {
      console.log(er);
      return { error: true, msg: errorLabels.filesProvider.baseUpdate, raw: `Erro do sistema: ${er.toString()}` };
    }
  }, [currentUser]);

  const changeFilePermissions = useCallback(async (fileId, selected, removed) => {
    try {
      const token = await getToken(currentUser);
      const opt = {
        ...await getRequestMeta(token, 'PUT', 'JSON'),
        body: JSON.stringify({
          users: [
            ...selected.map((u) => ({
              uid: u.id,
              read: ['read', 'write'].includes(u.sharePermission?.value),
              write: u.sharePermission?.value === 'write',
            })),
            ...removed.map((k) => ({
              uid: k, read: false, write: false,
            })),
          ],
        }),
      };

      const resFetch = await fetch(`${ip}/db/file/permissions/${fileId}`, opt);

      if (resFetch.status >= 500) {
        return {
          error: true,
          msg: errorLabels.fetchGeneric,
          raw: 'Verifique se existe algum problema com a sua conexão com a internet e tente novamente mais tarde!',
        };
      }
      const json = await resFetch.json();

      if (resFetch.status !== 200) {
        return {
          error: true, msg: json?.msg || errorLabels.filesProvider.filePermissions, raw: json.error,
        };
      }

      // const parsed = parse(json);
      return { error: false, msg: null, res: json.info };
    } catch (er) {
      console.log(er);
      return {
        error: true, msg: errorLabels.filesProvider.filePermissions, raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [currentUser, user]);

  const transferFileOwner = useCallback(async (fileId, newOwnerId, selfRemove) => {
    try {
      const token = await getToken(currentUser);
      const opt = {
        ...await getRequestMeta(token, 'PUT', 'JSON'),
        body: JSON.stringify({
          dst_uid: newOwnerId,
          self_remove: selfRemove,
        }),
      };
      const resFetch = await fetch(`${ip}/db/file/owner/${fileId}`, opt);

      if (resFetch.status === 500) {
        return {
          error: true,
          msg: errorLabels.fetchGeneric,
          raw: 'Verifique se existe algum problema com a sua conexão com a internet e tente novamente mais tarde!',
        };
      }

      const json = await resFetch.json();

      if (resFetch.status !== 200) {
        return { error: true, msg: errorLabels.filesProvider.transferUser, raw: json.error };
      }
      return { error: false, msg: null, res: json.info };
    } catch (er) {
      console.log(er);
      return { error: true, msg: errorLabels.filesProvider.transferUser, raw: `Erro do sistema: ${er.toString()}` };
    }
  }, [currentUser]);

  const createFileProvision = useCallback(async (fileId, tag) => {
    try {
      const token = await getToken(currentUser);
      const opt = {
        ...await getRequestMeta(token, 'POST', 'JSON'),
        body: JSON.stringify({
          tag,
        }),
      };
      const resFetch = await fetch(`${ip}/db/file/${fileId}/snapshot`, opt);

      if (resFetch.status === 500) {
        return {
          error: true,
          msg: errorLabels.fetchGeneric,
          raw: 'Verifique se existe algum problema com a sua conexão com a internet e tente novamente mais tarde!',
        };
      }

      const json = await resFetch.json();

      if (resFetch.status !== 200) {
        return { error: true, msg: errorLabels.filesProvider.createFileProvision, raw: json.error };
      }
      return { error: false, msg: null, res: json.info };
    } catch (er) {
      console.log(er);
      return { error: true, msg: errorLabels.filesProvider.createFileProvision, raw: `Erro do sistema: ${er.toString()}` };
    }
  }, [currentUser]);

  const listFileProvisions = useCallback(async (fileId) => {
    try {
      const token = await getToken(currentUser);
      const opt = {
        ...await getRequestMeta(token),
      };

      const resFetch = await fetch(`${ip}/db/file/${fileId}/snapshot`, opt);

      if (resFetch.status >= 500) {
        return {
          error: true,
          msg: errorLabels.fetchGeneric,
          raw: 'Verifique se existe algum problema com a sua conexão com a internet e tente novamente mais tarde!',
        };
      }

      const json = await resFetch.json();

      if (resFetch.status !== 200) {
        return { error: true, msg: json?.msg || errorLabels.filesProvider.listFileProvisions, raw: json.error };
      }

      return { error: false, msg: null, res: json.info };
    } catch (er) {
      console.log(er);
      return { error: true, msg: errorLabels.filesProvider.listFileProvisions, raw: `Erro do sistema: ${er.toString()}` };
    }
  }, [currentUser, user]);

  const deleteFileProvision = useCallback(async (fileId, provisionId) => {
    try {
      const token = await getToken(currentUser);
      const opt = {
        ...await getRequestMeta(token, 'DELETE', 'JSON'),
        body: JSON.stringify({
          snap_id: provisionId,
        }),
      };

      const resFetch = await fetch(`${ip}/db/file/${fileId}/snapshot`, opt);

      if (resFetch.status >= 500) {
        return {
          error: true,
          msg: errorLabels.fetchGeneric,
          raw: 'Verifique se existe algum problema com a sua conexão com a internet e tente novamente mais tarde!',
        };
      }

      const json = await resFetch.json();

      if (resFetch.status !== 200) {
        return { error: true, msg: json?.msg || errorLabels.filesProvider.deleteFileProvision, raw: json.error };
      }

      return { error: false, msg: null, res: json.info };
    } catch (er) {
      console.log(er);
      return { error: true, msg: errorLabels.filesProvider.deleteFileProvision, raw: `Erro do sistema: ${er.toString()}` };
    }
  }, [currentUser, user]);

  const changeDatabasePassword = useCallback(async (fileId, password) => {
    try {
      const token = await getToken(currentUser);
      const opt = {
        ...await getRequestMeta(token, 'PUT', 'JSON'),
        body: JSON.stringify({
          password,
        }),
      };
      const resFetch = await fetch(`${ip}/db/file/changepassword/${fileId}`, opt);

      if (resFetch.status === 500) {
        return {
          error: true,
          msg: errorLabels.fetchGeneric,
          raw: 'Verifique se existe algum problema com a sua conexão com a internet e tente novamente mais tarde!',
        };
      }

      const json = await resFetch.json();

      if (resFetch.status !== 200) {
        return { error: true, msg: errorLabels.filesProvider.changeDatabasePassword, raw: json.error };
      }
      return { error: false, msg: null, res: json.info };
    } catch (er) {
      console.log(er);
      return { error: true, msg: errorLabels.filesProvider.changeDatabasePassword, raw: `Erro do sistema: ${er.toString()}` };
    }
  }, [currentUser]);

  const selfRemoval = useCallback(async (fileId, options = {}) => {
    try {
      const token = await getToken(currentUser);
      const opt = {
        ...await getRequestMeta(token, 'DELETE', 'JSON'),
      };
      const resFetch = await fetch(`${ip}/db/file/permissions/me/${fileId}`, opt);

      if (resFetch.status === 500) {
        return {
          error: true,
          msg: errorLabels.fetchGeneric,
          raw: 'Verifique se existe algum problema com a sua conexão com a internet e tente novamente mais tarde!',
        };
      }

      const json = await resFetch.json();

      if (resFetch.status !== 200) {
        return { error: true, msg: errorLabels.filesProvider.selfRemoval, raw: json.error };
      }
      return { error: false, msg: null, res: json.info };
    } catch (er) {
      console.log(er);
      return { error: true, msg: errorLabels.filesProvider.selfRemoval, raw: `Erro do sistema: ${er.toString()}` };
    }
  }, [currentUser]);

  const loadAssistantLogs = useCallback(async (fileId, cursor) => {
    try {
      let logsRaw; let logs; let idx;
      if (!cursor) {
        logsRaw = await getRefAssistantLogList(currentUser.uid, fileId)
          .where('is_chat', '==', true)
          .orderBy('asked_at', 'asc')
          .limitToLast(logsLimitPerLoad)
          .get();

        logs = logsRaw.docs.reduce((aux, l) => {
          const tmp = l.data();
          aux.push({
            id: `${l.id}-question`,
            type: 'question',
            message: tmp.question,
            timestamp: tmp.asked_at,
          });
          aux.push({
            id: `${l.id}-answer`,
            type: tmp.type,
            message: JSON.parse(tmp.answer),
            timestamp: tmp.answered_at,
          });
          return aux;
        }, []);
        idx = 0;
      } else {
        logsRaw = await getRefAssistantLogList(currentUser.uid, fileId)
          .where('is_chat', '==', true)
          .orderBy('asked_at', 'desc')
          .startAfter(cursor)
          .limit(logsLimitPerLoad)
          .get();

        logs = logsRaw.docs.reduce((aux, l) => {
          const tmp = l.data();
          aux.push({
            id: `${l.id}-answer`,
            type: tmp.type,
            message: JSON.parse(tmp.answer),
            timestamp: tmp.answered_at,
          });
          aux.push({
            id: `${l.id}-question`,
            type: 'question',
            message: tmp.question,
            timestamp: tmp.asked_at,
          });
          return aux;
        }, []).reverse();
        idx = logs.length - 1;
      }
      const newCursor = logsRaw.docs.length >= logsLimitPerLoad ? logsRaw.docs[idx] : null;

      return {
        error: false,
        msg: '',
        logs,
        cursor: newCursor,
      };
    } catch (er) {
      console.log(er);
      return {
        error: true,
        msg: firestoreErrors(er.code) || errorLabels.filesProvider.callVirtualAssistant,
        raw: `Erro do sistema: ${er.toString()}`,
        logs: [],
        cursor: null,
      };
    }
  }, [currentUser]);

  const callVirtualAssistant = useCallback(async (fileId, query, origin) => {
    try {
      const token = await getToken(currentUser);
      const opt = {
        ...await getRequestMeta(token, 'POST', 'JSON'),
        body: JSON.stringify({
          file_id: fileId,
          prompt: query,
          origin,
        }),
      };
      const resFetch = await fetch(`${ip}/db/assistant`, opt);

      if (resFetch.status === 500) {
        return {
          error: true,
          // msg: errorLabels.fetchGeneric,
          message: 'Verifique se existe algum problema com a sua conexão com a internet e tente novamente mais tarde!',
        };
      }

      const json = await resFetch.json();

      if (resFetch.status !== 200) {
        return {
          error: true,
          // msg: errorLabels.filesProvider.callVirtualAssistant,
          message: json.error,
        };
      }
      return {
        error: false, message: json.info, type: json.type, code: json.code, // data: json.data,
      };
    } catch (er) {
      console.log(er);
      return { error: true, message: errorLabels.filesProvider.callVirtual, raw: `Erro do sistema: ${er.toString()}` };
    }
  }, [currentUser]);

  const historySchedule = useCallback(async (fileId, cronCode) => {
    try {
      const token = await getToken(currentUser);
      const opt = {
        ...await getRequestMeta(token, 'POST', 'JSON'),
        body: JSON.stringify({
          provision_cron: cronCode,
        }),
      };
      const resFetch = await fetch(`${ip}/db/file/${fileId}/snapshot/schedule`, opt);

      if (resFetch.status === 500) {
        return {
          error: true,
          msg: errorLabels.fetchGeneric,
          raw: 'Verifique se existe algum problema com a sua conexão com a internet e tente novamente mais tarde!',
        };
      }

      const json = await resFetch.json();

      if (resFetch.status !== 200) {
        return {
          error: true,
          msg: errorLabels.filesProvider.historySchedule,
          raw: json.error,
        };
      }
      return { error: false, msg: null, res: json.info };
    } catch (er) {
      console.log(er);
      return { error: true, message: errorLabels.filesProvider.historySchedule, raw: `Erro do sistema: ${er.toString()}` };
    }
  }, [currentUser]);

  const middleware = useCallback(async (action) => {
    switch (action.type) {
      case 'init': {
        setLoading(true);
        const [compRes, filesRes, instanceFilesRes] = await Promise.all([
          getCurrentCompanyStorage(),
          getFilesList(),
          getInstanceFilesList(),
        ]);
        if (compRes.error || filesRes.error || instanceFilesRes.error) {
          setLoading(false);
        } else {
          const { size, dashboards } = compRes.raw;
          dispatch({
            type: 'init',
            files: filesRes.files,
            instanceFiles: instanceFilesRes.files,
            size,
            dashboards,
          });
        }
        return {
          ...compRes,
          ...filesRes,
          error: compRes.error || filesRes.error || instanceFilesRes.error,
        };
      }

      case 'getFile': {
        setLoading(true);
        const { fileId } = action;
        const getRes = await getFile(fileId);
        setLoading(false);
        return getRes;
      }

      case 'getFilesList': {
        setLoading(true);
        const getRes = await getFilesList();
        if (getRes.error) {
          setLoading(false);
        } else {
          dispatch({ type: 'setFiles', files: getRes.files });
        }
        return getRes;
      }

      case 'getFilesMetadataList': {
        setLoading(true);
        const { filesName, filesOwner } = action;
        const getRes = await getFilesMetadataList(filesName, filesOwner);
        setLoading(false);
        return getRes;
      }

      case 'getFileSample': {
        setLoading(true);
        const { database, schema, rows, page } = action;
        const getRes = await getFileSample(database, schema, rows, page);
        setLoading(false);
        return getRes;
      }

      case 'getCurrentCompanyStorage': {
        setLoading(true);
        const getRes = await getCurrentCompanyStorage();
        if (getRes.error) {
          setLoading(false);
        } else {
          const { size, dashboards } = getRes.raw;
          dispatch({ type: 'setCompanyStorage', size, dashboards });
        }
        return getRes;
      }

      case 'optimize': {
        setLoading(true, waitMessagesUpload);
        const postRes = await optimize(action.data);
        setLoading(false);
        return postRes;
      }

      case 'convertFileToMail': {
        setLoading(true);
        const res = await convertFileToMail(action.fileName, action.fileId);
        setLoading(false);
        return res;
      }

      case 'convertFileApiGed': {
        setLoading(true);
        const res = await convertFileApiGed(action.fileName, action.fileId);
        setLoading(false);
        return res;
      }

      case 'downloadFile': {
        setLoading(true, waitMessagesDonwload);
        const downloadRes = await downloadFile(action.file);
        setLoading(false);
        return downloadRes;
      }

      case 'deleteFile': {
        setLoading(true);
        const delRes = await deleteFile(action.item);
        if (delRes.error) {
          setLoading(false);
        } else {
          const getRes = await getCurrentCompanyStorage();
          if (getRes.error) {
            setLoading(false);
          } else {
            const { size } = getRes.raw;
            dispatch({ type: 'deleteFile', deleted: delRes.deleted, size });
          }
        }
        return delRes;
      }

      case 'deleteGlobalFile': {
        setLoading(true);
        const delRes = await deleteGlobalFile(action.item);
        if (delRes.error) {
          setLoading(false);
        } else {
          const getRes = await getCurrentCompanyStorage();
          if (getRes.error) {
            setLoading(false);
          } else {
            const { size } = getRes.raw;
            dispatch({ type: 'deleteFile', deleted: delRes.deleted, size });
          }
        }
        return delRes;
      }

      case 'uploadFile': {
        setLoading(true);
        const { file, overwrite } = action;
        const res = await uploadFile(file, overwrite);
        setLoading(false);
        return res;
      }

      case 'uploadFileByLink': {
        setLoading(true);
        const { url, filename, headerSkip, connector } = action;
        const res = await uploadFileByLink(url, filename, headerSkip, connector);
        setLoading(false);
        return res;
      }

      case 'uploadBySqlDB': {
        setLoading(true);
        const { params } = action;
        const res = await uploadBySqlDB(params);
        setLoading(false);
        return res;
      }

      case 'testSqlConnection': {
        setLoading(true);
        const { params } = action;
        const res = await testSqlConnection(params);
        setLoading(false);
        return res;
      }

      case 'uploadBennerReport': {
        setLoading(true);
        const {
          filename, url, username, password, headerSkip,
        } = action;
        const res = await uploadBennerReport(filename, url, username, password, headerSkip);
        setLoading(false);
        return res;
      }

      case 'reopen': {
        setLoading(true);
        const { filename, decimal, header } = action;
        const res = await reopen(filename, decimal, header);
        setLoading(false);
        return res;
      }

      case 'getUnique': {
        setLoading(true);
        const { database, column, selector } = action;
        const res = await getUnique(database, column, selector);
        setLoading(false);
        return res;
      }

      case 'getColumns': {
        setLoading(true);
        const res = await getColumns(action.database);
        setLoading(false);
        return res;
      }

      case 'editColumns': {
        setLoading(true);
        const res = await editColumns(action.database, action.columnTypes);
        setLoading(false);
        return res;
      }

      case 'renameColumns': {
        setLoading(true);
        const res = await renameColumns(action.database, action.aliasMap);
        setLoading(false);
        return res;
      }

      case 'previewCustomColumns': {
        setLoading(true);
        const res = await previewCustomColumns(action.database, action.newColumnsList);
        setLoading(false);
        return res;
      }

      case 'manageCustomColumns': {
        setLoading(true);
        const res = await manageCustomColumns(action.database, action.newColumnsList);
        setLoading(false);
        return res;
      }

      case 'baseUpdate': {
        setLoading(true, filesViewerWaitMessagesReUpload);
        const { filename, database, header } = action;
        const res = await baseUpdate(filename, database, header);
        setLoading(false);
        return res;
      }

      case 'changeFilePermissions': {
        setLoading(true);
        const { fileId, selected, removed } = action;
        const res = await changeFilePermissions(fileId, selected, removed);
        setLoading(false);
        return res;
      }

      case 'transferFileOwner': {
        setLoading(true);
        const { fileId, newOwnerId, selfRemove } = action;
        const res = await transferFileOwner(fileId, newOwnerId, selfRemove);
        setLoading(false);
        return res;
      }

      case 'createFileProvision': {
        setLoading(true);
        const { fileId, tag } = action;
        const res = await createFileProvision(fileId, tag);
        setLoading(false);
        return res;
      }

      case 'listFileProvisions': {
        setLoading(true);
        const { fileId } = action;
        const res = await listFileProvisions(fileId);
        setLoading(false);
        return res;
      }

      case 'deleteFileProvision': {
        setLoading(true);
        const { fileId, provisionId } = action;
        const res = await deleteFileProvision(fileId, provisionId);
        setLoading(false);
        return res;
      }

      case 'changeDatabasePassword': {
        setLoading(true);
        const { fileId, password } = action;
        const res = await changeDatabasePassword(fileId, password);
        setLoading(false);
        return res;
      }

      case 'selfRemoval': {
        setLoading(true);
        const { fileId, options } = action;
        const res = await selfRemoval(fileId, options);
        setLoading(false);
        return res;
      }

      case 'loadAssistantLogs': {
        const { fileId, cursor } = action;
        const res = await loadAssistantLogs(fileId, cursor);
        return res;
      }

      case 'callVirtualAssistant': {
        // setLoading(true);
        const { fileId, query, origin } = action;
        const res = await callVirtualAssistant(fileId, query, origin);
        // setLoading(false);
        return res;
      }

      case 'historySchedule': {
        setLoading(true);
        const { fileId, cronCode } = action;
        const res = await historySchedule(fileId, cronCode);
        setLoading(false);
        return res;
      }

      default: {
        dispatch(action);
        return {
          error: false,
          msg: '',
          raw: '',
          default: true,
        };
      }
    }
  }, [
    getCurrentCompanyStorage,
    deleteFile,
    deleteGlobalFile,
    getFile,
    getFilesList,
    getFilesMetadataList,
    getFileSample,
    downloadFile,
    uploadFile,
    convertFileToMail,
    convertFileApiGed,
    optimize,
    uploadFileByLink,
    uploadBySqlDB,
    testSqlConnection,
    uploadBennerReport,
    baseUpdate,
    changeFilePermissions,
    transferFileOwner,
    createFileProvision,
    listFileProvisions,
    deleteFileProvision,
    selfRemoval,
    changeDatabasePassword,
    getUnique,
    getColumns,
    editColumns,
    renameColumns,
    previewCustomColumns,
    manageCustomColumns,
    reopen,
    loadAssistantLogs,
    callVirtualAssistant,
    historySchedule,
  ]);

  const filesAPI = useMemo(() => ({
    init: async () => middleware({ type: 'init' }),
    getFile: async (f) => middleware({ type: 'getFile', fileId: f }),
    getFilesList: async () => middleware({ type: 'getFilesList' }),
    getFilesMetadataList: async (filesName, filesOwner) => middleware({ type: 'getFilesMetadataList', filesName, filesOwner }),
    getCurrentCompanyStorage: async () => middleware({ type: 'getCurrentCompanyStorage' }),
    optimize: async (data) => middleware({ type: 'optimize', data }),
    convertFileToMail: async (fileName, fileId) => middleware({ type: 'convertFileToMail', fileName, fileId }),
    convertFileApiGed: async (fileName, fileId) => middleware({ type: 'convertFileApiGed', fileName, fileId }),
    downloadFile: async (file) => middleware({ type: 'downloadFile', file }),
    getFileSample: async (database, schema, rows, page) => middleware({
      type: 'getFileSample', database, schema, rows, page,
    }),
    deleteFile: async (item) => middleware({ type: 'deleteFile', item }),
    deleteGlobalFile: async (item) => middleware({ type: 'deleteGlobalFile', item }),
    uploadFile: async (file, overwrite) => middleware({
      type: 'uploadFile', file, overwrite,
    }),
    uploadFileByLink: async (url, filename, headerSkip, connector) => middleware({
      type: 'uploadFileByLink', url, filename, headerSkip, connector,
    }),
    uploadBySqlDB: async (params) => middleware({
      type: 'uploadBySqlDB', params,
    }),
    testSqlConnection: async (params) => middleware({
      type: 'testSqlConnection', params,
    }),
    uploadBennerReport: async (filename, url, username, password, headerSkip) => middleware({
      type: 'uploadBennerReport', filename, url, username, password, headerSkip,
    }),
    reopen: async (filename, decimal, header) => middleware({
      type: 'reopen', filename, decimal, header,
    }),
    getUnique: async (database, column, selector) => middleware({
      type: 'getUnique', database, column, selector,
    }),
    getColumns: async (database) => middleware({ type: 'getColumns', database }),
    editColumns: async (database, columnTypes) => middleware({ type: 'editColumns', database, columnTypes }),
    renameColumns: async (database, aliasMap) => middleware({ type: 'renameColumns', database, aliasMap }),
    previewCustomColumns: async (database, newColumnsList) => middleware({
      type: 'previewCustomColumns', database, newColumnsList,
    }),
    manageCustomColumns: async (database, newColumnsList) => middleware({
      type: 'manageCustomColumns', database, newColumnsList,
    }),
    baseUpdate: async (filename, database, header) => middleware({
      type: 'baseUpdate', filename, database, header,
    }),
    changeFilePermissions: async (fileId, selected, removed) => middleware({
      type: 'changeFilePermissions', fileId, selected, removed,
    }),
    transferFileOwner: async (fileId, newOwnerId, selfRemove) => middleware({
      type: 'transferFileOwner', fileId, newOwnerId, selfRemove,
    }),
    createFileProvision: async (fileId, tag) => middleware({
      type: 'createFileProvision', fileId, tag,
    }),
    listFileProvisions: async (fileId) => middleware({
      type: 'listFileProvisions', fileId,
    }),
    deleteFileProvision: async (fileId, provisionId) => middleware({
      type: 'deleteFileProvision', fileId, provisionId,
    }),
    changeDatabasePassword: async (fileId, password) => middleware({
      type: 'changeDatabasePassword', fileId, password,
    }),
    selfRemoval: async (fileId, options) => middleware({
      type: 'selfRemoval', fileId, options,
    }),
    loadAssistantLogs: async (fileId, cursor) => middleware({
      type: 'loadAssistantLogs', fileId, cursor,
    }),
    callVirtualAssistant: async (fileId, query, origin) => middleware({
      type: 'callVirtualAssistant', fileId, query, origin,
    }),
    historySchedule: async (fileId, cronCode) => middleware({
      type: 'historySchedule', fileId, cronCode,
    }),
  }), [middleware]);

  return (
    <FilesContext.Provider value={{ state, filesAPI }}>
      {providerChild}
    </FilesContext.Provider>
  );
};

FilesProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]).isRequired,
};

export default FilesProvider;
