import styled from 'styled-components';
import { gap } from '../../../../../juristec-ui/styles/theme';

export const MainContainer = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 2px 1fr;
  width: 100%;
  height: calc(100vh - 500px);
  max-width: 750px;
`;

export const MainExtra = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(5, 1fr);
  align-content: flex-start;
  width: 100%;
  grid-template-rows: repeat(2, min-content) 1fr;
`;

export const SideBySideContainer = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  padding: 0 .5rem;
  box-sizing: border-box;
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 .5rem;
  box-sizing: border-box;
  ${gap('10px', 'column')}
  width: 100%;
  margin-bottom: 5px;
  min-width: 300px;
  overflow: hidden auto;
`;

export const InputWrapper = styled.div`
  width: 100%;
  min-height: 55px;
  max-height: 55px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  & .errorMessage {
    position: absolute;
  }
  & .select_wrapper {
    margin-top: 17.6px;
  }
  & span.custom-label {
    font-size:0.85rem;
    color:${({ theme }) => theme.inputLabel};
    font-weight: bold;
    width: 100%;
  }
  &.inline {
    min-height: 35px;
  }
  &.toggle {
    min-height: 35px;
    max-height: 35px;
  }
  &.bottom {
    margin-top: auto;
  }
`;

export const InputWrapperExtra = styled(InputWrapper)`
  box-sizing: border-box;
  max-height: 100%;
  & .textAreaContainer {
    width: 100%;
    box-sizing: border-box;
  }
`;

export const CategoryLabel = styled.label`
  width: 100%;
  text-align: center;
  font-weight: bold;
  font-size: 12px;
  color: gray;
  margin-bottom: -5px;
`;

export const VerticalSeparator = styled.div`
  height: 100%;
  border: solid 1px #ddd;
  margin: auto;
`;

export const EmailContainer = styled.div`
  background-color: ${({ theme }) => theme.containerOdd};
  border-radius: 1rem;
  padding: 10px;
  height: calc(100vh - 500px);
  overflow: auto;
`;

export const EmailList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  ${gap('10px', 'row')}
  & li {
    display: flex;
    flex-direction: row;
    align-items: center;
    ${gap('10px', 'row')}
    margin: 5px 0;
    width: 100%;
    & > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      ${gap('5px', 'row')}
      border: 2px solid ${({ theme }) => theme.secondary};
      color: ${({ theme }) => theme.secondary};
      background-color: ${({ theme }) => theme.secondary}20;
      border-radius: 5px;
      font-weight: bold;
      padding: 4px;
      width: min-content;
      white-space: nowrap;
      height: min-content;
      width: 100%;
      & span {
        overflow: hidden;
        max-width: 245px;
        font-size: 14px;
      }
      & button, button:hover {
        padding: 0;
        box-shadow: none;
        & svg {
          height: 12px;
          width: 12px;
        }
      }
    }
  }
`;
