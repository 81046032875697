import React, {
  useContext, useState, useMemo, useEffect, useCallback, useRef,
} from 'react';
import { useTheme } from 'styled-components';

// contexts
import { AlertContext } from '../../context/AlertProvider';
import { ModalContext } from '../../context/ModalProvider';
import { FilesContext } from '../../context/FilesProvider';
import { AuthContext } from '../../context/AuthProvider';
import { UsersContext } from '../../context/UsersProvider';
import { TourContext } from '../../context/TourProvider';
import { TemplateContext } from '../../context/TemplateProvider';
import { NotificationsContext } from '../../context/NotificationProvider';
// hooks
import useGridDashboard from '../../hooks/useGridDashboard';
import useGridKpi from '../../hooks/useGridKpi';

// functions / utils
// import { getRefDashboardItem } from '../../utils/firestore';
// import {
//   getFormatFromMetricsFile, toMb,
// } from '../../utils/functions/verifyFiles';
import { shareDashOptions } from '../../options';

// components
import Loader from '../../juristec-ui/core/Loader';
import GridDashboardToolbar from '../../components/Toolbars/GridDashboardToolbar';
import ShareList from '../../juristec-ui/core/ShareList';
import NewDashboard from '../../components/Modals/NewDashboard';
import DashboardCore from '../../components/DashboardCore';
import ConfirmCopyToMe from '../../components/Modals/ConfirmCopyToMe';
// import OverrideFilesName from '../../components/Modals/OverrideFilesName';
import CopyDashboard from '../../components/ProgressiveModals/CopyDashboard';
import ImportDashboard from '../../components/Modals/ImportDashboard';

import { verifyFileImgType, verifyFileSize } from '../../juristec-ui/utils/validators/fileValidators';

export default function DashboardPage() {
  const { currentUser, userCompany, user } = useContext(AuthContext);
  const { setAlertConfig } = useContext(AlertContext);
  const { setModalConfig, closeModal } = useContext(ModalContext);
  const { state: usersState } = useContext(UsersContext);
  const { templateAPI } = useContext(TemplateContext);
  const { state: sysMsgState, notificationsAPI } = useContext(NotificationsContext);
  const {
    tourOpen, nextStep, refreshTour, remakeTourList,
  } = useContext(TourContext);
  // const { setModalConfig, closeModal } = useContext(ModalContext);

  const { state: filesState, filesAPI } = useContext(FilesContext);
  const theme = useTheme();

  const isMounted = useRef(false);

  useEffect(() => {
    if (!filesState.started && !filesState.isLoading && !isMounted.current) {
      (async () => {
        await filesAPI.init();
      })();
    }
  }, [filesState.started, filesAPI, filesState.isLoading]);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const [{
    isLoading,
    dashboards,
    sharedDashboards,
    dashboardLayout,
    groups,
  }, gridAPI] = useGridDashboard(currentUser, user);
  const [gridKpiState, gridKpiAPI] = useGridKpi(currentUser.id, '', currentUser, user);

  const [canEditLayout, setEditLayout] = useState(null);
  const [_currentBreakpoint, _setCurrentBreakPoint] = useState('lg');
  const [_layout, _setLayout] = useState({
    xxs: [],
    xs: [],
    sm: [],
    md: [],
    lg: [],
  });

  // filters vars
  const [filterText, setFilterText] = useState('');
  const [selectedGeneral, setSelectedGeneral] = useState('all');
  const [selectedGroups, setSelectedGroups] = useState({ });
  const [filterAgain, setFilterAgain] = useState(false);
  const [openTrashCan, setOpenTrashCan] = useState(false);
  const [countTrashCan, setCountTrashCan] = useState(0);

  useEffect(() => {
    if (dashboardLayout) _setLayout(dashboardLayout);
  }, [dashboardLayout]);

  const filterList = useCallback((listIn, searchTextIn) => {
    let countTrash = 0;
    const filtered = listIn.filter((dash) => {
      if (openTrashCan) {
        if (dash.isDeleted && dash.owner === user.id) {
          countTrash += 1;
          return true;
        } return false;
      }
      if (dash.isDeleted) {
        if (dash.owner === user.id) countTrash += 1;
        return false;
      }

      switch (selectedGeneral) {
        case 'shared':
          if (!dash.shared) return false;
          break;
        case 'my':
          if (dash.shared) return false;
          break;
        case 'bookmarked':
          if (!dash.bookMarked) return false;
          break;
        default:
          break;
      }
      // console.log('if ', Object.keys(selectedGroups));
      if (Object.keys(selectedGroups).length) {
        // console.log({ v: dash.group.value, teste: selectedGroups[dash.group.value] });
        if (!selectedGroups[dash.group?.value || '']) return false;
      }

      if (!searchTextIn) return true;

      const search = searchTextIn.toLowerCase().trim();
      return (
        dash.name.toLowerCase().includes(search)
        || dash.displayName.toLowerCase().includes(search)
        || dash.group?.label?.toLowerCase().includes(search)
      );
    });
    setCountTrashCan(countTrash);
    return filtered;
  }, [filterAgain]);

  // testar isso. dps corrigir css. dps arrumar o dashboradGrid nomes das var
  const dashWithUser = useMemo(() => {
    const { users } = usersState;
    const allDashs = dashboards.concat(sharedDashboards);
    return allDashs.map((d) => {
      const owner = users.reduce((acc, cur) => {
        let aux = acc;
        if (cur.id === d.owner || cur.uid === d.owner) aux = cur;
        return aux;
      }, {});

      return {
        ...owner,
        ownerName: owner.name,
        ...d,
      };
    });
  }, [dashboards, sharedDashboards, usersState]);

  const filteredDash = useMemo(() => filterList(dashWithUser, filterText),
    [filterText, dashWithUser, filterAgain]);

  // handlers
  const saveLayout = async () => {
    const result = await gridAPI.saveLayout(_layout);
    if (result.error) {
      setAlertConfig({
        type: 'error',
        text: result.msg,
        child: `${result.raw}`,
      });
    }
    setEditLayout(false);
  };

  const handleGeneral = (a) => {
    setFilterAgain((p) => !p);
    return setSelectedGeneral(a || 'all');
  };

  const handleGroup = (a) => {
    setFilterAgain((p) => !p);
    return setSelectedGroups(a);
  };

  const handleTrashCan = (a) => {
    setFilterAgain((p) => !p);
    return setOpenTrashCan(a);
  };

  const verifyMemoryAndDashboards = () => {
    if (filesState.size + 10 > userCompany?.volumeData) {
      setAlertConfig({
        type: 'error',
        text: 'Não há memória suficiente!',
        child: 'A sua instância já atingiu o limite máximo de memória disponível!',
      });
      return false;
    }
    if (filesState.dashboards >= userCompany?.dashboards) {
      setAlertConfig({
        type: 'error',
        text: 'Número máximo de dashboards atingido!',
        child: `O número máximo de dashboards do seu plano (${userCompany?.dashboards} dashboards) foi atingido!`,
      });
      return false;
    }
    return true;
  };
  // o tratamento de erro  tá no newDashboard.jsx
  const handleCreateModal = async () => {
    if (!verifyMemoryAndDashboards()) return;
    if (tourOpen) nextStep();

    const verifyAdd = async (newDash) => {
      const result = await gridAPI.createDashboard(newDash, dashboardLayout);
      if (result.error) {
        setAlertConfig({
          type: 'error',
          // text: result.msg,
          text: 'Tivemos um problema para criar o seu dashboard. Tente novamente, por favor!',
          child: `${result.raw}`,
        });
        return;
      }
      setAlertConfig({
        type: 'success',
        text: 'O dashboard foi criado com sucesso!',
      });
      closeModal();
      await filesAPI.getCurrentCompanyStorage();
    };

    // Function to check if the image satisfies the specified conditions
    const checkFileIsImg = (file) => {
      const errorMsg = verifyFileImgType(file) || verifyFileSize(file, 1000000);
      if (errorMsg.length > 0) {
        setAlertConfig({ type: 'error', text: errorMsg });
        return false;
      }
      return true;
    };

    setModalConfig({
      title: 'Novo Dashboard',
      className: 'newDash_modal',
      children: (
        <NewDashboard
          tourContext={{
            tourOpen, nextStep, remakeTourList, refreshTour,
          }}
          close={closeModal}
          createNewDashboard={verifyAdd}
          groupNameList={Object.keys(groups).map((g) => ({ label: g, value: g, id: g }))}
          currentUser={user}
          checkImage={checkFileIsImg}
        />
      ),
    });
  };

  const handleEditModal = (dashboard) => {
    const verifyEdit = async (dashId, sharedWith, newInfos) => {
      const result = await gridAPI.editDashboard(dashId, sharedWith, newInfos);
      if (result.error) {
        setAlertConfig({
          type: 'error',
          text: result.msg,
          child: `${result.raw}`,
        });
        return;
      }

      closeModal();
    };

    // Function to check if the image satisfies the specified conditions
    const checkFileIsImg = (file) => {
      const errorMsg = verifyFileImgType(file) || verifyFileSize(file, 1000000);
      if (errorMsg.length > 0) {
        setAlertConfig({ type: 'error', text: errorMsg });
        return false;
      }
      return true;
    };

    setModalConfig({
      title: 'Editar Dashboard',
      children: (
        <NewDashboard
          toEditDashboard={dashboard}
          close={closeModal}
          currentUser={user}
          editDashboard={verifyEdit}
          groupNameList={Object.keys(groups).map((g) => ({ label: g, value: g, id: g }))}
          checkImage={checkFileIsImg}
        />
      ),
    });
  };
  //

  const handleRemoveDash = (dash) => {
    const verifyRemove = async () => {
      const result = await gridAPI.removeDashboard(dash);
      if (result.error) {
        setAlertConfig({
          type: 'error',
          text: result.msg,
          child: `${result.raw}`,
        });
        return;
      }
      setAlertConfig({
        type: 'success',
        text: 'Dashboard removido com sucesso!',
      });
      await filesAPI.getCurrentCompanyStorage();
    };

    setModalConfig({
      title: 'Apagar Dashboard',
      yesFunc: verifyRemove,
      yesLabel: 'Apagar',
      notFunc: closeModal,
      notLabel: 'Cancelar',
      children: (
        <div
          style={{
            padding: '10px 10px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <span
            style={{
              fontSize: 16,
            }}
          >
            Tem certeza que deseja apagar o dashboard
            <strong>
              {` ${dash.name} `}
            </strong>
            definitivamente?
          </span>
        </div>
      ),
    });
  };

  const handleClearTrashCan = (dashs) => {
    const verifyClear = async () => {
      let error = false; // Boolean para determinar se teve erro na deleção
      const promises = [];
      dashs.map((dash) => promises.push(gridAPI.removeDashboard(dash)));
      const resPromises = await Promise.all(promises);
      resPromises.forEach((res) => {
        if (res.error) {
          error = true;
        }
      });
      if (error) {
        setAlertConfig({
          type: 'error',
          text: 'Houve um erro ao tentar esvaziar a lixeira!',
          child: 'Um ou mais dashboards não foram apagados corretamente. Por favor, tente novamente!',
        });
        return;
      }
      setAlertConfig({
        type: 'success',
        text: 'Lixeira esvaziada com sucesso!',
      });
      await filesAPI.getCurrentCompanyStorage();
    };

    setModalConfig({
      title: 'Esvaziar Lixeira',
      yesFunc: verifyClear,
      yesLabel: 'Apagar',
      notFunc: closeModal,
      notLabel: 'Cancelar',
      children: (
        <div
          style={{
            padding: '10px 10px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <span
            style={{
              fontSize: 16,
            }}
          >
            Tem certeza que deseja esvaziar a lixeira?
            Os dashboards excluídos não poderão ser recuperados!
          </span>
        </div>
      ),
    });
  };

  const handleToggleTrash = (dash) => {
    const verifyTrash = async () => {
      const result = await gridAPI.toggleTrashDashboard(dash.id, dash.sharedWith, !dash.isDeleted);
      if (result.error) {
        setAlertConfig({
          type: 'error',
          text: result.msg,
          child: `${result.raw}`,
        });
        return;
      }
      closeModal();
      setAlertConfig({
        type: 'success',
        text: 'Dashboard enviado para a lixeira!',
      });
    };

    setModalConfig({
      title: dash.isDeleted ? 'Restaurar Dashboard' : 'Mover Dashboard para Lixeira',
      yesFunc: verifyTrash,
      yesLabel: 'Sim',
      notFunc: closeModal,
      notLabel: 'Cancelar',
      children: (
        <div
          style={{
            padding: '10px 10px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <span
            style={{
              fontSize: 16,
            }}
          >
            Tem certeza que deseja
            {dash.isDeleted ? ' restaurar ' : ' mover '}
            o dashboard
            <strong>{` ${dash.name} `}</strong>
            {dash.isDeleted ? '?' : 'para a lixeira?'}
          </span>
        </div>
      ),
    });
  };

  const handleShareDash = (dash) => {
    if (tourOpen) nextStep();
    const genSuccessMsg = (sharedUsers = [], missing) => {
      let child = '';
      let type = 'success';
      if (sharedUsers.length > 0) {
        child = `O dashboard ${dash.name} está compartilhado com ${sharedUsers.map((u) => (
          `${u.name} (${shareDashOptions.get(u.sharePermission?.value)})`)).join(', ')}.`;
        if (missing?.deleted?.length > 0 || missing?.noPermission?.length > 0) {
          type = 'warning';
          child += " Porém alguns KPI's dependem de";
          if (missing.deleted.length > 0) {
            child += ` ${missing.deleted.length} arquivos não encontrados${missing.noPermission.length > 0 ? ' e' : '.'}`;
          }
          if (missing.noPermission.length > 0) child += ` ${missing.deleted.length} arquivos que não são seus.`;
        }
        child = (
          <>
            <div>
              {child}
            </div>
            <div>
              <span style={{ color: theme.warning, fontWeight: 'bold' }}>
                Atenção:
              </span>
              {' '}
              <span>
                Filtros globais e variáveis de controle podem não estar disponíveis para os usuários
                compartilhados caso você não seja o proprietário dos arquivos dependentes.
              </span>
            </div>
          </>
        );
      } else {
        child = `O dashboard ${dash.name} não está compartilhado com outros usuários.`;
      }
      return {
        text: 'Opções de compartilhamento atualizadas!',
        type,
        child,
      };
    };

    const verifyShare = async (selected, removed) => {
      // console.log(selected);
      const result = await gridAPI.shareDashboard(dash, usersState.users, selected, removed, filesState.files);
      if (result.error) {
        setAlertConfig({
          type: 'error',
          text: result.msg,
          child: `${result.raw}`,
        });
        return;
      }
      setAlertConfig(genSuccessMsg(selected, result.missing));
      closeModal();
    };

    const genPublicUrl = async (revoke) => {
      const res = await gridAPI.genPublicKpis(dash, revoke);
      if (res.error) {
        setAlertConfig({
          type: 'error',
          text: `Não foi possível ${revoke ? 'desativar o link público' : 'gerar cópia pública'}. Tente novamente mais tarde`,
        });
      } else {
        setAlertConfig({
          type: 'success',
          text: `Link público ${revoke ? 'desativado' : 'atualizado'} com sucesso.`,
          child: (
            <>
              <div>
                <span style={{ color: theme.warning, fontWeight: 'bold' }}>
                  Atenção:
                </span>
                {' '}
                <span>
                  Alterações realizadas no dashboard não são atualizadas automaticamente no
                  link público. Para que as alterações tenham efeito, é necessário que o link
                  seja atualizado manualmente.
                </span>
              </div>
            </>
          ),
        });
      }
      return res;
    };

    setModalConfig({
      title: 'Compartilhar Dashboard',
      className: 'shareModal',
      children: (
        <ShareList
          users={usersState.users}
          owner={user.id}
          publicLink={userCompany.publicLink}
          sharedWith={dash?.sharedWith || {}}
          submit={verifyShare}
          close={closeModal}
          publicUrl={dash.publicUrl}
          publicTimestamp={dash.publicTimestamp}
          genPublicUrl={genPublicUrl}
          tourContext={{ tourOpen, nextStep, refreshTour }}
          permissionsOpts={shareDashOptions.getOptions()}
        />
      ),
    });
  };

  const handleSelfRemove = (dash) => {
    const selfRemoval = async () => {
      const result = await gridAPI.selfRemoval(dash);
      if (result.error) {
        setAlertConfig({
          type: 'error',
          text: result.msg,
          child: result.raw,
        });
      } else {
        setAlertConfig({
          type: 'success',
          text: 'Você foi removido do compartilhamento!',
        });
      }
      filesAPI.init();
    };

    setAlertConfig({
      type: 'warning',
      text: 'Tem certeza que deseja sair do compartilhamento?',
      child: (
        <span>
          Você não poderá mais visualizar o dashboard
          {' '}
          <strong style={{ fontStyle: 'italic' }}>
            {dash.name}
          </strong>
        </span>
      ),
      withoutConfirm: true, // tirar o ok do alerta
      withFunction: true, // colocar btn cancelar e confirmar
      confirmFunction: selfRemoval,
    });
  };

  const handleBookMarkDash = async (dash) => {
    const result = await gridAPI.bookMarkDashboard(dash.id, dash.bookMarked, dash.shared);
    if (result.error) {
      setAlertConfig({
        text: result.msg,
        type: 'error',
      });
    }
  };

  /* ========================================================= */

  const handleCopyDashboard = async (dash) => {
    if (!verifyMemoryAndDashboards()) return;
    const handle = async () => {
      gridAPI.setLoading(true);
      const dashId = dash.id.split('_')[1];

      const result = await gridAPI.cloneDashboard(dashId, dash.owner);
      if (result.error) {
        setAlertConfig({
          type: 'error',
          text: result.msg,
          child: result.raw,
        });
        closeModal();
        return;
      }
      if (result.databases?.length) {
        let deleted = 0;
        const notAllowed = [];
        result.databases.forEach((db) => {
          const used = filesState.instanceFiles.find((f) => f.file_id === db);
          if (!used) {
            deleted += 1;
          } else {
            const shared = filesState.files.find((f) => f.file_id === db);
            if (!shared) {
              notAllowed.push({
                ...used,
                ownerName: usersState.users.find((u) => u.id === used.owner)?.name,
              });
            }
          }
        });
        if (deleted > 0 || notAllowed.length > 0) {
          setAlertConfig({
            type: 'warning',
            text: 'Dashboard clonado com sucesso!',
            child: (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span>
                  <b style={{ color: theme.error }}>Atenção: </b>
                  você possui
                </span>
                {notAllowed.length > 0 && (
                  <>
                    <span>
                      <b>
                        {notAllowed.length}
                      </b>
                      {' '}
                      {notAllowed.length > 1 ? 'arquivos ' : 'arquivo '}
                      sem permissão de uso:
                    </span>
                    <ul style={{ margin: '5px 0' }}>
                      {notAllowed.map((na) => (
                        <li key={na.file_id}>
                          {'Arquivo: '}
                          <b>
                            {na.filename}
                          </b>
                          {' - proprietário: '}
                          <b>
                            {na.ownerName}
                          </b>
                        </li>
                      ))}
                    </ul>
                    <span>
                      Para que estes arquivos sejam atualizados, solicite o compartilhamento
                      {' '}
                      {notAllowed.length > 1 ? 'dos arquivos.' : 'do arquivo.'}
                    </span>
                  </>
                )}
                {deleted > 0 && (
                  <span>
                    <b>
                      {deleted}
                    </b>
                    {' '}
                    {deleted > 1 ? 'arquivos não foram encontrados.' : 'arquivo não foi encontrado.'}
                  </span>
                )}
              </div>
            ),
          });
          closeModal();
          await filesAPI.getCurrentCompanyStorage();
          return;
        }
      }
      setAlertConfig({
        type: 'success',
        text: 'Dashboard clonado com sucesso!',
      });
      closeModal();
      await filesAPI.getCurrentCompanyStorage();
    };

    const owner = usersState.users.filter((u) => u.id === dash.owner).reduce((_, cur) => cur, {});
    // console.log(owner);
    setModalConfig({
      title: 'Clonar Dashboard',
      children: <ConfirmCopyToMe dashOwner={owner.name} dashName={dash.name} />,
      yesLabel: 'Sim',
      yesFunc: handle,
      notLabel: 'Não',
      notFunc: () => {},
    });
  };

  /* ========================================================= */

  const handleUseAsTemplate = async (dash) => {
    if (!verifyMemoryAndDashboards()) return;

    const {
      error, msg, reqVariablesDescription,
    } = await gridAPI.genRequiredFromDashboard(dash.id, dash.owner);
    if (error) {
      setAlertConfig({
        type: 'error',
        text: msg,
      });
      return;
    }
    if (!Object.keys(reqVariablesDescription)?.length) {
      setAlertConfig({
        type: 'error',
        text: 'Não é possível copiar um dashboard sem Kpis',
      });
      return;
    }

    const handleFileChange = async (database) => {
      gridAPI.setLoading(true);
      const { error: er, res, msg: message } = await filesAPI.getFileSample(database, false);
      if (er) {
        setAlertConfig({
          type: 'error',
          text: error.generic,
          child: message,
        });
        gridAPI.setLoading(false);
        return [];
      }
      const tempArray = res.columns.filter((val) => val !== 'index')
        .map((val, i) => ({
          id: val + i,
          label: val,
          value: val,
          type: res.types[val],
        }));
      gridAPI.setLoading(false);
      return tempArray;
    };

    const handleColumnChange = async (database, column, format) => {
      // Recebendo valores unicos
      const { error: columnError, msg: columnMsg, res: columnRes } = await filesAPI.getUnique(
        database,
        column,
        format || '',
      );
      if (columnError) {
        setAlertConfig({ type: 'error', text: columnMsg });
      }
      return columnRes.map((val) => ({ label: val, value: val, id: val }));
    };

    const submit = async ({
      name,
      bindings,
      filters,
      database,
    }) => {
      gridAPI.setLoading(true);
      const result = await templateAPI.applyTemplate(
        `copy_${dash.id}`,
        name,
        database,
        bindings,
        filters,
        dash.group,
      );
      if (result.error) {
        setAlertConfig({
          type: 'error',
          text: result.msg,
          child: result.raw,
        });
        gridAPI.setLoading(false);
        return;
      }
      await gridAPI.init();
      setAlertConfig({
        type: 'success',
        text: 'Dashboard copiado com sucesso',
      });
      gridAPI.setLoading(false);
      closeModal();
      await filesAPI.init();
    };

    setModalConfig({
      title: 'Copiar Dashboard',
      children: (
        <CopyDashboard
          hide={closeModal}
          variables={reqVariablesDescription}
          // columnData={reqColumns}
          //
          files={filesState.files}
          onSelectFilter={handleColumnChange}
          handleFileChange={handleFileChange}
          submitData={submit}
          tourContext={{ nextStep, tourOpen }}
        />
      ),
    });
  };

  /**
   * @async
   * Makes an exact copy of a dashboard
   * @param {object} dash Dashboard to be copied
   */
  const handleCloneDash = async (dash) => {
    if (!verifyMemoryAndDashboards()) return;

    const result = await gridAPI.cloneDashboard(dash.id);
    if (result.error) {
      setAlertConfig({
        type: 'error',
        text: result.msg,
        child: result.raw,
      });
      return;
    }
    setAlertConfig({
      type: 'success',
      text: 'Dashboard clonado com sucesso',
    });
    await filesAPI.getCurrentCompanyStorage();
  };

  const handleCloseMessage = async (msgId) => {
    const res = await notificationsAPI.setViewdSystemMessage(msgId);
    if (res.error) {
      setAlertConfig({
        type: 'error',
        text: res.msg,
        child: res.raw,
      });
    }
  };

  const handleImportDashboard = () => {
    const submit = async (file, dashName) => {
      gridAPI.setLoading(true);
      const result = await gridKpiAPI.importDashboard(file, dashName);
      if (result.error) {
        setAlertConfig({
          type: 'error',
          text: result.msg,
          child: `${result.raw}`,
        });
        gridAPI.setLoading(false);
        return;
      }
      setAlertConfig({
        type: 'success',
        text: 'Dashboard importado com sucesso',
      });
      gridAPI.setLoading(false);
      closeModal();
      await gridAPI.init();
    };
    setModalConfig({
      title: 'Importar Dashboard',
      children: (
        <ImportDashboard
          uploadFile={submit}
          hide={closeModal}
          tourContext={{ nextStep, tourOpen }}
        />
      ),
    });
  };

  const handleExportDashboard = async (dash) => {
    gridAPI.setLoading(true);
    const result = await gridKpiAPI.exportDashboard(dash.id, dash?.name || 'Dashboard');
    if (result.error) {
      setAlertConfig({
        type: 'error',
        text: result.msg,
        child: `${result.raw}`,
      });
      gridAPI.setLoading(false);
      return;
    }
    setAlertConfig({
      type: 'success',
      text: 'Dashboard exportado com sucesso!',
    });
    gridAPI.setLoading(false);
  };

  return (
    <>
      {(isLoading/* || filesState.isLoading */) && <Loader />}
      <GridDashboardToolbar
        //
        user={user}
        dashboards={dashWithUser}
        saveLayout={saveLayout}
        editPainel={canEditLayout}
        setEditLayout={setEditLayout}
        isLoading={!filesState?.started || filesState?.isLoading}
        // filter
        filterText={filterText}
        setFilterText={setFilterText}
        selectedGeneral={selectedGeneral}
        setSelectedGeneral={handleGeneral}
        selectedGroups={selectedGroups}
        setSelectedGroups={handleGroup}
        openTrashCan={openTrashCan}
        setOpenTrashCan={handleTrashCan}
        trashCount={countTrashCan}
        clearTrashCan={handleClearTrashCan}
        //
        groups={groups}
        handleCreateModal={handleCreateModal}
        importDashboard={handleImportDashboard}
        tourContext={{ tourOpen, nextStep }}
      />
      <DashboardCore
        _currentBreakpoint={_currentBreakpoint}
        _layout={_layout}
        _setCurrentBreakPoint={_setCurrentBreakPoint}
        _setLayout={_setLayout}
        canEditLayout={canEditLayout}
        dashboardLayout={dashboardLayout}
        filesState={filesState}
        filteredDash={filteredDash}
        filterText={filterText}
        handleBookMarkDash={handleBookMarkDash}
        handleCloneDash={handleCloneDash}
        handleCloseMessage={handleCloseMessage}
        handleCopyDashboard={handleCopyDashboard}
        handleCreateModal={handleCreateModal}
        handleEditModal={handleEditModal}
        handleExportDashboard={handleExportDashboard}
        handleRemoveDash={handleRemoveDash}
        handleSelfRemove={handleSelfRemove}
        handleShareDash={handleShareDash}
        handleToggleTrash={handleToggleTrash}
        handleTrashCan={handleTrashCan}
        handleUseAsTemplate={handleUseAsTemplate}
        isLoading={isLoading}
        nextStep={nextStep}
        openTrashCan={openTrashCan}
        refreshTour={refreshTour}
        selectedGeneral={selectedGeneral}
        selectedGroups={selectedGroups}
        sysMsgState={sysMsgState}
        tourOpen={tourOpen}
        user={user}
      />
      {/* <Header pageTitle="Dashboards" companyLogo={userCompany ? userCompany.photoUrl : logo} username={currentUser.displayName} /> */}
      {/* <GridDashboards /> */}
    </>
  );
}
