/* eslint-disable lines-between-class-members */
/* eslint-disable max-classes-per-file */
import React, { Fragment } from 'react';

const getInputsTags = (inputs = [], min = 1) => (
  Array.from({ length: Math.max(inputs.length, min) }, (_, i) => {
    const input = inputs[i];
    if (input === undefined) return <sub>⎵</sub>;
    switch (input.origin) {
      case 'COLUMN':
        return <b className={input.type}>{input.label}</b>;
      case 'TODAY':
        return <em>HOJE()</em>;
      case 'DATE':
      case 'NUMBER':
        return <i className={input.type}>{input.label}</i>;
      default:
        return <sub>⎵</sub>;
    }
  })
);

export const TO_INT = {
  id: 'TO_INT',
  value: 'TO_INT',
  label: 'Converter para Inteiro',
  type: 'float',
  allowedInputs: [['COLUMN']],
  minInputs: 1,
  maxInputs: 1,
  strictColumnType: null,
  strictOrder: true,
  description: `
    Converte qualquer tipo de coluna para um número inteiro, arredondando os decimais e marcando como "nan"
    valores não numéricos. Para colunas de data, a conversão retorna um número que representa o
    total de nanossegundos passados de 01/01/1970 00:00:00 até a data informada.
  `,
  formulaView(inputs) {
    const column = getInputsTags(inputs, this.minInputs)[0];
    return (
      <>
        <span>=INT(</span>
        {column}
        <span>)</span>
      </>
    );
  },
};

export const TO_FLOAT = {
  id: 'TO_FLOAT',
  value: 'TO_FLOAT',
  label: 'Converter para Real',
  type: 'float',
  allowedInputs: [['COLUMN']],
  minInputs: 1,
  maxInputs: 1,
  strictColumnType: null,
  strictOrder: true,
  description: `
    Converte qualquer tipo de coluna para número real, marcando como "nan" valores não numéricos.
    Para colunas de data, a conversão retorna um número que representa o total de
    nanossegundos passados de 01/01/1970 00:00:00 até a data informada.
  `,
  formulaView(inputs) {
    const column = getInputsTags(inputs)[0];
    return (
      <>
        <span>=VALOR(</span>
        {column}
        <span>)</span>
      </>
    );
  },
};

export const TO_STR = {
  id: 'TO_STR',
  value: 'TO_STR',
  label: 'Converter para Texto',
  type: 'abc',
  allowedInputs: [['COLUMN']],
  minInputs: 1,
  maxInputs: 1,
  strictColumnType: null,
  strictOrder: true,
  description: `
    Converte qualquer tipo de coluna para Texto, substituindo o tipo original da coluna
    para texto.
  `,
  formulaView(inputs) {
    const column = getInputsTags(inputs)[0];
    return (
      <>
        <span>=</span>
        {column}
        <span>{' & ""'}</span>
      </>
    );
  },
};

export const TO_INT_TO_STR = {
  id: 'TO_INT_TO_STR',
  value: 'TO_INT_TO_STR',
  label: 'Converter para Inteiro como Texto',
  type: 'abc',
  allowedInputs: [['COLUMN']],
  minInputs: 1,
  maxInputs: 1,
  strictColumnType: null,
  strictOrder: true,
  description: `
    Conversão dupla de qualquer tipo de coluna para Inteiro e depois para Texto,
    arredondando os decimais e marcando como "nan" valores não numéricos. Para colunas de data,
    a conversão retorna um número que representa o total de nanossegundos passados de 01/01/1970 00:00:00
    até a data informada.
  `,
  formulaView(inputs) {
    const column = getInputsTags(inputs)[0];
    return (
      <>
        <span>=INT(</span>
        {column}
        <span>) & &quot;&quot;</span>
      </>
    );
  },
};

export const TO_DATE = {
  id: 'TO_DATE',
  value: 'TO_DATE',
  label: 'Converter para Data',
  type: 'date',
  allowedInputs: [['COLUMN']],
  minInputs: 1,
  maxInputs: 1,
  strictColumnType: null,
  strictOrder: true,
  description: `
    Converte qualquer tipo de coluna para Data, marcando como "nat" valores que não representam datas.
    Para colunas numéricas, a conversão considera o número como o total de nanossegundos passados de
    01/01/1970 00:00:00 até a data informada.
  `,
  formulaView(inputs) {
    const column = getInputsTags(inputs)[0];
    return (
      <>
        <span>=DATA(</span>
        {column}
        <span>)</span>
      </>
    );
  },
};

export const SECONDS_TO_HOURS = {
  id: 'SECONDS_TO_HOURS',
  value: 'SECONDS_TO_HOURS',
  label: 'Segundos para Horas',
  type: 'float',
  allowedInputs: [['COLUMN']],
  minInputs: 1,
  maxInputs: 1,
  strictColumnType: null,
  strictOrder: true,
  description: `
    Converte qualquer tipo de coluna para um valor numérico real correspondente ao valor em horas. Se aplicado
    em variáveis de texto, retorna "nan". Valores de data são, primeiramente, convertidos em números em
    nanossegundos passados de 01/01/1970 00:00:00 até a data informada e, posterioremente, esse número é
    dividido por 3600. Para valores numéricos, apenas divide o valor por 3600.
  `,
  formulaView(inputs) {
    const column = getInputsTags(inputs)[0];
    return (
      <>
        <span>=VALOR(</span>
        {column}
        <span>) / 3600</span>
      </>
    );
  },
};

export const IS_TODAY = {
  id: 'IS_TODAY',
  value: 'IS_TODAY',
  label: 'Data é "HOJE"',
  type: 'abc',
  allowedInputs: [['COLUMN']],
  minInputs: 1,
  maxInputs: 1,
  strictColumnType: 'date',
  strictOrder: true,
  description: `
    Marca com o texto "Verdadeiro" ou "Falso" quando o valor de uma coluna de data seja
    igual ou não o dia de "Hoje". "Hoje" representa o dia da última atualização.
  `,
  formulaView(inputs) {
    const column = getInputsTags(inputs)[0];
    return (
      <>
        <span>=SE(</span>
        {column}
        <span>{' = HOJE(); "Verdadeiro"; "Falso")'}</span>
      </>
    );
  },
};

// export const DURATION_TO_SECONDS = {
//   id: 'DURATION_TO_SECONDS',
//   value: 'DURATION_TO_SECONDS',
//   label: 'Duração em segundos',
//   type: 'float',
//   allowedInputs: [['COLUMN']],
//   minInputs: 1,
//   maxInputs: 1,
//   strictColumnType: null,
//   strictOrder: true,
//   formulaView(inputs) {
//     const column = getInputsTags(inputs)[0];
//     return (
//       <>
//         <span>=TEMPO(HORA(</span>
//         {column}
//         <span>) ; MINUTO(</span>
//         {column}
//         <span>) ; SEGUNDO(</span>
//         {column}
//         <span>)) * 86400</span>
//       </>
//     );
//   },
// };

// export const DURATION_TO_MINUTES = {
//   id: 'DURATION_TO_MINUTES',
//   value: 'DURATION_TO_MINUTES',
//   label: 'Duração em minutos',
//   type: 'float',
//   allowedInputs: [['COLUMN']],
//   minInputs: 1,
//   maxInputs: 1,
//   strictColumnType: null,
//   strictOrder: true,
//   formulaView(inputs) {
//     const column = getInputsTags(inputs)[0];
//     return (
//       <>
//         <span>=TEMPO(HORA(</span>
//         {column}
//         <span>) ; MINUTO(</span>
//         {column}
//         <span>) ; SEGUNDO(</span>
//         {column}
//         <span>)) * 1440</span>
//       </>
//     );
//   },
// };

// export const DURATION_TO_HOURS = {
//   id: 'DURATION_TO_HOURS',
//   value: 'DURATION_TO_HOURS',
//   label: 'Duração em horas',
//   type: 'float',
//   allowedInputs: [['COLUMN']],
//   minInputs: 1,
//   maxInputs: 1,
//   strictColumnType: null,
//   strictOrder: true,
//   formulaView(inputs) {
//     const column = getInputsTags(inputs)[0];
//     return (
//       <>
//         <span>=TEMPO(HORA(</span>
//         {column}
//         <span>) ; MINUTO(</span>
//         {column}
//         <span>) ; SEGUNDO(</span>
//         {column}
//         <span>)) * 24</span>
//       </>
//     );
//   },
// };

// export const DURATION_TO_DAYS = {
//   id: 'DURATION_TO_DAYS',
//   value: 'DURATION_TO_DAYS',
//   label: 'Duração em dias',
//   type: 'float',
//   allowedInputs: [['COLUMN']],
//   minInputs: 1,
//   maxInputs: 1,
//   strictColumnType: null,
//   strictOrder: true,
//   formulaView(inputs) {
//     const column = getInputsTags(inputs)[0];
//     return (
//       <>
//         <span>=</span>
//         {column}
//         <span>{' - INT('}</span>
//         {column}
//         <span>)</span>
//       </>
//     );
//   },
// };

export const DAYS_DIFF = {
  id: 'DAYS_DIFF',
  value: 'DAYS_DIFF',
  label: 'Diferença de datas em dias',
  type: 'float',
  allowedInputs: [['COLUMN', 'DATE', 'TODAY']],
  minInputs: 2,
  maxInputs: 2,
  strictColumnType: 'date',
  strictOrder: false,
  description: `
    Realiza a diferença entre duas datas e retorna o resultado em dias.
  `,
  formulaView(inputs) {
    return (
      <>
        <span>=</span>
        {getInputsTags(inputs, 2).map((inp, i) => (
          <Fragment key={inputs[i]?.id ?? i}>
            {i > 0 && <span>{' - '}</span>}
            {inp}
          </Fragment>
        ))}
      </>
    );
  },
};

export const BUSINESS_DAYS_DIFF = {
  id: 'BUSINESS_DAYS_DIFF',
  value: 'BUSINESS_DAYS_DIFF',
  label: 'Diferença em dias úteis',
  type: 'float',
  allowedInputs: [['COLUMN', 'DATE', 'TODAY']],
  minInputs: 2,
  maxInputs: 2,
  strictColumnType: 'date',
  strictOrder: false,
  description: `
    Realiza a contagem de dias úteis entre o intervalo de datas informado.
  `,
  formulaView(inputs) {
    return (
      <>
        <span>=DIATRABALHOTOTAL(</span>
        {getInputsTags(inputs, 2).map((inp, i) => (
          <Fragment key={inputs[i]?.id ?? i}>
            {i > 0 && <span>{'; '}</span>}
            {inp}
          </Fragment>
        ))}
        <span>)</span>
      </>
    );
  },
};

// export const TIME_DIFF = {
//   id: 'TIME_DIFF',
//   value: 'TIME_DIFF',
//   label: 'Diferença em tempo',
//   type: 'abc',
//   allowedInputs: [['COLUMN', 'DATE', 'TODAY']],
//   minInputs: 2,
//   maxInputs: 2,
//   strictColumnType: 'date',
//   strictOrder: false,
//   description: `
//     Realiza a diferença entre duas datas e retorna o resultado como tempo no formato hh:mm.
//   `,
//   formulaView(inputs) {
//     return (
//       <>
//         <span>=INT((</span>
//         {getInputsTags(inputs, 2).map((inp, i) => (
//           <Fragment key={inputs[i]?.id ?? i}>
//             {i > 0 && <span>{' - '}</span>}
//             {inp}
//           </Fragment>
//         ))}
//         <span>) * 24) & &quot;:&quot; & TEXTO((</span>
//         {getInputsTags(inputs, 2).map((inp, i) => (
//           <Fragment key={inputs[i]?.id ?? i}>
//             {i > 0 && <span>{' - '}</span>}
//             {inp}
//           </Fragment>
//         ))}
//         <span>) * 1440 - INT((</span>
//         {getInputsTags(inputs, 2).map((inp, i) => (
//           <Fragment key={inputs[i]?.id ?? i}>
//             {i > 0 && <span>{' - '}</span>}
//             {inp}
//           </Fragment>
//         ))}
//         <span>) * 24) * 60; &quot;00&quot;)</span>
//       </>
//     );
//   },
// };

export const HOURS_DIFF = {
  id: 'HOURS_DIFF',
  value: 'HOURS_DIFF',
  label: 'Diferença de datas em horas',
  type: 'float',
  allowedInputs: [['COLUMN', 'DATE', 'TODAY']],
  minInputs: 2,
  maxInputs: 2,
  strictColumnType: 'date',
  strictOrder: false,
  description: `
    Realiza a diferença entre duas datas e retorna o resultado em horas.
  `,
  formulaView(inputs) {
    const columns = getInputsTags(inputs, 2);
    return (
      <>
        <span>=(</span>
        {columns[0]}
        <span>{' - '}</span>
        {columns[1]}
        <span>) * 24</span>
      </>
    );
  },
};

export const MONTHS_DIFF = {
  id: 'MONTHS_DIFF',
  value: 'MONTHS_DIFF',
  label: 'Diferença em meses',
  type: 'float',
  allowedInputs: [['COLUMN', 'DATE', 'TODAY']],
  minInputs: 2,
  maxInputs: 2,
  strictColumnType: 'date',
  strictOrder: false,
  description: `
    Realiza a diferença entre duas datas e retorna o resultado em meses.
  `,
  formulaView(inputs) {
    return (
      <>
        <span>=DATADIF(</span>
        {getInputsTags(inputs, 2).map((inp, i) => (
          <Fragment key={inputs[i]?.id ?? i}>
            {i > 0 && <span>{'; '}</span>}
            {inp}
          </Fragment>
        ))}
        <span>; &quot;M&quot;)</span>
      </>
    );
  },
};

export const YEARS_DIFF = {
  id: 'YEARS_DIFF',
  value: 'YEARS_DIFF',
  label: 'Diferença em anos',
  type: 'float',
  allowedInputs: [['COLUMN', 'DATE', 'TODAY']],
  minInputs: 2,
  maxInputs: 2,
  strictColumnType: 'date',
  strictOrder: false,
  description: `
    Realiza a diferença entre duas datas e retorna o resultado em anos.
  `,
  formulaView(inputs) {
    return (
      <>
        <span>=DATADIF(</span>
        {getInputsTags(inputs, 2).map((inp, i) => (
          <Fragment key={inputs[i]?.id ?? i}>
            {i > 0 && <span>{'; '}</span>}
            {inp}
          </Fragment>
        ))}
        <span>; &quot;Y&quot;)</span>
      </>
    );
  },
};

export const SUM = {
  id: 'SUM',
  value: 'SUM',
  label: 'Soma',
  type: 'float',
  allowedInputs: [['COLUMN', 'NUMBER']],
  minInputs: 2,
  maxInputs: 10,
  strictColumnType: 'float',
  strictOrder: false,
  numberPrecision: 2,
  description: `
    Realiza o somatório dos valores informados.
  `,
  formulaView(inputs) {
    return (
      <>
        <span>=</span>
        {getInputsTags(inputs, 2).map((inp, i) => (
          <Fragment key={inputs[i]?.id ?? i}>
            {i > 0 && <span>{' + '}</span>}
            {inp}
          </Fragment>
        ))}
      </>
    );
  },
};

export const SUB = {
  id: 'SUB',
  value: 'SUB',
  label: 'Diferença',
  type: 'float',
  allowedInputs: [['COLUMN', 'NUMBER']],
  minInputs: 2,
  maxInputs: 10,
  strictColumnType: 'float',
  strictOrder: false,
  numberPrecision: 2,
  description: `
    Realiza a diferença entre os valores informados.
  `,
  formulaView(inputs) {
    return (
      <>
        <span>=</span>
        {getInputsTags(inputs, 2).map((inp, i) => (
          <Fragment key={inputs[i]?.id ?? i}>
            {i > 0 && <span>{' - '}</span>}
            {inp}
          </Fragment>
        ))}
      </>
    );
  },
};

export const ABS_SUB = {
  id: 'ABS_SUB',
  value: 'ABS_SUB',
  label: 'Diferença absoluta',
  type: 'float',
  allowedInputs: [['COLUMN', 'NUMBER']],
  minInputs: 2,
  maxInputs: 10,
  strictColumnType: 'float',
  strictOrder: false,
  numberPrecision: 2,
  description: `
    Realiza a diferença absoluta (módulo da diferença) entre os valores informados.
    Ou seja, sempre retorna a diferença positiva entre os valores informados.
  `,
  formulaView(inputs) {
    return (
      <>
        <span>=ABS(</span>
        {getInputsTags(inputs, 2).map((inp, i) => (
          <Fragment key={inputs[i]?.id ?? i}>
            {i > 0 && <span>{' - '}</span>}
            {inp}
          </Fragment>
        ))}
        <span>)</span>
      </>
    );
  },
};

export const MULT = {
  id: 'MULT',
  value: 'MULT',
  label: 'Multiplicação',
  type: 'float',
  allowedInputs: [['COLUMN', 'NUMBER']],
  minInputs: 2,
  maxInputs: 10,
  strictColumnType: 'float',
  strictOrder: false,
  numberPrecision: 2,
  description: `
    Realiza a multiplicação entre os valores informados.
  `,
  formulaView(inputs) {
    return (
      <>
        <span>=</span>
        {getInputsTags(inputs, 2).map((inp, i) => (
          <Fragment key={inputs[i]?.id ?? i}>
            {i > 0 && <span>{' * '}</span>}
            {inp}
          </Fragment>
        ))}
      </>
    );
  },
};

export const DIV = {
  id: 'DIV',
  value: 'DIV',
  label: 'Divisão',
  type: 'float',
  allowedInputs: [['COLUMN', 'NUMBER']],
  minInputs: 2,
  maxInputs: 10,
  strictColumnType: 'float',
  strictOrder: false,
  numberPrecision: 2,
  description: `
    Realiza a divisão entre os valores informados.
  `,
  formulaView(inputs) {
    return (
      <>
        <span>=</span>
        {getInputsTags(inputs, 2).map((inp, i) => (
          <Fragment key={inputs[i]?.id ?? i}>
            {i > 0 && <span>{' / '}</span>}
            {inp}
          </Fragment>
        ))}
      </>
    );
  },
};

export const FLOOR_DIV = {
  id: 'FLOOR_DIV',
  value: 'FLOOR_DIV',
  label: 'Divisão inteira',
  type: 'float',
  allowedInputs: [['COLUMN', 'NUMBER']],
  minInputs: 2,
  maxInputs: 10,
  strictColumnType: 'float',
  strictOrder: false,
  numberPrecision: 2,
  description: `
    Realiza a divisão inteira entre os valores informados, ou seja, caso o resultado da
    divisão seja um valor com casas decimais, o valor é arredondado para baixo (truncado).
  `,
  formulaView(inputs) {
    return (
      <>
        <span>=ARREDMULTB(</span>
        {getInputsTags(inputs, 2).map((inp, i) => (
          <Fragment key={inputs[i]?.id ?? i}>
            {i > 0 && <span>{' / '}</span>}
            {inp}
          </Fragment>
        ))}
        <span>)</span>
      </>
    );
  },
};

export const MOD_DIV = {
  id: 'MOD_DIV',
  value: 'MOD_DIV',
  label: 'Resto da divisão',
  type: 'float',
  allowedInputs: [['COLUMN', 'NUMBER']],
  minInputs: 2,
  maxInputs: 2,
  strictColumnType: 'float',
  strictOrder: false,
  numberPrecision: 2,
  description: `
    Retorna o resto da divisão entre os valores informados.
  `,
  formulaView(inputs) {
    return (
      <>
        <span>=MOD(</span>
        {getInputsTags(inputs, 2).map((inp, i) => (
          <Fragment key={inputs[i]?.id ?? i}>
            {i > 0 && <span>{'; '}</span>}
            {inp}
          </Fragment>
        ))}
        <span>)</span>
      </>
    );
  },
};

export const DAYS_OFFSET = {
  id: 'DAYS_OFFSET',
  value: 'DAYS_OFFSET',
  label: 'Deslocamento de dias',
  type: 'date',
  allowedInputs: [['COLUMN', 'DATE', 'TODAY'], ['NUMBER']],
  minInputs: 2,
  maxInputs: 2,
  strictColumnType: 'date',
  strictOrder: true,
  numberPrecision: 0,
  description: `
    Avança ou retrocede dias de uma data a partir do valor informado.
    Para retroceder, informe um valor negativo.
  `,
  formulaView(inputs) {
    return (
      <>
        <span>=</span>
        {getInputsTags(inputs, 2).map((inp, i) => (
          <Fragment key={inputs[i]?.id ?? i}>
            {i > 0 && <span>{' + '}</span>}
            {inp}
          </Fragment>
        ))}
      </>
    );
  },
};

export const MONTHS_OFFSET = {
  id: 'MONTHS_OFFSET',
  value: 'MONTHS_OFFSET',
  label: 'Deslocamento de meses',
  type: 'date',
  allowedInputs: [['COLUMN', 'DATE', 'TODAY'], ['NUMBER']],
  minInputs: 2,
  maxInputs: 2,
  strictColumnType: 'date',
  strictOrder: true,
  numberPrecision: 0,
  description: `
    Avança ou retrocede meses de uma data a partir do valor informado.
    Para retroceder, informe um valor negativo.
  `,
  formulaView(inputs) {
    return (
      <>
        <span>=DATAM(</span>
        {getInputsTags(inputs, 2).map((inp, i) => (
          <Fragment key={inputs[i]?.id ?? i}>
            {i > 0 && <span>{'; '}</span>}
            {inp}
          </Fragment>
        ))}
        <span>)</span>
      </>
    );
  },
};

export const YEARS_OFFSET = {
  id: 'YEARS_OFFSET',
  value: 'YEARS_OFFSET',
  label: 'Deslocamento de anos',
  type: 'date',
  allowedInputs: [['COLUMN', 'DATE', 'TODAY'], ['NUMBER']],
  minInputs: 2,
  maxInputs: 2,
  strictColumnType: 'date',
  strictOrder: true,
  numberPrecision: 0,
  description: `
    Avança ou retrocede anos de uma data a partir do valor informado.
    Para retroceder, informe um valor negativo.
  `,
  formulaView(inputs) {
    const columns = getInputsTags(inputs, 2);
    return (
      <>
        <span>=DATA(ANO(</span>
        {columns[0]}
        <span>{' + '}</span>
        {columns[1]}
        <span>) ; MÊS(</span>
        {columns[0]}
        <span>) ; DIA(</span>
        {columns[0]}
        <span>))</span>
      </>
    );
  },
};
