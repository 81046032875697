/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Chart from '../../charts';
import InsightCard from '../../insight';
import IframeCard from '../../iframe';
import TablePagination from '../../../core/TablePagination';

import filterByValues from '../../utils/formatCharts/filterByValues';
import groupByValues from '../../utils/formatCharts/groupByValues';

import useOnScreen from '../../../hooks/useOnScreen';
import checkDataSize from '../../utils/chartTools/kpiMsg';

import {
  StyledCardKpi,
  StyledCardKpiTitle,
  StyledCardKpiContent,
  PaginationWrapper,
} from './styled/CardKpi.styled';

const getValueBackground = (isValue, globalTheme, background) => {
  if (isValue && globalTheme?.useGlobalTheme) return { backgroundColor: globalTheme.valueBg };
  return { backgroundColor: isValue ? background : 'transparent' };
};

const titleStyle = (tStyle, globalTheme) => ({
  fontSize: `${tStyle?.fontSize ?? 20}px`,
  color: globalTheme?.useGlobalTheme ? globalTheme.titles : (tStyle?.color ?? '#000000'),
  fontFamily: `'${tStyle?.fontFamily ?? 'arial'}', Arial`,
  fontWeight: tStyle?.bold ? 'bold' : 'normal',
  textDecoration: tStyle?.underline ? 'underline' : 'none',
  fontStyle: tStyle?.italic ? 'italic' : 'normal',
});

const CardKpi = React.forwardRef(({
  item,
  handleStyleConfig,
  stateDashboardLayout,
  editPainel,
  dashSize,
  id,
  showToPrint,
  isDataLoading,
  globalTheme,
  pageState,
  collection,
}, ref) => {
  const [page, setPage] = pageState;

  // Retorna se o kpi esta visivel na tela ou nao
  const isVisible = showToPrint || useOnScreen(ref);

  function modifyData(filterList, groupList, data, lineName) {
    let newData = data;
    if (filterList?.length > 0) {
      newData = filterByValues(filterList, newData, lineName);
    }
    if (groupList?.length > 0) {
      newData = groupByValues(groupList, newData, lineName);
    }
    return newData;
  }

  const data = (item?.data?.data?.length > 0) ? (
    modifyData(
      item?.meta?.filterValues,
      item?.meta?.groupValues,
      item.data,
      item?.meta?.lines?.[0]?.column,
    )
  ) : (
    { index: [], data: [], columns: [] }
  );

  return (
    <StyledCardKpi
      ref={ref}
      titleFontSize={
        item?.style?.ShowTitleControl?.checked ? item?.style?.StyleTitleControl?.fontSize : 0
      }
      kpiType={item?.style?.type ?? item?.type}
      showTitle={item?.style?.ShowTitleControl?.checked}
      id={id}
    >
      {['label', 'smartLabel'].includes(item?.type) && (
        <>
          <InsightCard
            item={item}
            globalTheme={globalTheme}
          />
        </>
      )}
      {item?.type === 'Iframe' && (
        checkDataSize(
          { data: [undefined] }, item?.linkType, true, 1, false, collection,
        ) || (
          <IframeCard item={item} />
        )
      )}
      {!['label', 'smartLabel', 'Iframe'].includes(item?.type) && (
        <>
          {item?.style?.ShowTitleControl?.checked ? (
            <StyledCardKpiTitle
              style={getValueBackground((item?.style?.type ?? item?.type) === 'Value', globalTheme, item?.style?.GlobalValue?.background)}
            >
              <span
                style={titleStyle(item?.style?.StyleTitleControl, globalTheme)}
              >
                {item.name ?? ''}
              </span>
            </StyledCardKpiTitle>
          ) : <></>}
          <StyledCardKpiContent type={item?.type}>
            {checkDataSize(data, item?.type, isVisible, dashSize, isDataLoading, collection) || (
              <>
                <Chart
                  id="svg"
                  type={item?.type}
                  data={data}
                  valueFunction={item?.meta?.values?.[0]?.map}
                  styleConfig={item?.style}
                  kpiSize={stateDashboardLayout?.lg?.filter((d) => d?.i === item?.id)}
                  handleStyleConfig={(param1, param2) => handleStyleConfig(param2, param1)}
                  isEdit={editPainel}
                  globalTheme={globalTheme}
                />
              </>
            )}
          </StyledCardKpiContent>
          {item?.type === 'SubsetTable' && (
            <PaginationWrapper bgColor={globalTheme?.backgroundColor ?? '#ffffff'}>
              <TablePagination
                page={page}
                totalPages={(data?.total_pages ?? 1) - 1}
                setPage={setPage}
                useGoToPage={false}
                label=""
                className="tableWrapperPagination"
                disabled={editPainel}
              />
            </PaginationWrapper>
          )}
        </>
      )}
    </StyledCardKpi>
  );
});

CardKpi.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
  handleStyleConfig: PropTypes.func,
  editPainel: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
  dashSize: PropTypes.number,
  id: PropTypes.string,
  showToPrint: PropTypes.bool,
  isDataLoading: PropTypes.bool,
  stateDashboardLayout: PropTypes.objectOf(PropTypes.any),
  globalTheme: PropTypes.objectOf(PropTypes.any),
  pageState: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.func]),
  ),
  collection: PropTypes.string,
};

CardKpi.defaultProps = {
  dashSize: undefined,
  id: undefined,
  stateDashboardLayout: undefined,
  handleStyleConfig: undefined,
  showToPrint: false,
  isDataLoading: false,
  globalTheme: { useGlobalTheme: false },
  pageState: [0, () => {}],
  collection: 'kpis',
};

export default CardKpi;
