import React from 'react';
import PropTypes from 'prop-types';

import ControllersGroup from '../../../controllers/ControllersGroup';
import {
  ToggleSwitchController,
  SelectController,
  CustomSortController,
  ChoiceBoxController,
} from '../../../controllers/Base';

const GeneralControllers = ({
  handle,
  config,
  data,
  extend,
  setExtend,
  selectedLine,
  selectedColumn,
  selectedFunction,
}) => {
  const totalsLabel = () => {
    if (config?.TotalFunctionControl) {
      return config.TotalFunctionControl === 'avg' ? 'médias' : 'totais';
    }
    return selectedFunction?.value === 'mean' ? 'médias' : 'totais';
  };

  return (
    <>
      <ControllersGroup
        title="Opções Gerais"
        id="general"
        extend={extend}
        setExtend={setExtend}
        sizeIncrease={config?.SortValuesControl === 'Customizado' ? data?.index?.length : 0}
      >
        {selectedLine && (
          <SelectController
            label="Ordenar campos"
            handle={handle}
            kWord="SortValuesControl"
            options={[
              { label: 'Automático', value: 'Não ordenado', id: 'nao-ordenado' },
              { label: 'Crescente', value: 'Crescente', id: 'Crescente' },
              { label: 'Decrescente', value: 'Decrescente', id: 'Decrescente' },
              { label: 'Customizado', value: 'Customizado', id: 'Customizado' },
            ]}
            configValue={config?.SortValuesControl}
            fullWidth
          />
        )}
        {config?.SortValuesControl !== 'Customizado' && config?.SortValuesControl !== 'Não ordenado' && (
          <SelectController
            label="Coluna a ser ordenada"
            handle={handle}
            kWord="ColumnToSortControl"
            options={[
              { label: 'Linha (-)', value: 'index', id: 'sort-index' },
              ...data?.columns?.map((col) => {
                const val = typeof col === 'string' ? col : col[col.length - 1];
                return { label: val, value: val.toString(), id: `sort-${val}` };
              }),
            ]}
            // Replace to fix legacy values
            configValue={(config?.ColumnToSortControl || 'index').replace(/.*,/, '')}
            fullWidth
          />
        )}
        {config?.SortValuesControl === 'Customizado' && (
          <CustomSortController
            handle={handle}
            kWord="CustomSortControlUpt"
            oldOrder={data?.index || []}
            newOrder={config?.CustomSortControl?.kpiCategories || []}
            removeTotal
          />
        )}
        <ToggleSwitchController
          label={`Linha de ${totalsLabel()}`}
          handle={handle}
          kWord="LineShowTotalControl"
          configValue={config?.LineShowTotalControl}
          firstOption=""
          secondOption=""
          fullWidth={!selectedColumn}
        />
        {selectedColumn && (
          <ToggleSwitchController
            label={`Coluna de ${totalsLabel()}`}
            handle={handle}
            kWord="ColumnShowTotalControl"
            configValue={config?.ColumnShowTotalControl}
            firstOption=""
            secondOption=""
          />
        )}
        <ChoiceBoxController
          label="Fórmula"
          handle={handle}
          kWord="TotalFunctionControl"
          options={[
            { value: 'avg', label: 'Média dos valores', id: 'totalsAvg' },
            { value: 'sum', label: 'Soma dos valores', id: 'totalsSum' },
          ]}
          configValue={config?.TotalFunctionControl ?? (selectedFunction?.value === 'mean' ? 'avg' : 'sum')}
          fullWidth
          disabled={!config?.LineShowTotalControl && (!selectedColumn || !config?.ColumnShowTotalControl)}
        />
      </ControllersGroup>
    </>
  );
};

GeneralControllers.propTypes = {
  config: PropTypes.objectOf(PropTypes.any),
  data: PropTypes.objectOf(PropTypes.any),
  handle: PropTypes.func,
  extend: PropTypes.string,
  setExtend: PropTypes.func,
};

GeneralControllers.defaultProps = {
  config: undefined,
  data: undefined,
  handle: () => {},
  extend: '',
  setExtend: () => {},
};

export default GeneralControllers;
