import styled, { css } from 'styled-components';
import { gap } from '../../../styles/theme';

const getContrastYIQ = (hexcolor, dark, light) => {
  // eslint-disable-next-line no-param-reassign
  hexcolor = hexcolor.replace('#', '');
  const r = parseInt(hexcolor.substr(0, 2), 16);
  const g = parseInt(hexcolor.substr(2, 2), 16);
  const b = parseInt(hexcolor.substr(4, 2), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? dark : light;
};

const getFontColor = (hexcolor) => getContrastYIQ(hexcolor, '#000', '#FFF');

const getHoverColor = (hexcolor) => getContrastYIQ(hexcolor, '#F5F5F5', '#9a9a9a');

export const StyledTable = styled.table`
  width: 100%;
  white-space: nowrap;
  border-spacing: 0 3px;
  ${({ compact }) => compact && css`
    border-collapse: collapse;
    border-style: hidden;
  `}
  & td, th {
    text-align: center;
    background-color: ${({ rowColor, theme }) => theme[rowColor] || rowColor};
  }
  ${({ indexAsHeader }) => (indexAsHeader && css`
    & th {
      background-color: transparent;
    }
  `)}
  & td, th {
    padding: 10px 5px;
    ${({ compact, indexAsHeader }) => (!compact && (indexAsHeader ? css`
    &:first-of-type {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
    &:last-of-type {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  ` : css`
    &:first-child {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }
    &:last-child {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  `))}
  }
  & tbody tr {
    ${({ strippedRowsColor, theme, expand }) => strippedRowsColor && css`
      &:nth-of-type(${expand ? '4n+1' : 'odd'}) td, :nth-of-type(${expand ? '4n+1' : 'odd'}) th {
        background-color: ${theme[strippedRowsColor] || strippedRowsColor};
      }
    `}
    &:hover td, :hover th {
      background-color: ${({ theme }) => theme.containerHover};
    }
  }
  
  ${({
    tableStyle, headerColor, rowColor, strippedRowsColor, theme,
  }) => tableStyle && css`
    font-family: '${tableStyle.LabelsFontFamilyControl?.value}', Arial;
    font-size: ${tableStyle.LabelsFontSizeControl?.value || '14'}px;
    & td, th {
      border-style: solid;
      border-color: ${tableStyle.GridColor || '#EEE'};
      border-width: ${tableStyle.EnableGridX ? '2px' : '0'}
        ${tableStyle.EnableGridY ? '2px' : '0'};
    }
    & thead th {
      color: ${getFontColor(theme[headerColor] || headerColor)};
    }
    & tbody tr {
      & td, th {
        color: ${getFontColor(theme[rowColor] || rowColor)};
      }
      &:nth-of-type(odd) td, :nth-of-type(odd) th {
        color: ${getFontColor(theme[strippedRowsColor] || strippedRowsColor)};
      }
      &:hover {
        & td, th {
          background-color: ${getHoverColor(theme[rowColor] || rowColor)};
        }
        &:nth-of-type(odd) td, :nth-of-type(odd) th {
          background-color: ${getHoverColor(theme[strippedRowsColor] || strippedRowsColor)};
        }
      }
    }
  `}

  ${({ expand, theme }) => expand && css`
    border-spacing: 0 2px;
    & tbody {
      position: relative;
      top: 2px;
      & tr {
        cursor: pointer;
        &.extra-row {
          position: relative;
          top: -2px;
          transition: 0.3s transform ease-in-out;
          & th {
            position: relative;
            padding: 0;
            background-color: transparent;
            overflow: hidden;
            width: 40px;
            & div {
              height: 0%;
              width: 50%;
              border: solid ${theme.headerBackground};
              position: absolute;
              top: 0;
              right: 0;
              border-width: 0 0 2px 2px;
              transition: 0.3s height ease-in-out;
            }
          }
          & td {
            padding: 0;
            border-radius: 0 0 5px 5px;
            overflow: hidden;
          }
        }
        &.hide-row {
          max-height: 0;
          transform: translateY(-50%) scaleY(0);
        }
        &.show-row {
          cursor: initial;
          transform: translateY(0) scaleY(1);
          & th div {
            height: 50%;
          }
        }
        &.show-content {
          & td {
            border-bottom-right-radius: 0;
          }
          &:hover td {
            background-color: ${theme.containerHover};
          }
        }
      }
    }
  `}
`;

export const ColumnHeader = styled.div`
  display: flex;
  flex-direction: row;
  margin: auto;
  justify-content: center;
  align-items: center;
  width: max-content;
  ${gap('3px', 'row')}
  ${({ sortable, theme }) => sortable && css`
    cursor: pointer;
    border-radius: 5px 5px 0 0;
    &:hover {
      color: ${theme.primary};
    }
  `}
`;

export const IconWrapper = styled.div`
  display: flex;
  width: 10px;
  height: 10px;
  align-items: center;
  margin-left: 5px;
  & svg {
    fill: ${({ theme }) => theme.disabled};
  }
  transition: all 0.5s;
  ${({ toggle, field, theme }) => toggle && field === toggle.field && css`
    & svg {
      fill: ${theme.primary};
    }
    ${toggle.order < 0 && css`transform: rotate(180deg) translate(0, -1px);`}
  `}
`;
