import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '../../../juristec-ui/core/Tooltip';
import IconButton from '../../../juristec-ui/core/IconButton';
import Button from '../../../juristec-ui/core/Button';
import {
  ArrowLeft,
  NewColumn,
  VirtualAssistant,
} from '../../../juristec-ui/icons';

import { printSize, formatDateTime } from '../../../juristec-ui/utils/functions/lab';
import unsecureFileNames from '../../../utils/unsecureFileNames';
import useDeviceType from '../../../juristec-ui/hooks/useDeviceType';

import {
  MainContainer,
  ItensContainer,
  FileInfo,
} from './styled/FileViewDataToolbar.styled';

/**
 * A component to show and manage a file
*/
const FileViewDataToolbar = ({
  changed,
  ownerName,
  fileName,
  fileSize,
  lastUpdate,
  handleOptimizer,
  handleCustomColumns,
  openVirtualAssistant,
  goBack,
}) => {
  const device = useDeviceType();

  return (
    <MainContainer>
      <ItensContainer className="left">
        <Tooltip text="Voltar" direction="right">
          <IconButton
            variant="contained"
            color="secondary"
            size="medium"
            shape="rounded"
            onClick={goBack}
          >
            <ArrowLeft />
          </IconButton>
        </Tooltip>
      </ItensContainer>
      <ItensContainer className="center">
        <h3 className="filename">
          {unsecureFileNames(fileName.replace('.metrics', ''))}
          {' '}
          (
          {printSize(fileSize).replace('.', ',')}
          )
        </h3>
        {device !== 'phone' && (
          <FileInfo>
            {ownerName && ownerName !== '' && (
              <span className="fileinfo">
                Proprietário:
                {' '}
                {ownerName}
              </span>
            )}
            {lastUpdate && lastUpdate !== '' && (
              <span className="fileinfo">
                Atualizado:
                {' '}
                {formatDateTime(lastUpdate, { time: 'half' })}
              </span>
            )}
          </FileInfo>
        )}
      </ItensContainer>
      <ItensContainer className="right">
        {changed ? (
          device !== 'phone' && (
            <Button
              variant="contained"
              color="secondary"
              size="medium"
              shape="rounded"
              onClick={handleOptimizer}
            >
              Revalidação
            </Button>
          )
        ) : (
          <>
            {Boolean(openVirtualAssistant) && (
              <Tooltip text="Assistente Virtual (BETA)" direction="left">
                <IconButton
                  variant="pattern"
                  color="primary"
                  size="medium"
                  shape="rounded"
                  onClick={openVirtualAssistant}
                >
                  <VirtualAssistant />
                </IconButton>
              </Tooltip>
            )}
            {device !== 'phone' && (
              <>
                {/* COMENTADO POR ENQUANTO */}
                {/* <Tooltip text="Colunas Customizadas" direction="left">
                  <IconButton
                    variant="pattern"
                    color="primary"
                    size="medium"
                    shape="rounded"
                    onClick={handleCustomColumns}
                  >
                    <NewColumn />
                  </IconButton>
                </Tooltip> */}
              </>
            )}
          </>
        )}
      </ItensContainer>
    </MainContainer>
  );
};

FileViewDataToolbar.propTypes = {
  /**
   * bool that says if the user changed some column type
   */
  changed: PropTypes.bool.isRequired,
  /**
   * bool that says if the user changed some column type
   */
  ownerName: PropTypes.string,
  /**
   * A string with the name of the file
   */
  fileName: PropTypes.string.isRequired,
  /**
   * A number with the size of the file, in bytes
   */
  fileSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  /**
   * A string with the datetime with the last update of the file
   */
  lastUpdate: PropTypes.string,
  /**
   * A function to validate data of the file
   */
  handleOptimizer: PropTypes.func.isRequired,
  /**
   * A function to create a new column based on others
   */
  handleCustomColumns: PropTypes.func.isRequired,
  /**
   * A function to enable the virtual assistant
   */
  openVirtualAssistant: PropTypes.func,
  /**
   * A function to return the browser history back
   */
  goBack: PropTypes.func.isRequired,
};

FileViewDataToolbar.defaultProps = {
  ownerName: '',
  lastUpdate: '',
  openVirtualAssistant: () => {},
};

export default FileViewDataToolbar;
