import React, { useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';

// components
import Avatar from '../Avatar';
import Tooltip from '../Tooltip';
import IconButton from '../IconButton';
import Popover from '../Popover';
import List from '../List';
import ListItem from '../ListItem';
import Loading from '../MiniLoading';
import useDeviceType from '../../hooks/useDeviceType';
import { formatDateTime } from '../../utils/functions/lab';

import {
  // CloneDash,
  Edit,
  MoreVert,
  Share,
  TemplateUse,
  Star,
  StarSolid,
  Restore,
  Trash,
  Clone,
  Desactivate,
  Export,
} from '../../icons';

// styles
import {
  CardContainer,
  CardHeader,
  CardMain,
  CardFooter,
  CardLastUpdate,
  LoadingContainer,
} from './styled/DashboardCard.styled';

/**
* A component that exposes the dashboards made available to the user
*/
const DashboardCard = ({
  dashInfo,
  dashboardDate,
  isUserOwner,
  openDashboard,
  shareFunc,
  exportFunc,
  templateFunc,
  cloneDashFunc,
  editFunc,
  deleteFunc,
  copyFunc,
  trashFunc,
  gridEditMode,
  newsDash,
  bookMarkFunc,
  lastUpdate,
  selfRemoveFunc,
  isLoading,
  tourContext,
  ...props
}) => {
  const device = useDeviceType();
  const [openPopover, setOpenPopover] = useState(false);
  const [_isBookmarked, _setIsBookmarked] = useState(false);

  const handleTour = () => {
    if (!openPopover && tourContext.tourOpen) tourContext.nextStep();
    setOpenPopover(!openPopover);
  };

  useLayoutEffect(() => {
    _setIsBookmarked(dashInfo.bookMarked);
  }, [dashInfo.bookMarked]);

  const shareDash = () => shareFunc(dashInfo);
  const exportDash = () => exportFunc(dashInfo);
  const selfRemove = () => selfRemoveFunc(dashInfo);
  const copyDash = () => copyFunc(dashInfo);
  const trashDash = () => trashFunc(dashInfo);
  const bookmarkDash = () => bookMarkFunc(dashInfo);
  const deleteDash = () => deleteFunc(dashInfo);
  const editDash = () => editFunc(dashInfo);
  const templateDash = () => templateFunc(dashInfo);
  const cloneDash = () => cloneDashFunc(dashInfo);
  const openDash = () => openDashboard(dashInfo);

  return (
    <CardContainer
      bgColor={dashInfo.mainColor || '#FFFFFF'}
      bgSrc={dashInfo.image}
      isDeleted={dashInfo.isDeleted}
      onClick={!gridEditMode && !dashInfo.isDeleted ? openDash : () => { }}
      {...props}
    >
      <CardHeader bgColor={dashInfo.headerColor || '#ABB8C3'} onClick={(e) => e.stopPropagation()}>
        <h5>{dashInfo.group.label || 'Sem Grupo'}</h5>
        <Popover
          style={{ overflow: 'hidden' }}
          offset={[0, 0]}
          open={openPopover}
          closePopover={() => setOpenPopover(false)}
        >
          <Popover.Action>
            <Tooltip text="Mais opções" direction="left">
              <IconButton
                onClick={() => (!gridEditMode ? handleTour() : {})}
                style={{ padding: '0', margin: '2px 4px 0 0' }}
                shape="rounded"
              >
                <MoreVert />
              </IconButton>
            </Tooltip>
          </Popover.Action>
          <Popover.Content>
            <List>
              {/* eslint-disable-next-line no-nested-ternary */}
              {dashInfo.isDeleted ? (
                <>
                  <ListItem onClick={trashDash} noOutline>
                    <Restore />
                    Restaurar
                  </ListItem>
                  <ListItem onClick={deleteDash} noOutline>
                    <Trash />
                    Apagar
                  </ListItem>
                </>
              ) : isUserOwner ? (
                <>
                  <ListItem
                    onClick={shareDash}
                    noOutline
                    className="share_dash_opt"
                    isLoading={isLoading}
                  >
                    <Share />
                    Compartilhar
                  </ListItem>
                  <ListItem
                    onClick={exportDash}
                    noOutline
                    className="share_dash_opt"
                    isLoading={isLoading}
                  >
                    <Export />
                    Exportar
                  </ListItem>
                  {device !== 'phone' && (
                    <ListItem onClick={templateDash} noOutline isLoading={isLoading}>
                      <TemplateUse />
                      Copiar
                    </ListItem>
                  )}
                  <ListItem onClick={cloneDash} noOutline isLoading={isLoading}>
                    <Clone />
                    Clonar
                  </ListItem>
                  <ListItem onClick={editDash} noOutline>
                    <Edit />
                    Editar
                  </ListItem>
                  <ListItem onClick={trashDash} noOutline>
                    <Trash />
                    Mover para a Lixeira
                  </ListItem>
                </>
              ) : (
                <>
                  <ListItem onClick={selfRemove} noOutline isLoading={isLoading}>
                    <Desactivate />
                    Sair do compartilhamento
                  </ListItem>
                  {dashInfo.dashPermission === 'edit' && (
                    <>
                      <ListItem onClick={copyDash} noOutline isLoading={isLoading}>
                        <Clone />
                        Clonar
                      </ListItem>
                    </>
                  )}
                </>
              )}
            </List>
          </Popover.Content>
        </Popover>
      </CardHeader>
      <CardMain>
        <div style={{ display: 'block' }}>
          <span>{dashInfo.name}</span>
        </div>
        {newsDash && lastUpdate && (
          <CardLastUpdate>
            Atualizado em:
            {' '}
            {formatDateTime(lastUpdate)}
          </CardLastUpdate>
        )}
      </CardMain>
      <CardFooter>
        {dashInfo.isDeleted || (
          <>
            {!isUserOwner && (
              <Tooltip text={dashInfo.ownerName || ''} direction="right">
                <Avatar
                  name={dashInfo.ownerName}
                  src={dashInfo.photoUrl}
                  size="medium"
                  onClick={(e) => e.stopPropagation()}
                  style={{ cursor: 'default' }}
                />
              </Tooltip>
            )}
            <IconButton
              className="bookmarkButton"
              variant="pattern"
              color="#FFA900"
              onClick={(e) => {
                e.stopPropagation();
                bookmarkDash();
                _setIsBookmarked((p) => !p);
              }}
            >
              { _isBookmarked ? <StarSolid /> : <Star /> }
            </IconButton>
          </>
        )}
      </CardFooter>
    </CardContainer>
  );
};

DashboardCard.propTypes = {
  /** Object with all the dashboard info */
  dashInfo: PropTypes.shape({
    image: PropTypes.string,
    name: PropTypes.string,
    group: PropTypes.objectOf(PropTypes.string),
    photoUrl: PropTypes.string,
    ownerName: PropTypes.string,
    mainColor: PropTypes.string,
    headerColor: PropTypes.string,
    dashPermission: PropTypes.string,
    bookMarked: PropTypes.bool,
    isDeleted: PropTypes.bool,
  }),
  /**
   * Date of the dashboard last update
   */
  dashboardDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  /**
    * Boolean to verify if the creator is the current user
    */
  isUserOwner: PropTypes.bool,
  /**
    * Function to open the selected dashboard
    */
  openDashboard: PropTypes.func.isRequired,
  /**
    * Function to share the selected dashboard
    */
  shareFunc: PropTypes.func,
  /**
    * Function to export the selected dashboard
    */
  exportFunc: PropTypes.func,
  /**
    * Function to use the selected dashboard as template. Not used when 'isUserOwner' is false
    */
  templateFunc: PropTypes.func,
  /**
    * Function to edit the data of the selected dashboard. Not used when 'isUserOwner' is false
    */
  editFunc: PropTypes.func,
  /**
    * Function to delete the selected dashboard. Not used when 'isUserOwner' is false
    */
  deleteFunc: PropTypes.func,
  /**
    * Function to copy the dashboard.
    * Not used when 'isUserOwner' is false and 'editPermission' is true
    */
  copyFunc: PropTypes.func,
  /**
   * Function to toggle the isDeleted from the dashboard
   */
  trashFunc: PropTypes.func,
  /**
   * Function to self remove a user from a shared dashboard
   */
  selfRemoveFunc: PropTypes.func,
  /**
    * Boolean that if true, blocks every events for the grid drag and drop functionality
    */
  gridEditMode: PropTypes.bool,
  /**
   * Boolean to determine whether or not the dashboard has new updates
   */
  newsDash: PropTypes.bool,

  lastUpdate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date), PropTypes.object]),

  bookMarkFunc: PropTypes.func,

  cloneDashFunc: PropTypes.func,
};

DashboardCard.defaultProps = {
  dashInfo: {
    image: '',
    name: '',
    group: { label: '' },
    photoUrl: '',
    ownerName: '',
    mainColor: '#FFFFFF',
    headerColor: '#ABB8C3',
    dashPermission: 'read',
    bookMarked: false,
    isDeleted: false,
  },
  isUserOwner: false,
  gridEditMode: false,
  newsDash: false,
  lastUpdate: '',
  dashboardDate: '',
  //
  shareFunc: () => {},
  exportFunc: () => {},
  copyFunc: () => {},
  trashFunc: () => {},
  bookMarkFunc: () => {},
  deleteFunc: () => {},
  editFunc: () => {},
  templateFunc: () => {},
  cloneDashFunc: () => {},
  selfRemoveFunc: () => {},
};

export default DashboardCard;
