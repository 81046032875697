import styled from 'styled-components';
import { devices } from '../../../juristec-ui/core/breakpoints';

export const MainContainer = styled.div`
  padding: 0 5%;
  @media ${devices.tablet} {
    padding: 0 20px;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  margin: auto;
  & svg {
    fill: ${({ theme }) => theme.primary};
  }
`;

export const TableOverFlow = styled.div`
  max-height: calc(100vh - 185px);
  overflow-y: auto;
  overflow-x: auto;
`;

export const OwnerLabel = styled.span`
  display: block;
  max-width: 300px;
  margin: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  font-style: italic;
`;

export const FooterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  & .select_header > div {
    margin: 0;
  }
`;

export const Options = styled.div`
  display: flex;
  justify-content: center;
  & > * + * {
    margin-left: 5px;
  }
  align-items: center;
`;
