/* eslint-disable no-param-reassign */
import React, {
  useContext,
  useEffect,
  useState,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { FullScreen } from 'react-full-screen';
// components
// import { useReactToPrint } from 'react-to-print';
import GridKpisCard from '../../juristec-ui/kpis/grid/CardContainer/GridKpisCard';
import InfoKpi from '../Modals/InfoKpi';
import ModifierByValue from '../Modals/ModifierByValue';
import KpiViewer from '../KpiViewer';
import Button from '../../juristec-ui/core/Button';

// contexts and others
// import { AlertContext, ModalContext } from '../Juristec/core';
import { AlertContext } from '../../context/AlertProvider';
import { ModalContext } from '../../context/ModalProvider';
import urlHash from '../../juristec-ui/utils/functions/urlHash';
import { formatDateTime } from '../../juristec-ui/utils/functions/lab';
import useDeviceType from '../../juristec-ui/hooks/useDeviceType';

// styles
import {
  StyledResponsiveReactGridKpiLayout,
  StyledOuterGridKpiLayout,
  ExitFullScreen,
} from './styled/GridKpis.styled';

import getCardStyle from '../../utils/functions/getCardStyle';

import '../../juristec-ui/styles/react-grid-layout.css';
import '../../juristec-ui/styles/react-resizable.css';

// ReactGridLayout variables
const rglCols = {
  lg: 48,
  // md: 20,
  sm: 12,
  // xs: 8,
  xxs: 4,
};

/* const breakpoints = {
  lg: 1200,
  md: 1024,
  sm: 768,
  xs: 480,
  xxs: 0,
}; */

const breakpoints = {
  lg: 960,
  sm: 600,
  xxs: 0,
};

const setBreakPoints = (layoutType) => {
  switch (layoutType) {
    case 'desktop':
      return 'lg';
    case 'tablet':
      return 'sm';
    case 'mobile':
      return 'xxs';
    default:
      return null;
  }
};

const itemTypeLabel = (itemType) => {
  switch (itemType) {
    case 'label':
    case 'smartLabel':
      return 'Insight';
    case 'Iframe':
      return 'Conteúdo';
    default:
      return 'KPI';
  }
};

const GridKpis = React.memo(({
  gridKpiRef,
  isOwner,
  isSuper,
  gridKpiAPI,
  gridKpiState,
  editPainel,
  saveLayout,
  dashboardKey,
  kpiItemList,
  dashboardDoc,
  dashboardFileDependencies,
  kpisLayout,
  gridBreakpoint,
  setGridBreakpoint,
  showToolbar,
  showToPrint,
  //
  getControlValues,
  handleDynamicKpi,
  fullScreenHandler,
  checkAccessToDependency,
  getKpiWithoutDependencyMessage,
  handleIframe,
  handleSubsetTable,
  //
  globalFilters,
  globalFiltersOrder,
  getUserName,
}) => {
  const history = useHistory();

  const { setAlertConfig } = useContext(AlertContext);
  const { setModalConfig, closeModal } = useContext(ModalContext);
  const device = useDeviceType();

  const [stateKpisLayout, setStateKpisLayout] = useState({
    lg: [],
    // md: [],
    sm: [],
    // xs: [],
    xxs: [],
  });

  // const [tempKpis, setTempKpis] = useState(new Map());
  const [viewKpiId, setViewKpiId] = useState(null);

  useEffect(() => {
    if (kpisLayout) {
      setStateKpisLayout({
        lg: kpisLayout.lg,
        sm: kpisLayout.sm,
        xxs: kpisLayout.xxs,
      });
    }
  }, [kpisLayout]);

  // valor começa com null no index.
  useEffect(() => {
    if (editPainel === false) saveLayout(stateKpisLayout);
  }, [editPainel]);

  const getSpaceBetween = (obj) => {
    if (obj) return [+obj.spaceBetween || 0, +obj.spaceBetween || 0];

    return [10, 10];
  };

  // ///////////// handlers ////////////// //
  const openCardInfo = (kpiInfos) => {
    const formatOldGroups = (gObjOld) => {
      if (!gObjOld.rules) return [];
      return (
        Object.keys(gObjOld.rules).reduce((aux, rName) => {
          aux.push({
            column: gObjOld.target,
            ftype: 'values',
            rule: rName,
            selector: '',
            inverse: false,
            values: gObjOld.rules[rName],
          });
          return aux;
        }, [])
      );
    };
    const fileOrigin = dashboardFileDependencies.find((file) => (
      file.file_id === kpiInfos?.database
    ));

    const getGroups = () => {
      let columnsGroups = kpiInfos?.meta?.columnsMap || [];
      let lineGroups = kpiInfos?.meta?.linesMap || [];
      const fGroups = kpiInfos?.meta?.groupValues || [];

      if (!Array.isArray(columnsGroups)) {
        columnsGroups = formatOldGroups(columnsGroups);
      }
      if (!Array.isArray(lineGroups)) {
        lineGroups = formatOldGroups(lineGroups);
      }

      if (fGroups.length > 0) {
        fGroups.forEach((g) => {
          if (kpiInfos.meta?.columns?.[0]?.column === g.column) {
            columnsGroups.push({ ...g, isValueGroup: true });
          } else {
            lineGroups.push({ ...g, isValueGroup: true });
          }
        });
      }
      return { column: [...columnsGroups], line: [...lineGroups] };
    };

    const getFilters = () => {
      const bFilters = kpiInfos?.meta?.filters || [];
      const fFilters = kpiInfos?.meta?.filterValues?.map((f) => {
        f.isValueFilter = true; return f;
      }) || [];
      return bFilters.concat(fFilters);
    };

    const owner = getUserName(kpiInfos?.meta?.ownerId);

    if (fileOrigin) {
      setModalConfig({
        title: 'Informações do KPI',
        yesLabel: 'Ok',
        children: (
          <InfoKpi
            creationDate={formatDateTime(kpiInfos.createdAt, { time: 'none' })}
            lastUpdateDate={formatDateTime(kpiInfos.updatedAt, { time: 'none' })}
            kpiName={kpiInfos.name || ''}
            fileName={fileOrigin.filename || ''}
            dataValues={kpiInfos.meta?.values}
            dataLine={kpiInfos.meta?.lines?.[0]}
            dataColumn={kpiInfos.meta?.columns?.[0]}
            dataControl={kpiInfos.meta?.control?.[0]}
            chartType={kpiInfos?.type}
            filters={getFilters()}
            groupings={getGroups()}
            device={device}
            owner={owner}
          />
        ),
      });
    } else {
      setAlertConfig({
        type: 'error',
        text: 'Erro ao tentar obter as informações deste KPI',
        child: 'O arquivo responsável pela geração deste KPI foi excluído. Desta forma, não é possível verificar as informações relativas a este KPI!',
      });
    }
  };

  const deleteCard = async (card) => {
    const verifyDelete = async () => {
      const result = await gridKpiAPI.removeCard(card.id);
      if (result.error) {
        setAlertConfig({
          type: 'error',
          text: result.msg,
          child: `${result.raw}`,
        });
        return;
      }

      setAlertConfig({
        type: 'success',
        text: `${itemTypeLabel(card.type)} removido com sucesso!`,
      });
    };

    setAlertConfig({
      type: 'warning',
      text: `Tem certeza que deseja remover o ${itemTypeLabel(card.type)}${card.name ? ` ${card.name}` : ''}?`,
      withoutConfirm: true,
      withFunction: true,
      confirmFunction: verifyDelete,
    });
  };

  const downloadReport = async (kpiId, reportName, tempFilters) => {
    const result = await gridKpiAPI.downloadReportFromCard(
      kpiId, reportName, tempFilters,
    );
    if (result.error) {
      setAlertConfig({
        type: 'error',
        text: result.msg,
        child: `${result.raw}`,
      });
    }
  };

  const editCard = async (item) => {
    switch (item.type) {
      case 'Iframe':
        return handleIframe(item);
      case 'SubsetTable':
        return handleSubsetTable(item);
      case 'label':
      case 'smartLabel':
        return history.push(`/label/${urlHash(`${dashboardKey}/${item.id}`)}`);
      default:
        return history.push(`/kpi/${urlHash(`${dashboardKey}/${item.id}`)}`);
    }
    // if (item.type === 'Iframe') {
    //   return handleIframe(item);
    // }
    // const hash = urlHash(`${dashboardKey}/${item.id}`);
    // if (['label', 'smartLabel'].includes(item.type)) return history.push(`/label/${hash}`);

    // return history.push(`/kpi/${hash}`);
  };

  const cloneCard = async (item) => {
    const result = await gridKpiAPI.cloneCard(item, kpisLayout);
    if (result.error) {
      setAlertConfig({
        type: 'error',
        text: result.msg,
        child: `${result.raw}`,
      });
      return;
    }
    setAlertConfig({
      type: 'success',
      text: `${itemTypeLabel(item.type)} clonado com sucesso`,
    });
  };

  // Function to save edit the KPI document adding the comment
  const addComment = async (msg, itemId) => {
    const result = await gridKpiAPI.saveKpiComment(itemId, msg);
    if (result.error) {
      setAlertConfig({
        type: 'error',
        text: result.msg,
        child: `${result.raw}`,
      });
    } else {
      await gridKpiAPI.loadCardsFromCollection();
    }
  };
  const openModifiersModal = (kpiInfos) => {
    const submitValueModifiers = async (filtersList, groupList) => {
      const metaGen = {
        ...kpiInfos.meta,
        filterValues: filtersList,
        groupValues: groupList,
      };
      const result = await gridKpiAPI.saveKpiValueFilters(kpiInfos.id, metaGen);
      if (result.error) {
        setAlertConfig({
          type: 'error',
          text: result.msg,
          child: `${result.raw}`,
        });
      } else {
        await gridKpiAPI.loadCardsFromCollection();
        closeModal();
      }
    };

    setModalConfig({
      title: 'Filtrar e Agrupar',
      minWidth: '400px',
      children: (
        <ModifierByValue
          lineName={kpiInfos?.meta?.lines?.[0]?.column}
          columnName={kpiInfos?.meta?.columns?.[0]?.column}
          filtersList={kpiInfos.meta.filterValues}
          groupList={kpiInfos.meta.groupValues}
          closeModal={closeModal}
          submitData={submitValueModifiers}
        />
      ),
    });
  };

  const holdBreakpoint = (bk) => {
    if (!editPainel) {
      setGridBreakpoint(bk);
    }
  };

  const getLayoutOrder = useMemo(() => {
    const tempL = [
      ...kpisLayout[gridBreakpoint],
    ].filter((l) => !!kpiItemList.find((k) => k.id === l.i));
    const qtdCols = rglCols[gridBreakpoint];
    return tempL.sort((a, b) => (a.y * qtdCols + a.x >= b.y * qtdCols + b.x ? 1 : -1));
  }, [gridBreakpoint]);

  const getKpiSize = (kpiId) => {
    const nodeKpi = document.getElementById(kpiId);
    return { h: nodeKpi?.clientHeight || 0, w: nodeKpi?.clientWidth || 0 };
  };

  const openKpiViewer = (id, kpiItem, pageState, kpiLoading) => {
    setModalConfig({
      title: '',
      transparent: true,
      children: (
        <KpiViewer
          show
          close={closeModal}
          kpiItem={kpiItem}
          layout={getLayoutOrder}
          getKpiSize={getKpiSize}
          cardStyles={dashboardDoc?.cardStyles}
          collection={gridKpiState?.collection}
          changeKpi={setViewKpiId}
          pageState={pageState}
          isDataLoading={kpiLoading}
        />
      ),
    });
  };

  return (
    <FullScreen handle={fullScreenHandler}>
      <StyledOuterGridKpiLayout
        editPainel={!!editPainel}
        layoutType={editPainel}
        showToolbar={showToolbar}
        isFullScreen={fullScreenHandler.active}
      >
        <StyledResponsiveReactGridKpiLayout
          key={(!!editPainel).toString() + gridKpiState?.collection}
          ref={gridKpiRef}
          editPainel={!!editPainel}
          isResizable={!!editPainel}
          isDraggable={!!editPainel}
          margin={getSpaceBetween(dashboardDoc?.cardStyles)}
          breakpoints={breakpoints}
          cols={rglCols}
          rowHeight={20}
          layouts={{
            lg: kpisLayout.lg,
            sm: kpisLayout.sm,
            xxs: kpisLayout.xxs,
          }}
          onLayoutChange={(layout, _layouts) => {
            setStateKpisLayout(_layouts);
          }}
          onBreakpointChange={holdBreakpoint}
          breakpoint={setBreakPoints(editPainel)}
          compactType="vertical" // TODO: criar input para 3 opcoes: vertical, horizontal ou null
          preventCollision={false}
          useCSSTransforms={false}
          measureBeforeMount={false}
          resizeHandles={['s', 'e', 'se']}
        >
          {kpiItemList.map((item) => {
            // const kpiFile = files.find((f) => (f.file_id === item.database)) || {};
            const selectedGlobalFilters = globalFilters.current[item.database] || {};
            return (
              <div
                key={item.id}
                style={getCardStyle(dashboardDoc?.cardStyles || {})}
              >
                <GridKpisCard
                  isOwner={isOwner}
                  isSuper={isSuper}
                  item={item}
                  gridKpiState={gridKpiState}
                  editCard={editCard}
                  cloneCard={cloneCard}
                  deleteCard={deleteCard}
                  openCardInfo={openCardInfo}
                  donwloadReport={downloadReport}
                  downloadImgCard={gridKpiAPI.downloadImgCard}
                  createKpiComment={addComment}
                  openModifiersModal={openModifiersModal}
                  modifiersQtd={(
                    item?.meta?.filterValues?.length || 0
                  ) + (
                    item?.meta?.groupValues?.length || 0
                  )}
                  openKpiViewer={openKpiViewer}
                  viewKpiIdState={[viewKpiId, setViewKpiId]}
                  showToPrint={showToPrint}
                  getControlValues={getControlValues}
                  handleDynamicKpi={handleDynamicKpi}
                  cardSizes={
                    stateKpisLayout?.[gridBreakpoint]?.find((bk) => bk.i === item.id)
                  }
                  globalFilters={selectedGlobalFilters}
                  globalFiltersOrder={globalFiltersOrder}
                  dependencyFile={checkAccessToDependency(item)}
                  getKpiWithoutDependencyMessage={getKpiWithoutDependencyMessage}
                  globalTheme={dashboardDoc?.cardStyles}
                />
              </div>
            );
          })}
        </StyledResponsiveReactGridKpiLayout>
      </StyledOuterGridKpiLayout>
      { fullScreenHandler.active && (
        <ExitFullScreen>
          <Button color="secondary" variant="outlined" size="large" onClick={fullScreenHandler.exit}>
            Aperte ESC ou clique para sair
          </Button>
        </ExitFullScreen>
      )}
    </FullScreen>
  );
});

export default GridKpis;

GridKpis.propTypes = {
  gridKpiRef: PropTypes.shape({}),
  kpiItemList: PropTypes.arrayOf(PropTypes.shape({})),
  dashboardKey: PropTypes.string.isRequired,
  gridKpiAPI: PropTypes.shape({
    cloneCard: PropTypes.func,
    removeCard: PropTypes.func,
    downloadImgCard: PropTypes.func,
    downloadReportFromCard: PropTypes.func,
    saveKpiComment: PropTypes.func,
    saveKpiValueFilters: PropTypes.func,
    loadCardsFromCollection: PropTypes.func,
  }),
  gridKpiState: PropTypes.shape({
    collection: PropTypes.string,
  }),
  dashboardDoc: PropTypes.shape({
    cardStyles: PropTypes.shape({}),
  }),
  kpisLayout: PropTypes.shape({
    sm: PropTypes.arrayOf(PropTypes.shape({})),
    xxs: PropTypes.arrayOf(PropTypes.shape({})),
    lg: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  saveLayout: PropTypes.func,
  isOwner: PropTypes.bool,
  isSuper: PropTypes.bool,
  editPainel: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  gridBreakpoint: PropTypes.string,
  setGridBreakpoint: PropTypes.func,
  showToolbar: PropTypes.bool,
  showToPrint: PropTypes.bool,
  getControlValues: PropTypes.func,
  handleDynamicKpi: PropTypes.func,
  fullScreenHandler: PropTypes.shape({
    active: PropTypes.bool,
    exit: PropTypes.func,
  }).isRequired,
  globalFilters: PropTypes.shape({
    current: PropTypes.objectOf(PropTypes.shape({})),
  }).isRequired,
  globalFiltersOrder: PropTypes.shape({
    current: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  dashboardFileDependencies: PropTypes.arrayOf(
    PropTypes.shape({}),
  ).isRequired,
  checkAccessToDependency: PropTypes.func,
  getKpiWithoutDependencyMessage: PropTypes.func,
  getUserName: PropTypes.func,
  handleIframe: PropTypes.func,
  handleSubsetTable: PropTypes.func,
};

GridKpis.defaultProps = {
  gridKpiRef: null,
  gridKpiAPI: {
    cloneCard: () => {},
    removeCard: () => {},
    downloadImgCard: () => {},
    downloadReportFromCard: () => {},
    saveKpiComment: () => {},
    loadCardsFromCollection: () => {},
  },
  gridKpiState: {},
  dashboardDoc: {},
  kpisLayout: {},
  saveLayout: () => {},
  isOwner: false,
  isSuper: false,
  editPainel: false,
  kpiItemList: [],
  gridBreakpoint: '',
  setGridBreakpoint: () => {},
  showToolbar: true,
  showToPrint: true,
  getControlValues: () => {},
  handleDynamicKpi: () => {},
  checkAccessToDependency: () => {},
  getKpiWithoutDependencyMessage: () => {},
  getUserName: () => {},
  handleIframe: () => {},
  handleSubsetTable: () => {},
};
